import styled  from 'styled-components/native';

export const Container = styled.View`
display: flex;
flex-direction: row;
width: 100%;
background-color: ${props => props.theme.colors.secondBackground};
margin-bottom: 50px;
margin-top: 28px;
align-items: center
`