import { PixelRatio, Platform } from 'react-native';
import styled  from 'styled-components/native';

export const DotsContainer = styled.View`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-top: ${Platform.OS ==="web" ? '-70px': '-12%'};
`;

export const TextInCarousel = styled.Text`
    font-size: ${Platform.OS ==='web'?60:28}px;
    left: ${props => Platform.OS ==='web' ? 330 :20}px;
    font-weight: bold;
    width: 80%;
`

export const ItemContainer = styled.View`
    width:${props=> props.theme.ScreenWidth}px;
    height:${Platform.OS ==='web' ? '300px':'300px'};
    display:flex;
    justify-content:center;
    align-items: center;
`
export const ItemContainerApp = styled.View`
    width:${props=> props.theme.ScreenWidth}px;
    height:140px;
    display:flex;
    justify-content:center;
    align-items: center;
`

export const CarouselImg = styled.Image`
    width: ${props=> ((props.theme.ScreenWidth *50)/100)}px;
    height: ${Platform.OS ==='web'? '400px':'300px'};
    bottom: 0;
    position: absolute;
    right: 0;
    z-index: 10
`;

export const ItemContent = styled.View`
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    background-color: ${props => props.theme.colors.imageCarousel.background};
`
export const TextCarouselContent = styled.View`
    width: 50%;
    height: 100%;
    display: flex;
    z-index: 9;
`
export const TextCarousel = styled.TouchableOpacity`
    margin-top: ${Platform.OS === 'web' ? '60px': '0px'};
    justify-content: center;
    height: 250px;
`

export const TextDecoration = styled.View`
    width: ${props => Platform.OS == 'web' ?  ((props.theme.ScreenWidth *15)/100) :((props.theme.ScreenWidth *34)/100)}px;
    height: 2px;
    border-style: solid; 
    border-top-width: 1px;
    margin-left: 20px;
`

export const TouchableDots = styled.TouchableOpacity`
    width:50px; 
    height:50px;
    border-radius:4px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
`;

export const DotsColor = styled.TouchableOpacity`
    width:98%; 
    height:2px;
    background-color: aliceblue;
    border-radius:4px;

`
