import React, { createContext, useState, useEffect, FC } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Platform } from "react-native";
import { ILoyaltyUser } from "./types";

const AuthContext = createContext({
  loyaltyUser: {
    id: 0,
    modalidade: "",
    pontosProximaModalidade: 0,
    proximaModalidade: "",
    saldo: 0,
  },
  signed: false,
  loading: false,
  B2CLoginToken: "",
  cpf: null,
  givenName: null,
  familyName: null,
  telephoneNumber: null,
  drawer: {
    openDrawer: () => {},
    closeDrawer: () => {},
  },
  userId: null,
  modality: null,
  balance: null,
  modalVisible: false,
  requestCameraAccess: true,
  textModal: "",
  benefitsUsed: [],
  fidelityToken: "",
  setFidelityToken: (value: any) => {},
  setRequestCameraAccess: (value: any) => {},
  setBalance: (balance: any) => {},
  setModality: (modality: any) => {},
  setUserId: (id: any) => {},
  setDrawer: (nav: any) => {},
  setTelephoneNumber: (telephoneNumber: any) => {},
  setFamilyName: (name: any) => {},
  setGivenName: (name: any) => {},
  setCpf: (cpf: any) => {},
  setB2CLoginToken: (token: any) => {},
  signIn: () => {},
  signedOut: () => {},
  setModalVisible: (modalVisible: any) => {},
  setTextModal: (textModal: any) => {},
  setBenefitsUsed: (benefitsUsed: any) => {},
  setLoyaltyUser: (loyaltyUser: ILoyaltyUser) => {}
});

export const Provider: FC = ({ children }) => {
  const [signedValue, setSignedValue] = useState<any>(false);
  const [loading, setLoading] = useState<any>(true);
  const [B2CLoginTokenState, setB2CLoginTokenState] = useState<any>(null);
  const [cpfState, setCpfState] = useState<any>(null);
  const [givenNameState, setGivenNameState] = useState<any>(null);
  const [familyNameState, setFamilyNameState] = useState<any>(null);
  const [telephoneNumberState, setTelephoneNumberState] = useState<any>(null);
  const [userIdState, setUserIdState] = useState<any>(null);
  const [modalityState, setModalityState] = useState<any>(null);
  const [balanceState, setBalanceState] = useState<any>(null);
  const [modalVisibleState, setModalVisibleState] = useState<any>(false);
  const [requestCameraAccessState, setRequestCameraAccessState] =
    useState<any>(false);
  const [textModalState, setTextModalState] = useState<any>("");
  const [benefitsUsedState, setBenefitsUsedState] = useState<any>([]);
  const [fidelityTokenState, setFidelityTokenState] = useState<any>("");

  const [loyaltyUserState, setLoyaltyUserState] = useState<ILoyaltyUser>({
    id: 0,
    modalidade: "",
    pontosProximaModalidade: 0,
    proximaModalidade: '',
    saldo: 0,
  });

  const [drawerState, setDrawerState] = useState<any>({
    openDrawer: () => {},
    closeDrawer: () => {},
  });

  useEffect(() => {
    async function loadStorage() {
      const storagedSigned: any = await AsyncStorage.getItem("signed");
      const b2cToken: any = await AsyncStorage.getItem("B2CToken");
      const phoneNumber: any = await AsyncStorage.getItem("phoneNumber");
      const familyName = await AsyncStorage.getItem("familyName");
      const setGivenName = await AsyncStorage.getItem("givenName");
      const cpf = await AsyncStorage.getItem("cpf");
      const benefitsUsed = await AsyncStorage.getItem("benefitsUsed");
      const fidelityToken = await AsyncStorage.getItem("fidelityToken");

      // await new Promise(resolve => setTimeout(resolve, 2000))  <----testando o loading, fazendo ele demorar 2seg propositalmente
      if (
        fidelityToken &&
        storagedSigned &&
        b2cToken &&
        phoneNumber &&
        familyName &&
        setGivenName
      ) {
        setSignedValue(true);
        setB2CLoginTokenState(b2cToken);
        setTelephoneNumberState(phoneNumber);
        setFamilyNameState(familyName);
        setGivenNameState(setGivenName);
        setCpfState(cpf);
        setBenefitsUsedState(JSON.parse(`${benefitsUsed}`));
        setFidelityTokenState(fidelityToken);
      } else {
        setLoading(false);
        return signedOut();
      }
      setLoading(false);
    }
    loadStorage();
  }, []);
  const setFidelityToken = async (value: any) => {
    setFidelityTokenState(value);
    AsyncStorage.setItem("fidelityToken", value);
  };
  const setRequestCameraAccess = async (value: any) => {
    setRequestCameraAccessState(value);
  };
  const setBalance = async (balance: any) => {
    setBalanceState(balance);
  };
  const setModality = async (modality: any) => {
    setModalityState(modality);
  };
  const setUserId = async (id: any) => {
    setUserIdState(id);
  };

  const setDrawer = async (drawer: any) => {
    setDrawerState(drawer);
  };

  const setTelephoneNumber = async (phoneNumber: any) => {
    AsyncStorage.setItem("phoneNumber", phoneNumber);
    setTelephoneNumberState(phoneNumber);
  };

  const setFamilyName = async (name: any) => {
    AsyncStorage.setItem("familyName", name);

    setFamilyNameState(name);
  };

  const setGivenName = async (name: any) => {
    AsyncStorage.setItem("givenName", name);

    setGivenNameState(name);
  };

  const setCpf = async (cpf: string) => {
    AsyncStorage.setItem("cpf", cpf);

    setCpfState(cpf);
  };

  const setB2CLoginToken = async (token: any) => {
    if (!token) {
      throw new Error("Token is missing");
    }

    AsyncStorage.setItem("B2CToken", token);
    setB2CLoginTokenState(token);
  };

  const signIn = async () => {
    AsyncStorage.setItem("signed", "true");
    setSignedValue(true);
  };
  const signedOut = async () => {
    const asyncStorageKeys = await AsyncStorage.getAllKeys();
    if (asyncStorageKeys.length > 0) {
      if (Platform.OS === "android") {
        return await AsyncStorage.clear();
      }
      if (Platform.OS === "ios") {
        return await AsyncStorage.multiRemove(asyncStorageKeys);
      }
      if (Platform.OS === "web") {
        localStorage.clear();
      }
    }
    await AsyncStorage.clear();
    setSignedValue(false);
    setB2CLoginTokenState(null);
    setTelephoneNumberState(null);
    setFamilyNameState(null);
    setGivenNameState(null);
    setCpfState(null);
  };

  const setModalVisible = (modalVisible: any) => {
    setModalVisibleState(modalVisible);
  };

  const setTextModal = (textModal: any) => {
    setTextModalState(textModal);
  };

  const setBenefitsUsed = (benefitsUsed: any) => {
    AsyncStorage.setItem("benefitsUsed", JSON.stringify(benefitsUsed));
    setBenefitsUsedState(benefitsUsed);
  };

  const setLoyaltyUser = (loyaltyUser: ILoyaltyUser) => {
    setLoyaltyUserState(loyaltyUser)
  }

  return (
    <AuthContext.Provider
      value={{
        signed: signedValue,
        signIn,
        signedOut,
        loading,
        B2CLoginToken: B2CLoginTokenState,
        setB2CLoginToken,
        cpf: cpfState,
        setCpf,
        givenName: givenNameState,
        setGivenName,
        familyName: familyNameState,
        setFamilyName,
        telephoneNumber: telephoneNumberState,
        setTelephoneNumber,
        drawer: drawerState,
        setDrawer,
        userId: userIdState,
        setUserId,
        modality: modalityState,
        setModality,
        balance: balanceState,
        setBalance,
        setModalVisible,
        modalVisible: modalVisibleState,
        requestCameraAccess: requestCameraAccessState,
        setRequestCameraAccess,
        textModal: textModalState,
        setTextModal,
        benefitsUsed: benefitsUsedState,
        setBenefitsUsed,
        fidelityToken: fidelityTokenState,
        setFidelityToken,
        loyaltyUser: loyaltyUserState,
        setLoyaltyUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
