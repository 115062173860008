import React from "react";
import { View, Text, StyleSheet } from "react-native";
import DefaultButton from "../../components/buttons/defaultButton";

const ErrorScreen = ({ navigation, route }) => {
  const { errorMessage } = route?.params;

  return (
    <View style={styles.main}>
      <Text style={styles.errorText}>
       {errorMessage}
      </Text>
      <View style={styles.buttonContainer}>
      <DefaultButton theme="Dark" onPress={() => navigation.goBack()} text="OK" />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  main: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 10,
  },
  errorText: {
    fontSize: 26,
    fontWeight: "bold",
    lineHeight: 36,
  },
  buttonContainer: {
    width: "100%",
    marginTop: 40,
  },
});

export default ErrorScreen;
