import styled  from 'styled-components/native';

export const Container = styled.View`
display: flex;
flex-direction: column;
width: 100%;
`

export const Item = styled.Pressable`
display: flex;
flex-direction: row;
width: 100%;
background-color: ${props=> props.theme.colors.secondBackground};
justify-content: space-between;
margin-bottom: 10px;
`

export const ImageItem = styled.Image`
width: 150px;
height: 150px;

`
