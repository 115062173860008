import AuthRequest from "../../config/AuthRequest";
import axios from "axios";

export const getBenefits = async (token: string) => {
  const response =  await axios.get(`${AuthRequest.fidelityApi}/benefits/getBenefits`, {
      headers:{
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
      }
  })
  return response;
}

export const redemptionBenefit = async (
  clientID: string,
  benefitID: number,
  token: string,
) => {
  try {
    const response = await axios.post(
      `${AuthRequest.fidelityApi}/benefits/redemptionBenefit`,
      {
        clientID,
        benefitID,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (err) {
    return err;
  }
};
