import { Platform } from 'react-native';
import styled  from 'styled-components/native';

export const Container = styled.View`
display: flex;
justify-content: space-between
flex-direction: row;
width: 100%;
align-items: center;
height: ${Platform.OS === 'web' ? 80: 60}px;
border-bottom-width: 1px;
border-bottom-color: #FFFF;
`