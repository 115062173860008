import React, { useContext, useEffect, useState } from 'react';
import { View, ActivityIndicator, Platform } from 'react-native'
import { QueryClient, QueryClientProvider } from 'react-query'
import AuthContext from '../context';
import StackRoutes from './public/Stack';
import TabRoutes from './private/Tab';
import verifyVersion from '../services/verifyVersion';
import * as Linking from 'expo-linking';
import ModalAlert from '../components/modalAlert';

const queryClient = new QueryClient()

// const Routes = ({ signed }) => {
const Routes = () => {

  const [isCurrentVersion, setIsCurrentVersion] = useState(true);
  const [modalAlertCurrentVersion, setModalAlertCurrentVersion] = useState(true);

  useEffect(()=>{
    (async () => {
        const response = await verifyVersion();
        if(response?.status === 426) {
          setIsCurrentVersion(false);
        }
    })();
  },[]);

  const { signed, loading } = useContext(AuthContext)

  const updateApp = () => {
    if(Platform.OS === 'android') {
      Linking.openURL('https://play.google.com/store/apps/details?id=com.solar.fidelidade')
    } else if(Platform.OS === 'ios') {
      Linking.openURL('https://apps.apple.com/br/app/solar-shopping-leblon/id1627338175')
    }
  }

  const cancelUpdateApp = () => {
    setModalAlertCurrentVersion(false);
  }

  if (loading) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ActivityIndicator size="large" color="#666"></ActivityIndicator>
      </View>
    )
  }

  if (!isCurrentVersion && modalAlertCurrentVersion) {
    return (
      <ModalAlert 
          text="Para melhor uso do aplicativo, atualize a versão do app em sua loja de aplicativos."
          textButtonConfirm="Atualizar"
          textButtonCancel="Cancelar"
          isVisible={modalAlertCurrentVersion}
          onPressConfirm={() => updateApp()}
          onPressCancel={() => cancelUpdateApp()}
      />
    )
  }

  /**
   * To do:
   * Implementar validação por signed e tambem incluir:  cpf, givenName, familyName
   * 
   */
  // return signed ? <TabRoutes/> :  <StackRoutes />
  return signed ? (
    <QueryClientProvider client={queryClient}>
        <TabRoutes/>
    </QueryClientProvider>) : 
    (
      <QueryClientProvider client={queryClient}>
         <StackRoutes />
      </QueryClientProvider>
    )
   
    

  
  //  <>
  // <MyDrawer/>
  // <StackRoutes />
  // </>
  // return <AppRoutes />
}

export default Routes;