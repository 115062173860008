import React from "react";
import Svg, { G, Path } from "react-native-svg";

function AllCategoriesIcon() {
  return (
    <Svg width="40" height="40" fill="none" viewBox="0 0 40 40">
      <G clipPath="url(#clip0_414_351)">
        <Path
          fill="#000"
          d="M31.5403 38.774C29.8653 38.7752 28.2275 38.2944 26.8342 37.3924C25.4409 36.4904 24.3547 35.2076 23.7131 33.7065C23.0715 32.2054 22.9033 30.5534 23.2297 28.9595C23.5562 27.3656 24.3627 25.9015 25.5471 24.7524C26.7315 23.6033 28.2407 22.8209 29.8836 22.5042C31.5265 22.1875 33.2294 22.3507 34.7766 22.9731C36.3239 23.5956 37.6461 24.6494 38.5759 26.0011C39.5057 27.3528 40.0013 28.9417 39.9999 30.5668C39.9964 32.7424 39.104 34.8279 37.5183 36.3663C35.9326 37.9048 33.7829 38.7705 31.5403 38.774ZM31.5403 23.8459C30.1699 23.8447 28.8299 24.2377 27.6898 24.9755C26.5497 25.7132 25.6607 26.7624 25.1353 27.9903C24.61 29.2183 24.4719 30.5698 24.7384 31.874C25.0049 33.1781 25.6642 34.3763 26.6328 35.3168C27.6014 36.2574 28.8357 36.8981 30.1797 37.158C31.5237 37.4178 32.9169 37.2851 34.1832 36.7766C35.4494 36.2681 36.5317 35.4067 37.2933 34.3013C38.0548 33.196 38.4612 31.8963 38.4612 30.5668C38.4577 28.7865 37.7276 27.08 36.4307 25.8205C35.1337 24.561 33.3754 23.8511 31.5403 23.8459Z"
        ></Path>
        <Path
          fill="#000"
          d="M16.1532 38.7743H0.766028C0.563403 38.7726 0.36957 38.6938 0.226287 38.5548C0.0830042 38.4158 0.00173937 38.2277 5.34214e-10 38.0312V23.1031C-7.57173e-06 22.9054 0.0804855 22.7157 0.223955 22.5754C0.367425 22.435 0.562255 22.3552 0.766028 22.3535H16.1532C16.3581 22.3535 16.5547 22.4325 16.6996 22.5731C16.8445 22.7137 16.9259 22.9043 16.9259 23.1031V38.0312C16.9241 38.2288 16.842 38.4179 16.6972 38.557C16.5525 38.6962 16.357 38.7743 16.1532 38.7743ZM1.53872 37.2815H15.3872V23.8463H1.53872V37.2815Z"
        ></Path>
        <Path
          fill="#000"
          d="M27.6901 16.3883H12.3096C12.1763 16.3904 12.0447 16.3587 11.9279 16.2964C11.8111 16.2341 11.7131 16.1432 11.6435 16.0329C11.5737 15.9244 11.5335 15.8005 11.5265 15.6728C11.5195 15.545 11.546 15.4177 11.6036 15.3026L19.3171 0.38109C19.3877 0.264477 19.4884 0.167778 19.6093 0.100587C19.7302 0.0333958 19.8672 -0.00195312 20.0066 -0.00195312C20.1459 -0.00195312 20.2829 0.0333958 20.4038 0.100587C20.5247 0.167778 20.6255 0.264477 20.696 0.38109L28.3829 15.3026C28.4411 15.4151 28.4692 15.54 28.4646 15.6658C28.46 15.7917 28.4227 15.9143 28.3564 16.0225C28.29 16.1306 28.1966 16.2209 28.0848 16.2848C27.9731 16.3487 27.8465 16.3843 27.7168 16.3883H27.6901ZM13.5553 14.8955H26.4512L19.9832 2.38442L13.5553 14.8955Z"
        ></Path>
      </G>
    </Svg>
  );
}

export default AllCategoriesIcon;
