import React from "react";
import { View, FlatList, ListRenderItemInfo, Image } from "react-native";
import { formatLumisUrl } from "../../pages/utils";

import { CategoryContainer, CategoryButton } from "./styles";
import { MediumTextBold } from "../../styles/text/boldText/mediumTextBold";
import AllCategoriesIcon from "../../assets/svg/all-categories";

export interface ICategory {
  idCategoria: string;
  imagem: string;
  posicao: string;
  nome: string;
}

type ICategoryProps = {
  categories: ICategory[];
  onCategoryPress: (categoryId: string) => void;
};

const BenefitsCategoryHeader: React.FC<ICategoryProps> = (props) => {
  const renderCategoryItem = (category: ICategory) => {
    return (
      <CategoryContainer key={category.idCategoria}>
        <CategoryButton
          onPress={() => props.onCategoryPress(category.idCategoria)}
        >
          {category.idCategoria === "All" ? (
            <AllCategoriesIcon />
          ) : (
            <Image
              source={{ uri: formatLumisUrl(category.imagem) }}
              style={{ width: 40, height: 40 }}
            />
          )}
        </CategoryButton>
        <MediumTextBold>
          {category.nome?.toUpperCase() || ""}
        </MediumTextBold>
      </CategoryContainer>
    );
  };

  return (
    <View>
      <FlatList
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        data={props.categories}
        renderItem={({ item }: ListRenderItemInfo<ICategory>) =>
          renderCategoryItem(item)
        }
        keyExtractor={(item: ICategory) => item.idCategoria}
      />
    </View>
  );
};

export default BenefitsCategoryHeader;
