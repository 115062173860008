import { Platform } from 'react-native';
import styled  from 'styled-components/native';

export const Container = styled.View`
width: ${props=> Platform.OS !=='web' ?280:props.theme.ScreenWidth>1800?500:380 }px;
background-color: ${props=> props.theme.colors.tier.backgroundColor};
min-height: 500px;
display: flex;
align-items: center;
border-top-width: 4px;
margin-bottom: 30px;
margin-right: 20px;

`
export const ViewBottomLine = styled.View`
display: flex;
align-items: center;
width: 100%;
border-bottom-width: 1px;
border-bottom-color: ${props => props.theme.colors.gray2};
padding: 5px;
margin-bottom: 10px;
`


export const Dot = styled.View`
width: 8px;
height: 8px;
border-radius:50px;

margin-top: -5px;
margin-right: 10px;

`

export const Tier = styled.Text`
font-size: 16px;
font-weight: bold;
margin-top: 10px;
color: ${props=> props.theme.colors.tier.title};
`
export const Desc = styled.Text`
font-size: 14px;
font-weight: bold;
margin-top: 10px;
margin-bottom: 10px;

color: ${props=> props.theme.colors.tier.desc};

`

