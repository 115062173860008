import React, { useContext, useState } from 'react';
import { Image, ImageBackground, Pressable, View, Text, TouchableOpacity, TouchableHighlight } from 'react-native';
import Back from '../../components/back/index';
import AuthContext from '../../context';
import { useQuery } from "react-query";
import { getSpecificBenefit } from '../../services/get';
import DashedLine from 'react-native-dashed-line';
import { AntDesign } from '@expo/vector-icons';

import { Container, Ticket, Circle, CircleBottom, BenefitImage, Opacity, Loading } from './styles';
import { Wrapper } from '../../styles/containers/main/wrapper';
import { SecondContentAligned } from '../../styles/containers/second/secondContentAligned';
import { SecondMainCotainer } from '../../styles/containers/second/secondMainContainer';
import { SecondWrapper } from '../../styles/containers/second/secondWrapper';
import { defaultTheme } from '../../styles/themes/default';
import { BigTextBold } from '../../styles/text/boldText/bigTextBold';
import { ContentCenter } from '../../styles/containers/main/contentCenter';
import { BigText } from '../../styles/text/texts/bigText';
import SwipeVoucher from '../../../assets/swipe-voucher.png';
import ModalAlert from '../../components/modalAlert';
import BackWithPoints from '../../components/backWithPoints';
import AuthRequest from '../../config/AuthRequest';
import sendMail from '../../services/sendEmail';
import moment from 'moment'
export const Voucher:React.FC<any> = ({navigation, route}) => {
    const {fidelityToken, userId, loyaltyUser, benefitsUsed, setBenefitsUsed,givenName,familyName,cpf } = useContext(AuthContext);

    const [startTouch, setStartTouch] = useState(0);
    const [endTouch, setEndTouch] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [colorTouchable, setColorTouchable] = useState(defaultTheme.colors.secondBackground);
    const { benefitID, vouchers, voucherId, imageURI } = route?.params;
    
    const { data, isError, isLoading, status } = useQuery(["specific-benefit", benefitID, fidelityToken], getSpecificBenefit);
    const handleInTouch = (e:any) => {
        setColorTouchable(defaultTheme.colors.gold)
        setStartTouch(e.nativeEvent.locationX);
    }

    const handleOutTouch = async (e:any) => {
        setColorTouchable(defaultTheme.colors.secondBackground)
        setEndTouch(e.nativeEvent.locationX);
        if(startTouch < 100 && e.nativeEvent.locationX > (defaultTheme.ScreenWidth - 100)) {
            setModalVisible(true)
        }
    }

    const sendMailFnc = () =>{
        sendMail(
            fidelityToken,
            `Uso de benefício ${givenName} ${familyName}`, 
            `<p> Nome: ${givenName} ${familyName} </p> <br/> <p>CPF: ${cpf} </p> <br/> <p> Beneficio Usado: ${data?.data?.nome} </p> <br/>  <p> Data: ${ moment().format('DD/MM/YYYY , h:mm:ss a')} </p>`
        )
    }

    const handleConfirmUsed = () => {
        let benefitsUsedIndex = benefitsUsed?.findIndex((e:any) => e?.user_id === userId);
        
        var benefitsUsedTemp:any = [];
        if(benefitsUsed !== null) {
            benefitsUsedTemp = benefitsUsed?.map((e:any) => {
                if(e?.user_id === userId) {
                    e?.benefits.push(benefitID)
                    return e;
                } else {
                    return e;
                }
            })
        }

        if(benefitsUsedIndex < 0 || benefitsUsedIndex === null || benefitsUsedIndex === undefined) {
            benefitsUsedTemp.push({
                user_id: userId,
                benefits: [benefitID]
            });
        }
        if(benefitsUsedTemp === undefined || benefitsUsedTemp === null) {
            benefitsUsedTemp = [];
        }
        setBenefitsUsed(benefitsUsedTemp)
        setModalVisible(false)
        sendMailFnc()
        navigation.navigate('MyBenefits')
    }
    const isUsed = benefitsUsed?.findIndex((e:any) => e?.benefits.includes(benefitID) && e?.user_id === userId);
    const render = () => {
        return (
          <>
            <Container showsVerticalScrollIndicator={false}>
              <Wrapper
                style={{
                  backgroundColor: defaultTheme.colors.secondBackground,
                }}
              >
                <ContentCenter style={{ marginTop: 20 }}>
                  <Ticket>
                    <ContentCenter>
                      <Circle />
                      {/* <Image  
                                    style={{width: 100, height: 75, marginTop: 40}}
                                    source={{uri: 'https://cdn.freebiesupply.com/images/thumbs/2x/burger-king-logo.png'}} 
                                /> */}
                    </ContentCenter>
                    <BigTextBold style={{ marginTop: 50 }}>
                      {data?.data?.nome}
                    </BigTextBold>
                    <BigText style={{ marginTop: 20 }}>
                      {data?.data?.descricaoVoucher}
                    </BigText>
                    <View style={{ marginTop: 20, marginBottom: !!vouchers.length ? 0 : 100 }} />
                    {/* <BigTextBold style={{ marginTop: 20, marginBottom: !!vouchers.lenght ? 0 : 100 }}>
                      Benefício válido até{" "}
                      {data?.data?.validade
                        ? data?.data?.validade.split("-").reverse().join("/")
                        : ""}
                    </BigTextBold> */}
                    {!!vouchers.length && (
                      <View>
                        <BigTextBold style={{ marginTop: 0 }}>
                          Código
                        </BigTextBold>
                        <BigTextBold
                          style={{
                            marginTop: 10,
                            marginBottom: 100,
                            color: defaultTheme.colors.gold,
                          }}
                        >
                          {voucherId}
                        </BigTextBold>
                      </View>
                    )}
                    <BenefitImage source={{ uri: imageURI }}>
                      {isUsed >= 0 ? null : (
                        <TouchableOpacity
                          onPressIn={(e) => handleInTouch(e)}
                          onPressOut={(e) => handleOutTouch(e)}
                          activeOpacity={0.7}
                          style={{
                            width: defaultTheme.ScreenWidth * 0.9,
                            height: 80,
                            zIndex: 999,
                            marginTop: -40,
                            paddingTop: 40,
                          }}
                        >
                          <DashedLine
                            dashLength={15}
                            dashThickness={15}
                            dashGap={5}
                            dashColor={colorTouchable}
                            dashStyle={{
                              borderRadius: 5,
                              marginTop: -10,
                              zIndex: 99999,
                            }}
                          />
                        </TouchableOpacity>
                      )}

                      <Opacity />
                      <ContentCenter style={{ height: 250 }}>
                        {isUsed >= 0 ? null : (
                          <Image
                            source={SwipeVoucher}
                            style={{
                              width: 200,
                              height: 23,
                              position: "absolute",
                              top: 0,
                              left: 20,
                            }}
                          />
                        )}

                        <CircleBottom />
                      </ContentCenter>
                    </BenefitImage>
                  </Ticket>

                  <BigTextBold
                    style={{
                      marginTop: 20,
                      display: "flex",
                      textAlign: "center",
                    }}
                  >
                    {`A apresentação deste voucher\natravés do aplicativo é indispensável.`}
                  </BigTextBold>

                  <Pressable
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      marginBottom: 250,
                      marginTop: 20,
                    }}
                  >
                    {/*} <BigTextBold>
                                Ir para meus resgates
                            </BigTextBold>
                            <AntDesign name="right" size={18} color="black" /> */}
                  </Pressable>
                </ContentCenter>
              </Wrapper>
            </Container>
          </>
        );
    }

    const renderLoading = () => {
        return(
            <>
            <Container>
                <Wrapper style={{backgroundColor: defaultTheme.colors.secondBackground}}>
                    <ContentCenter style={{marginTop: 20}}>
                        <Loading  
                            primaryColor="#ffffff" 
                            secondaryColor="lightgray" 
                            duration={5000} 
                            style={{
                                height: 20,
                                marginTop: 20
                            }}
                        />
                        <Loading  
                            primaryColor="#ffffff" 
                            secondaryColor="lightgray" 
                            duration={5000} 
                            style={{
                                height: 20,
                                marginTop: 20
                            }}
                        />

                        <Ticket>
                            <ContentCenter>
                                <Circle />
                                <Loading  
                                    primaryColor="#ffffff" 
                                    secondaryColor="lightgray" 
                                    duration={5000} 
                                    style={{
                                        height: 75,
                                        marginTop: 80,
                                        width: 100
                                    }}
                                />
                            </ContentCenter>
                            <Loading  
                                primaryColor="#ffffff" 
                                secondaryColor="lightgray" 
                                duration={5000} 
                                style={{
                                    height: 20,
                                    marginTop: 20
                                }}
                            />
                            <Loading  
                                primaryColor="#ffffff" 
                                secondaryColor="lightgray" 
                                duration={5000} 
                                style={{
                                    height: 100,
                                    marginTop: 20
                                }}
                            />
                            <Loading  
                                primaryColor="#ffffff" 
                                secondaryColor="lightgray" 
                                duration={5000} 
                                style={{
                                    height: 20,
                                    marginTop: 20
                                }}
                            />
                            <Loading  
                                primaryColor="#ffffff" 
                                secondaryColor="lightgray" 
                                duration={5000} 
                                style={{
                                    height: 20,
                                    marginTop: 20
                                }}
                            />
                            <Loading  
                                primaryColor="#ffffff" 
                                secondaryColor="lightgray" 
                                duration={5000} 
                                style={{
                                    height: 20,
                                    marginTop: 20
                                }}
                            />

                            <Loading  
                                primaryColor="#ffffff" 
                                secondaryColor="lightgray" 
                                duration={5000} 
                                style={{
                                    height: 250,
                                    marginTop: 20
                                }}
                            />
                            
                        </Ticket>

                        <Loading  
                            primaryColor="#ffffff" 
                            secondaryColor="lightgray" 
                            duration={5000} 
                            style={{
                                height: 20,
                                marginTop: 20
                            }}
                        />

                        <Loading  
                            primaryColor="#ffffff" 
                            secondaryColor="lightgray" 
                            duration={5000} 
                            style={{
                                height: 20,
                                marginTop: 20
                            }}
                        />  
                        
                    </ContentCenter>
                </Wrapper>
            </Container>
            </>
        )
    }

    return(
        <>
            <SecondMainCotainer>
                <SecondWrapper>
                    <SecondContentAligned> 
                        <BackWithPoints text="MEUS RESGATES" title="MEUS PONTOS" points={loyaltyUser.saldo} size="big" redirect='back' navigation={navigation}/>
                        {/* <Back title="MEUS BENEFÍCIOS" size="big" redirect='MyBenefits' navigation={navigation} /> */}
                    </SecondContentAligned>
                </SecondWrapper>
            </SecondMainCotainer>

            {isLoading ? renderLoading() : render()}

            <ModalAlert 
                text="Confirma que deseja utilizar este benefício?"
                textButtonConfirm="CONFIRMAR" 
                textButtonCancel="CANCELAR"
                isVisible={modalVisible}
                onPressConfirm={handleConfirmUsed}
                onPressCancel={() => setModalVisible(!setModalVisible)}
            />
            
        </>
    )

}

export default Voucher;