import { Platform } from 'react-native';
import styled  from 'styled-components/native';

export const ButtonDark = styled.TouchableOpacity`
width: ${Platform.OS === 'web' ?48:100}%;
display: flex;
justify-content: center;
align-items: center;
/* background-color: ${props => props.theme.colors.defaultButton.background}; */
background-color: black;

border-color: ${props => props.theme.colors.defaultButton.borderColor};
border-width: 1px;
padding: 10px;
margin-top:10px;
margin-bottom:10px;
`
export const Button = styled.TouchableOpacity`
width: ${Platform.OS === 'web' ?48:100}%;
display: flex;
justify-content: center;
align-items: center;
/* background-color: ${props => props.theme.colors.defaultButton.background}; */
background-color: transparent;

border-color: ${props => props.theme.colors.defaultButton.borderColor};
border-width: 1px;
padding: 10px;
margin-top:10px;
margin-bottom:10px;

`
export const TextWhiteButton = styled.Text`
color: white;
font-size: 16px;
font-weight: bold;
`
export const TextButton = styled.Text`
color: ${props=> props.theme.colors.defaultButton.textColor};
font-size: 16px;
font-weight: bold;
`