import styled from "styled-components/native";
import MaskInput from "react-native-mask-input";

export const ScrollViewContainer = styled.ScrollView`
  background-color: ${(props) => props.theme.colors.secondBackground};
`;
export const SolarLogo = styled.Image`
  height: 50px;
  margin-top: 80px;
  resize-mode: contain;
`;
export const LeblonLogo = styled.Image`
  height: 80px;
  margin-top: 60px;
  resize-mode: contain;
`;
export const FormContainer = styled.View`
  margin-top: 60px;
  padding-horizontal: 20px;
  width: 100%;
`;

export const FormRow = styled.View`
  width: 100%;
  flex-direction: row;
`;

export const TextInputField = styled(MaskInput)`
  flex-grow: 1;
  border-bottom-width: 1px;
  border-bottom-color: ${(props) => props.theme.colors.gray};
  height: 50px;
  margin-horizontal: 10px;
  margin-top: 10px;
`;
