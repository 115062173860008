import AuthRequest from '../config/AuthRequest';
import axios from 'axios';
import URL from '../config/URL';


const getAlianseUser = async (cpf:any, token:any)=>{
    try{
        const getToken = await axios.post(`${AuthRequest.fidelityApi}/sensedia/getUser`,{
            "cpf": cpf
        },
        {
            headers:{
                "Content-Type": "application/json",
                "application": AuthRequest.Application,
                "Authorization": `Bearer ${token}`,
            }
        })
        return getToken
    }catch(e){

        return e;
    }

}

export default getAlianseUser