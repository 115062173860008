import React from "react";
import Svg, { G, Path } from "react-native-svg";

function LockIcon({ color }: { color: string }) {
  return (
    <Svg width="21" height="24" fill={color} viewBox="0 0 11 14">
      <G fill={color} clipPath="url(#clip0_279_353)">
        <Path d="M9.9 6.332h-.186V4a3.911 3.911 0 00-1.235-2.835A4.33 4.33 0 005.498-.01a4.33 4.33 0 00-2.982 1.174A3.911 3.911 0 001.281 4v2.333H1.1c-.292 0-.572.11-.778.306a1.02 1.02 0 00-.322.74v5.6c.006.273.125.533.33.724.206.19.482.298.77.298h8.8c.145 0 .288-.027.421-.08.134-.053.255-.13.357-.227.102-.097.183-.212.238-.339a1 1 0 00.084-.4v-5.6a1.022 1.022 0 00-.33-.724 1.13 1.13 0 00-.77-.298zM2.38 4c0-.786.329-1.54.914-2.095a3.2 3.2 0 012.204-.868 3.2 3.2 0 012.204.868c.584.555.912 1.309.912 2.095v2.333H2.381V4zm7.52 8.955H1.1v-5.6h8.8v5.6z"></Path>
        <Path d="M6.72 11.909l-.673-1.288c.248-.117.446-.31.564-.549.117-.238.147-.506.083-.762a1.162 1.162 0 00-.432-.648A1.268 1.268 0 005.5 8.41c-.277 0-.545.089-.762.252-.217.163-.37.392-.432.648-.064.256-.034.524.083.762.118.238.316.432.564.549l-.678 1.288H6.72z"></Path>
      </G>
    </Svg>
  );
}

export default LockIcon;
