import styled  from 'styled-components/native';

export const Container = styled.View`
display: flex;
flex-direction: row;
margin-top: 50px;
margin-bottom: 50px;
justify-content: space-between;

`

export const TiersContainer = styled.View`
display: flex;
flex-direction: column;
align-items: stretch;
justify-content: space-between;
height: 40px;
width: 50%;
`

export const GoldCircle = styled.View`
width: 14px;
height: 14px;
border-radius: 50px;
background-color: ${props => props.theme.colors.tier.gold} ;
`
export const SilverCircle = styled.View`
width: 14px;
height: 14px;
border-radius: 50px;
background-color: ${props => props.theme.colors.tier.silver} ;
`
export const BronzeCircle = styled.View`
width: 14px;
height: 14px;
border-radius: 50px;
background-color: ${props => props.theme.colors.tier.bronze} ;
`

export const PointsCircle = styled.View`
width: 14px;
height: 14px;
border-radius: 50px;
background-color: ${props => props.theme.colors.text} ;
`