import styled  from 'styled-components/native';

export const CategoryContainer = styled.View`
margin-horizontal: 20px;
margin-vertical: 20px;
align-items: center;
`

export const CategoryButton = styled.TouchableOpacity`
width: 80px;
height: 80px;
border-radius: 50px;
background-color: ${props => props.theme.colors.secondBackground};
justify-content: center;
align-items: center;
margin-bottom: 10px;
`

