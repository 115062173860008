import React from 'react';
import { View, Text } from 'react-native';
import { defaultTheme } from '../../styles/themes/default';

import { Container, Tier, Desc,Dot,ViewBottomLine } from './styles';

interface descTiers{
  title: string;
  promotion: any[];
}
interface props {
  tier: string;
  desc: string;
  border: 'Gold'| 'Silver'| 'Bronze';
  data:descTiers[]
}
  const TierProfits: React.FC<props> = ({tier, data,desc, border}) => {
  return (
      <Container style={border === 'Gold'? {borderTopColor: defaultTheme.colors.tier.goldBorder}:border === 'Silver'?{borderTopColor: defaultTheme.colors.tier.silverBorder}:{borderTopColor: defaultTheme.colors.tier.bronzeBorder} }>
          <ViewBottomLine >
          <View style={{ width:'80%'}}>

          <Tier>{tier}</Tier>
          <Desc>{desc}</Desc>

          </View>
          </ViewBottomLine>


        <View style={{width: '80%'}}>
          <View>
            {data.length>0 ? data.map((e, i)=>(
              <View style={{padding:10, marginBottom:10}} key={i}>
                <Text style={{fontWeight:'bold', marginBottom:10}}>
                {e.title}

                </Text>
                <View>
                  {e.promotion.map((el, index)=>(
                    <View style={{ display:'flex', flexDirection: 'row', alignItems:'center'}} key={index}>
                      <Dot style={border === 'Gold'? {backgroundColor: defaultTheme.colors.tier.goldBorder}:border === 'Silver'?{backgroundColor: defaultTheme.colors.tier.silverBorder}:{backgroundColor: defaultTheme.colors.tier.bronzeBorder}}/>
                      <Desc style={{marginTop:3}}>{el}</Desc>

                    </View>
                  ))}
                </View>
              </View>
            )) :null}
          </View>
        </View>
      </Container>
  )
}

export default TierProfits;