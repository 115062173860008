import styled  from 'styled-components/native';

export const Container = styled.View`
width: 100%;
min-height: 50px;
background-color: ${props=> props.theme.colors.background};
border-bottom-width: 1px;
border-bottom-color: ${props=> props.theme.colors.gray2};
display: flex;
flex-direction: row;
justify-content: space-evenly;
align-items: center;

`

export const BoxContainer = styled.View`
width: 100%;
left:10%;
min-height: 50px;
background-color: ${props=> props.theme.colors.background};
border-bottom-width: 1px;
border-bottom-color: ${props=> props.theme.colors.gray2};
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;

`