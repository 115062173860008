import React from 'react';
import { View, Text, Image, Platform } from 'react-native';
import { ScrollView, TouchableOpacity } from 'react-native-gesture-handler';
import profit from '../../../assets/profit.png';
import Celular from '../../../assets/Celular.png';
import qrCode from '../../../assets/qrCode.png';
import URL from '../../config/URL';
import { Container,ImageProfit, ItemContainer,ProfitText,Title,Name } from './styles';

interface profitDataContent{
  
    name: string;
    imgUrl: any;
  
}
interface profitItem {
    title: string;
    data:profitDataContent[]
}
interface props {
    data: profitItem[]
}
const Profits: React.FC<props> = ({data}) => {
  return (
      <Container>
        <View style={{display:'flex', width:'100%', alignItems: 'center', marginBottom:30}}>
        <ProfitText>BENEFÍCIOS</ProfitText>
        </View>
        {
          data.map((e:any, i:any)=>(
            <View key={i} style={{ width: '100%', justifyContent: 'space-between'}}>
              <View style={{ marginBottom: 10, paddingLeft: 10}}>
                <Title>
                  {e.title}
                </Title>
              </View>
              {Platform.OS ==='web' ? (
                <View style={{ width:'100%', display:'flex', flexDirection: 'row', justifyContent:'space-between'}}>
                                  {e.data.map(
                  (el:any, index: any)=>
                  (
                    <ItemContainer key={index}>
                      <ImageProfit style={{resizeMode: 'stretch'}} source={{uri: el.imgUrl}} />
                      <Name>{el.name}</Name>
                      </ItemContainer>
                  )
                )}
                </View>
              ):(
                <ScrollView  horizontal={true} showsHorizontalScrollIndicator={false}>
                {e.data.map(
                  (el:any, index: any)=> {
                    var [, imageSplit] = el.imgUrl.split('data/');
                    var imageURI = `${URL.urlBaseImages}/data/${imageSplit}`
                  return (
                    <ItemContainer key={index}>
                      <View style={{ height: 200, width: 200}}>
                      <ImageProfit style={{resizeMode: 'cover', height: '100%', width: '100%' }} source={{uri: imageURI}} />
                      </View>
                     
                      <Name>{el.imgName}</Name>
                      </ItemContainer>
                  )}
                )}
            </ScrollView> 

              )}

            </View>
          ))
        }

      </Container>
  )
}

export default Profits;