import { Platform } from 'react-native';
import styled  from 'styled-components/native';

export const Container = styled.View`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
/* margin-top: 80px; */
margin-top: ${Platform.OS ==='web'?-20:0}px;


/* background-color: red; */
`
export const StatementWrapper = styled.View`
display: flex;
width: 100%;
flex-direction: row;
justify-content: space-between;
border-bottom-color: ${props=> props.theme.colors.gray2};
border-bottom-width: 1px;
margin-top: 20px;
min-height: 50px;
align-items: center;
height: 50px;

`
export const Options = styled.View`
display: flex;
flex-direction:row;
`

export const Filter = styled.TouchableOpacity`
/* border-bottom-width: 1px; */
/* border-bottom-color: ${props => props.theme.colors.statement.borderFilterColor}; */
height: 50px;
display: flex;
justify-content: center;
align-items: center;
min-width: ${Platform.OS ==='web'?200:80}px;
`

export const DateView = styled.View`
margin-top: 30px;
width: 100%;
margin-bottom: 10px;
`
export const ListResult = styled.ScrollView`
${props=>Platform.OS==="web"?"height: 20%":"height: 440px"};
margin-top: 10px;
margin-bottom: 200px
`
export const DebitView = styled.View`
width:${props => ((props.theme.ScreenWidth*90)/100)}px;
display: flex;
justify-content: space-between;
flex-direction: row;
background-color: ${props=>props.theme.colors.secondBackground};
padding: 4px;
border-bottom-width:1px;
border-bottom-color:${props=>props.theme.colors.background} ;
`





