import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../context";
import { View, Text } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import Back from "../../components/back";
import DatePickerFilter from "../../components/datePickerFilter";
import ListBenefitsTierNew from "../../components/listBenefitsTierNew";
import { ContentAligned } from "../../styles/containers/main/contentAligned";
import { MainCotainer } from "../../styles/containers/main/mainContainer";
import { Wrapper } from "../../styles/containers/main/wrapper";
import { SecondContentAligned } from "../../styles/containers/second/secondContentAligned";
import { SecondMainCotainer } from "../../styles/containers/second/secondMainContainer";
import { SecondWrapper } from "../../styles/containers/second/secondWrapper";
import ModalAlert from "../../components/modalAlert";
import { useQuery } from "react-query";
import {
  getUserBenefits,
  getBenefitImagesLumisApi,
  getBenefits,
} from "../../services/get";
import { BigTextBold } from "../../styles/text/boldText/bigTextBold";
import Loading from "../../components/loading";
import BackWithPoints from "../../components/backWithPoints";

const MyBenefits: React.FC = ({ navigation, route }: any) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { userId, fidelityToken, loyaltyUser } =
    useContext(AuthContext);

  const benefitsQuery = useQuery(
    ["benefits", fidelityToken],
    getBenefits
  );
  const userBenefitsQuery = useQuery(
    ["user-benefits", userId, fidelityToken],
    getUserBenefits
  );
  const benefitsImagesQuery = useQuery(
    ["benefits-images", fidelityToken],
    getBenefitImagesLumisApi
  );

  var myBenefitsImages: any = [];

  if (!userBenefitsQuery.isLoading && !userBenefitsQuery.isError) {
    if (Array.isArray(userBenefitsQuery.data?.data)) {
      var myBenefits = userBenefitsQuery.data?.data.map((e: any) => {
        return e.beneficio.id;
      });
    }
  }

  if (!benefitsImagesQuery.isLoading && !benefitsImagesQuery.isError) {
    // console.log(benefitsImagesQuery.data?.data)
  }

  // if(!queryBenefitsImages.isLoading && !queryBenefitsImages.isError) {
  //     queryBenefitsImages.data?.data.filterIndex((e:any) => {
  //         // console.log(e);
  //         if(myBenefitsID.includes(e.idBeneficio)) {
  //             myBenefitsImages.push({
  //                 idBeneficio: e.idBeneficio,
  //                 imagem: e.imagem
  //             });
  //         }
  //     })
  //     console.log(myBenefitsImages);
  // }

  useEffect(() => {
    if (route.params?.redemption) {
      setModalVisible(true);
    }
  }, [route.params]);

  const renderLoading = () => {
    return (
      <>
        <Loading
          duration={5000}
          style={{
            height: 100,
            marginTop: 10,
          }}
        />
        <Loading
          duration={5000}
          style={{
            height: 100,
            marginTop: 10,
          }}
        />
        <Loading
          duration={5000}
          style={{
            height: 100,
            marginTop: 10,
          }}
        />
        <Loading
          duration={5000}
          style={{
            height: 100,
            marginTop: 10,
          }}
        />
      </>
    );
  };

  const getMyBenefitList = () => {
    const benefitList: any = [];

    userBenefitsQuery.data?.data.forEach((benefit: any) => {
      benefitList.push({
        benefit,
        benefitDetails: benefitsQuery.data?.data.find(
          (benefitItem: any) => benefitItem.idBeneficio === benefit.beneficio.id
        ),
      });
    });
    return benefitList;
  };

  const render = () => {
    return (
      <>
        {
          (benefitsQuery.isLoading || benefitsQuery.isError) &&
          !userBenefitsQuery.isError ? null : (
            <ListBenefitsTierNew
              myBenefitsIDs={myBenefits}
              benefitsImages={benefitsImagesQuery.data?.data}
              nav={navigation}
              params={route.params}
              myBenefits={true}
              myBenefitList={getMyBenefitList()}
            />
          )
          // null
        }

        {userBenefitsQuery.isError &&
        userBenefitsQuery.error?.response.status === 404 ? (
          <BigTextBold>Nenhum benefício encontrado</BigTextBold>
        ) : null}
      </>
    );
  };

  return (
    <>
      <SecondMainCotainer>
        <SecondWrapper>
          <SecondContentAligned>
            <BackWithPoints
              text="MEUS RESGATES"
              title="MEUS PONTOS"
              points={loyaltyUser.saldo}
              size="big"
              redirect="Inicio"
              navigation={navigation}
            />
            {/* <Back title="MEUS RESGATES" size="big" redirect='Inicio' navigation={navigation} /> */}
          </SecondContentAligned>
        </SecondWrapper>
      </SecondMainCotainer>

      <MainCotainer>
        <Wrapper>
          <ContentAligned>
            <ScrollView>
              {
                // To do:
                // Fazer os dados atualizarem quando CB for assionado
              }
              {/* <DatePickerFilter /> */}

              <View style={{ marginTop: 20 }}>
                {benefitsQuery.isLoading || userBenefitsQuery.isLoading
                  ? renderLoading()
                  : render()}
              </View>
            </ScrollView>
          </ContentAligned>
        </Wrapper>
        <ModalAlert
          text="Resgate enviado! Aguarde 24 Horas para conferir seu resgate em Meus Benefícios"
          textButtonConfirm="OK"
          isVisible={modalVisible}
          onPressConfirm={() => {
            setModalVisible(false);
          }}
        />
      </MainCotainer>
    </>
  );
};
export default MyBenefits;
