import React from "react";
import { Platform, Dimensions } from "react-native";
import styled from "styled-components/native";
import { FadeLoading } from "react-native-fade-loading";

const { width, height } = Dimensions.get("window");

export const Container = styled.View`
  margin-top: ${Platform.OS === "web" ? -20 : 20}px;
`;

export const ImageBenefit = styled.Image`
  margin-top: 20px;
`;

export const BenefitPoints = styled.View`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

export const Loading = styled(FadeLoading)`
  width: ${(props) => 0.9 * props.theme.ScreenWidth - 15}px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const LoadingContainer = styled.View`
  height: 100%; 
  width: 100%
  justify-content: center;
  align-items: center; 
  position: absolute;
  top: 0;
  background-color: #00000090;
`;
