import AuthRequest from '../config/AuthRequest';
import axios from 'axios';


const sendMail = async (
    token:any,
	subject:any,
	template:any
)=>{

    try{
        const getToken = await axios.post(`${AuthRequest.fidelityApi}/email/sendMail`,{
            "subject":subject, 
            "template":template
        },
        {
            headers:{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
        return getToken.data
    }catch(e){

        return "Request Error"
    }

}

export default sendMail