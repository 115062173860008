import React, { useContext } from "react";
import { TouchableOpacity, SectionList, FlatList, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import AuthContext from "../../context";

import { IBenefit } from "../../pages/BenefitsNew/types";

import {
  ListContainer,
  SectionHeaderContainer,
  SingleRow,
  BanefitImage,
} from "./styles";
import { BigTextBold } from "../../styles/text/boldText/bigTextBold";
import { MediumTextBold } from "../../styles/text/boldText/mediumTextBold";
import { MediumText } from "../../styles/text/texts/mediumText";
import { defaultTheme } from "../../styles/themes/default";

import LockIcon from "../../assets/svg/lock-icon";

import { formatLumisUrl } from "../../pages/utils";

import thousandsSeparator from "../../pages/utils/thousandsSeparator";
import GlobalMessages from "../../config/Messages";

interface IBenefitItem {
  title: "SOLAR BRONZE";
  tier: "BRONZE";
  data: IBenefit[];
}

type IBenefitListProps = {
  listData: IBenefitItem[];
  onSeeMorePress: (tier: string) => void;
};

type Nav = {
  navigate: (value: string,  params: any) => void;
}

const BenefitsSectionList: React.FC<IBenefitListProps> = ({ listData, onSeeMorePress }) => {
  const navigation = useNavigation<Nav>()
  const { loyaltyUser } = useContext(AuthContext);
  const { colors } = defaultTheme;

  const getLockedModality = (tier: string) => {
    if (loyaltyUser.modalidade === "OURO") return;

    if (loyaltyUser.modalidade === "PRATA") {
      return tier === "OURO";
    }

    if (loyaltyUser.modalidade === "BRONZE") {
      return tier === "OURO" || tier === "PRATA";
    }
  };

  const onListCardPress = (
    modality: string,
    id: number,
    uri: string,
    category: string
  ) => {
    navigation.navigate("SpecificBenefit", {
      modality: modality,
      benefitID: id,
      imageURI: uri,
      benefitCategory: category,
    });
  };

  const renderListItem = ( item: IBenefit, index: number, tier: string) => {
    return (
      <TouchableOpacity
        style={{ width: 170, marginVertical: 10 }}
        key={`${item}${index}`}
        onPress={() =>
          onListCardPress(
            tier,
            item.idBeneficio,
            formatLumisUrl(item.imagem),
            item.categoria.nome
          )
        }
      >
        <BanefitImage source={{ uri: formatLumisUrl(item.imagem) }} />
        <View style={{ paddingHorizontal: 5 }}>
          <MediumTextBold style={{ marginTop: 10 }}>{item.nome}</MediumTextBold>
          <MediumText>
            {thousandsSeparator(item.redemptionPoints, ".")}{" "}
            {GlobalMessages.pts}{" "}
          </MediumText>
        </View>
      </TouchableOpacity>
    );
  };

  const renderSectionHeader = (title: string, tier: string, data: any) => {
    const titleColor =
      tier === "OURO"
        ? colors.gold
        : tier === "PRATA"
        ? colors.silver
        : colors.bronze;

    const isLocked = getLockedModality(tier);

    return (
      <View key={title}>
        <SectionHeaderContainer>
          <SingleRow>
            {isLocked && <LockIcon color={titleColor} />}
            <BigTextBold
              style={{ color: titleColor, marginLeft: isLocked ? 10 : 0 }}
            >
              {title}
            </BigTextBold>
          </SingleRow>
          <MediumTextBold onPress={() => onSeeMorePress(tier)}>{"Ver mais"}</MediumTextBold>
        </SectionHeaderContainer>
        <FlatList
          horizontal
          data={data.slice(0, 6)}
          keyExtractor={(item, index) => `${item.title}${index}`}
          renderItem={({ item, index }) => renderListItem(item, index, tier)}
          showsHorizontalScrollIndicator={false}
        />
      </View>
    );
  };

  return (
    <ListContainer>
      <SectionList
        stickySectionHeadersEnabled={false}
        showsVerticalScrollIndicator={false}
        sections={listData}
        keyExtractor={(item, index) => `${item.idBeneficio.toString()}${index}`}
        renderItem={() => null}
        renderSectionHeader={({ section: { title, tier, data } }) =>
          renderSectionHeader(title, tier, data)
        }
        initialNumToRender={5}
      />
    </ListContainer>
  );
};

export default BenefitsSectionList;
