import React, { useEffect, useState, useContext } from "react";
import { TouchableOpacity, ActivityIndicator, View } from "react-native";
import AuthContext from "../../context";
import { IBenefit } from "./types";
import { ICategory } from "../../components/benefitsCategoryHeader";

import BackWithPoints from "../../components/backWithPoints";
import BenefitsCategoryHeader from "../../components/benefitsCategoryHeader";
import BenefitsSectionList from "../../components/benefitsSectionList";
import BenefitsFullList from "../../components/benefitsFullList";
import getLumisCategories from "../../services/lumisCategory";
import { getBenefits } from "../../services/benefits";
import { BigTextBold } from "../../styles/text/boldText/bigTextBold";
import { Feather } from "@expo/vector-icons";
import { defaultTheme } from "../../styles/themes/default";

const allCategories = {
  idCategoria: "All",
  imagem: "",
  posicao: "0",
  nome: "Todos",
};

const BenefitsNew: React.FC = ({ navigation }: any) => {
  const { fidelityToken, loyaltyUser } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false)
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [listData, setListData] = useState<any>();
  const [fullListData, setFullListData] = useState<any>();
  const [isFullView, setIsFullView] = useState<string | null>(null);

  const { colors } = defaultTheme;

  useEffect(() => {
    getBenefitContent()
  }, []);

  const getBenefitContent = async () => {
    setIsLoading(true)
    const lumisCategories = await getLumisCategories(fidelityToken);
    const benefits = await getBenefits(fidelityToken);

    const filteredCategories: ICategory[] = []

    lumisCategories.forEach((category: ICategory) => {
      const categoryName = benefits.data.find((benefit: IBenefit) => benefit.categoria.idCategoria.toString() === category.idCategoria)

      if (!categoryName) return
     
      filteredCategories.push({ ...category, nome: categoryName.categoria.nome }) 
    })

    setCategories(filteredCategories)
    setFullListData(benefits.data)
    arrangeBenefitsByTier(benefits.data)
    setIsLoading(false)
  }

  const applyFilter = (categoryId: string) => {
    const localData = fullListData;

    if(categoryId == "All") {
      return arrangeBenefitsByTier(localData)
    }
    
    const filteredData = localData.filter((benefit: IBenefit) => benefit.categoria.idCategoria.toString() === categoryId )
    arrangeBenefitsByTier(filteredData)
  }

  const arrangeBenefitsByTier = (benefits: IBenefit[]) => {
    const bronzeTierData: IBenefit[] = [];
    const silverTierData: IBenefit[] = [];
    const goldTierData: IBenefit[] = [];

    benefits.forEach((benefit) => {
      if (benefit.modalidades.some((modality) => modality.nome === "BRONZE")) {
        const redemptionPoints = benefit.modalidades.find(
          (modality) => modality.nome === "BRONZE"
        )!.pontos;
        bronzeTierData.push({ ...benefit, redemptionPoints: redemptionPoints });
      }

      if (benefit.modalidades.some((modality) => modality.nome === "PRATA")) {
        const redemptionPoints = benefit.modalidades.find(
          (modality) => modality.nome === "PRATA"
        )!.pontos;
        silverTierData.push({ ...benefit, redemptionPoints: redemptionPoints });
      }

      if (benefit.modalidades.some((modality) => modality.nome === "OURO")) {
        const redemptionPoints = benefit.modalidades.find(
          (modality) => modality.nome === "OURO"
        )!.pontos;
        goldTierData.push({ ...benefit, redemptionPoints: redemptionPoints });
      }
    });

    const listData = [
      {
        title: "SOLAR BRONZE",
        tier: "BRONZE",
        data: bronzeTierData,
      },
      {
        title: "SOLAR PRATA",
        tier: "PRATA",
        data: silverTierData,
      },
      {
        title: "SOLAR OURO",
        tier: "OURO",
        data: goldTierData,
      },
    ];

    setListData(listData);
  };

  if (isLoading) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ActivityIndicator size="large" color="#666"></ActivityIndicator>
      </View>
    )
  }

  return (
    <>
      <BackWithPoints
        text="BENEFÍCIOS"
        title="MEUS PONTOS"
        points={loyaltyUser.saldo}
        size="big"
        redirect="back"
        navigation={navigation}
      />
      <BenefitsCategoryHeader
        categories={[allCategories, ...categories]}
        onCategoryPress={(categoryId) => applyFilter(categoryId)}
      />
      {isFullView ? (
        <>
          <TouchableOpacity
            style={{
              flexDirection: "row",
              alignItems: "center",
              height: 60,
              paddingHorizontal: 20,
            }}
            onPress={() => setIsFullView(null)}
          >
            <Feather name="chevron-left" size={28} color={colors.text} />
            <BigTextBold>{"VOLTAR"}</BigTextBold>
          </TouchableOpacity>
          <BenefitsFullList
            listData={listData.find(
              (listItem: any) => listItem.tier === isFullView
            )}
          />
        </>
      ) : (
        <BenefitsSectionList
          listData={listData || []}
          onSeeMorePress={(tier) => setIsFullView(tier)}
        />
      )}
    </>
  );
};

export default BenefitsNew;
