import { Platform } from 'react-native';
import styled  from 'styled-components/native';

export const Container = styled.View`
display: flex;
width: 100%;
${Platform.OS ==='web'?'min-height:100px':null}
/* background-color: pink; */
flex-direction: row;
justify-content: space-between;
align-items: center;
`

export const Circles = styled.View`
display: flex;

height: 70px;
width: 70px;
background-color: ${props=>props.theme.colors.secondBackground};
border-radius: 50px;
justify-content: center;
align-items: center;
`
export const TouchableMenu = styled.TouchableOpacity`
height: ${Platform.OS ==='web'? 110:110}px;
width: ${Platform.OS ==='web'? 110:85}px;

`
// style={Platform.OS==='web'?{maxWidth:110, maxHeight:110, minHeight:110}:}