import React from 'react';
import { Platform, View,Text, TouchableOpacity, Image } from 'react-native';
import {Feather} from "@expo/vector-icons"

import { BoxContainer } from './styles';
import { defaultTheme } from '../../styles/themes/default';
import { DefaultText } from '../../styles/text/defaultText';
import { BigTextBold } from '../../styles/text/boldText/bigTextBold';
import { MediumTextBold } from '../../styles/text/boldText/mediumTextBold';
import thousandsSeparator from '../../pages/utils/thousandsSeparator';
import signal from '../../../assets/Vector.png'

const PointsWillExpire: React.FC<any> = ({points= '-1500', date= '17/05'}) => {
  return (
    <>
  <BoxContainer>
      <Image style={{marginTop: 14, marginBottom: 14, marginRight: 14, marginLeft: 10}}  source={signal} />
      <View style={{width:'70%',flexDirection:'row', justifyContent:'space-between'}}>
      <View>
      <MediumTextBold>Próximos pontos {"\n"}a expirar:</MediumTextBold>
      </View>
      <View>
      <DefaultText style={{color:defaultTheme.colors.gray}}>{date} </DefaultText>

      <MediumTextBold>{thousandsSeparator(points, '.')} </MediumTextBold>

      </View>

      <TouchableOpacity onPress={()=>console.log('slaaa')} >
        <Text>

        {/* <Feather  name='chevron-right' size={Platform.OS === "web"? 30: 30} color={defaultTheme.colors.text} /> */}
        </Text>

      </TouchableOpacity>
      </View>
  </BoxContainer>


    </>

  )
}

export default PointsWillExpire;