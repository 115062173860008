import React from 'react';
import { Button, TextButton } from './style';

const CancelButton: React.FC<any> = ({text, onPress}:any) => {

    return (
        <Button onPress={onPress }>
            <TextButton>{text}</TextButton>
        </Button>
    )

}

export default CancelButton