import styled from "styled-components/native";
import { Platform } from "react-native";

export const Button = styled.Pressable`
    background-color: ${props => props.theme.colors.confirmButton.background};
    width: ${Platform.OS === 'web' ?48:100}%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: ${props => props.theme.colors.confirmButton.borderColor};
    border-width: 1px;
    padding: 10px;
    margin-top:20px;
`;

export const TextButton = styled.Text`
    color: ${props => props.theme.colors.confirmButton.textColor};
    font-size: 16px;
    font-weight: bold;
`;
