import 'react-native-gesture-handler';
import { StatusBar } from 'expo-status-bar';
// import { ThemeProvider, DefaultTheme } from 'styled-components'
// import {ThemeProvider, DefaultTheme} from 'styled-components/native'
import {ThemeProvider, DefaultTheme} from 'styled-components/native'


import { Button, Platform, StyleSheet, Text, View } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import {defaultTheme} from './src/styles/themes/default'
import Routes from './src/routes/routes';
import { Provider } from './src/context';
import { useEffect, useState } from 'react';
import usePersistedThemeState from './src/hooks/usePersistedState';
import { TouchableOpacity } from 'react-native-gesture-handler';
import  Header  from './src/components/header';
import { useQuery } from 'react-query'




export default function App() {
  const [ startApp, setStartApp]= useState(false)
  const [theme, setTheme] = usePersistedThemeState<DefaultTheme>({ key: 'theme', initialState: defaultTheme })
  
 const loadPressets = async() =>{
   let loadedTheme = await theme
   setStartApp(true)
   /**
    * remover o seTheme da função caso queira que a cor do app persista de acordo com cache.
    * o setTheme foi colocado na função, pois quero que quando o app carregar ele priorize o tema 
    * setado nesse momento, ao invez do que foi guardado em cache
    */
   setTheme(defaultTheme)
   return loadedTheme
 }
//change theme - bom para fazer dark mode e light mode
//  const [ swtich, setSwitch] = useState(false)
//  const changeColor = () =>{
//    if(swtich){
//     return setTheme(defaultTheme), setSwitch(false)
//    }
//     return setTheme(defaultTheme2), setSwitch(true)
//  }

  useEffect(()=>{
    loadPressets()
  })

  return (
    <>
      { startApp ?
          (
            <NavigationContainer>
              <StatusBar  backgroundColor={defaultTheme.colors.statusBar}/>
              <Provider>
                <ThemeProvider theme={theme}>
                    {/* <Header></Header> */}
                    <Routes/>
                    {/* <Button onPress={changeColor} title='change color'></Button> */}
                </ThemeProvider>
              </Provider>
            </NavigationContainer>
          )
      : 
        (
          <>
            <Text>Loading</Text>
          </>
        )
      }
    </>
  );
}
