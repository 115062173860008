import React, { useContext } from "react";
import { Platform, View, Image } from "react-native";
import AuthContext from "../../context";
import { BigTextBold } from "../../styles/text/boldText/bigTextBold";
import { DefaultText } from "../../styles/text/defaultText";
import { Feather } from "@expo/vector-icons";
import userIcon from "../../../assets/icone_usuario.png";
import { Container } from "./styles";
import { BigText } from "../../styles/text/texts/bigText";

const InfoUser: React.FC<any> = () => {
  const { modality, givenName } = useContext(AuthContext);
  const blockedModality = modality === "INATIVOS";

  return (
    <Container>
      {/* <Feather style={{marginRight: 10}} name='user' size={Platform.OS === "web"? 40: 40} color="#000000" /> */}
      <Image style={{ marginRight: 8, marginTop: 4 }} source={userIcon} />

      <View>
        <BigTextBold>Bem vindo, {givenName} </BigTextBold>
        {!blockedModality && <BigText>Sua Categoria: {modality}</BigText>}
      </View>
    </Container>
  );
};

export default InfoUser;
