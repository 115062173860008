import { FadeLoading } from "react-native-fade-loading";
import styled from "styled-components/native";

export const LoadingStyle = styled(FadeLoading)`
    width: ${props => (0.9 * props.theme.ScreenWidth) - 15}px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
`;
