import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { color } from 'react-native-reanimated';

import { Button, ButtonDark, TextButton, TextWhiteButton} from './styles';

interface props{
    text: string
    onPress: any
    theme?: string
    buttonStyle?: object
}
const DefaultButton: React.FC<props> = ({theme="Light", text, onPress, buttonStyle}) => {
  return (
    <>
  
            {
              theme === 'Dark'?
              <ButtonDark onPress={onPress} style={buttonStyle}>
                <TextWhiteButton>{text}</TextWhiteButton>
              </ButtonDark>
              :
              <Button onPress={onPress} style={buttonStyle}>
                <TextButton>{text}</TextButton>
              </Button>
            }
     
            </>
  )
}

export default DefaultButton;