import React, { useContext } from 'react';
import { View, Text } from 'react-native';
import Back from '../../components/back';
import BackWithPoints from '../../components/backWithPoints';
import DefaultButton from '../../components/buttons/defaultButton';
import CurrentBalance from '../../components/currentBalance';
import InvoiceList from '../../components/invoiceList';
import AuthContext from '../../context';
import { ContentAligned } from '../../styles/containers/main/contentAligned';
import { MainCotainer } from '../../styles/containers/main/mainContainer';
import { Wrapper } from '../../styles/containers/main/wrapper';
import { SecondContentAligned } from '../../styles/containers/second/secondContentAligned';
import { SecondMainCotainer } from '../../styles/containers/second/secondMainContainer';
import { SecondWrapper } from '../../styles/containers/second/secondWrapper';

// import { Container } from './styles';
const Invoice: React.FC = ({navigation}:any) => {
const {setRequestCameraAccess, loyaltyUser} = useContext(AuthContext)

  return (
<>
        <SecondMainCotainer>
            <SecondWrapper>
                <SecondContentAligned> 
                    {/* <Back title="NOTA FISCAL" size="big" redirect='Inicio' navigation={navigation} /> */}
                    <BackWithPoints  text="NOTA FISCAL" title="MEUS PONTOS" points={loyaltyUser.saldo} size="big" redirect='back' navigation={navigation}/>
                    {/* <CurrentBalance /> */}
                    <DefaultButton 
                        text='ADICIONAR NOTA FISCAL' 
                        onPress={()=>{
                            setRequestCameraAccess(false),
                            navigation.navigate({name:'BarCodeScanner'})
                        }} 
                        buttonStyle={{
                            width: '90%',
                            marginLeft: '5%',
                        }}
                    />
                </SecondContentAligned>
            </SecondWrapper>
        </SecondMainCotainer>

        <MainCotainer>
            <Wrapper>
                <ContentAligned> 
                    <InvoiceList />
                </ContentAligned>
            </Wrapper>
        </MainCotainer>


    </>
    )
}

export default Invoice;