import React from 'react';
import { Dimensions, Platform } from 'react-native';
import styled  from 'styled-components/native';

//definindo a largura da tela, margem em 80%
// width:  ${props=> ((props.theme.ScreenWidth * 80)/100)};
export const Wrapper = styled.View`
background-color: ${props => props.theme.colors.background};
display: flex;
align-items: center;

/* height: ${props => props.theme.ScreenHeight}px; */
width:  ${props=> props.theme.ScreenWidth}px;
`