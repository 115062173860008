const thousandsSeparator = (val:any,sep:any)  =>{
    var sRegExp = new RegExp('(-?[0-9]+)([0-9]{3})'),
    sValue=parseInt(val)+'';

    if (sep === undefined) {sep=',';}
    while(sRegExp.test(sValue)) {
        sValue = sValue.replace(sRegExp, '$1'+sep+'$2');
    }
    if(isNaN(sValue)) {
        return null
    } else {
        return sValue;
    } 
}

export default thousandsSeparator;