import React, { useContext, useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import AuthContext from '../../context';
import { ContentAligned } from '../../styles/containers/main/contentAligned';
import { MainCotainer } from '../../styles/containers/main/mainContainer';
import { Wrapper } from '../../styles/containers/main/wrapper';
import { MediumTextBold } from '../../styles/text/boldText/mediumTextBold';
import { MediumText } from '../../styles/text/texts/mediumText';
import { defaultTheme } from '../../styles/themes/default';
import DatePickerFilter from '../../components/datePickerFilter';
import getStatement from '../../services/getStatement';

import { Container, StatementWrapper,Options,Filter, DateView, ListResult,DebitView } from './styles';


interface Period {
    startDate: string;
    finalDate: string;
}

/**
 * get previous month
 */
// const current = new Date();
// current.setMonth(current.getMonth()-1);
// const previousMonth = current.toLocaleString('default', { month: 'long' });
// console.log(previousMonth)

const StatementList: React.FC<any> = () => {
    const [filterSelected, setFilterSelected] = useState('TODOS')
    const [subFilterSelected, setSubFilterSelected] = useState('ÚLTIMOS LANÇAMENTOS')
	const [formatedData, setFormatedData] = useState<any[]>([])
	const [showDatePickerFilter, setShowDatePickerFilter] = useState(false);
	const { balance, userId, fidelityToken } = useContext(AuthContext)
	let rawData:any;

	const handleDatePickerFilter = async (period: Period) => {
		rawData = await getStatement(userId,fidelityToken, false, period)
		if(rawData !== "Request Error") 
			return formatData()   
	}
	const formatValue = (element: any) => {
		const value = ['Saldo Final', 'Saldo Inicial']
		.includes(element.tipoMovimentacao) ? element.saldoFormatado : element.pontosFormatado;
		if(value) return value;
	}
	
	/**
	 * To do:
	 * instalar moment e tratar as datas
	 */
	const handleMountData = async(subFilter?:any) => {
		if(subFilter == 'ÚLTIMOS LANÇAMENTOS'){
			rawData =  await getStatement(userId,fidelityToken, true)
			if(rawData !== "Request Error") return formatData()   
			return formatData()
		}
		rawData =  await getStatement(userId, fidelityToken, false)
		if(rawData !== "Request Error") return formatData()   
		return
	}
	 useEffect(()=>{
		handleMountData(subFilterSelected)
	},[userId,balance,filterSelected, subFilterSelected])

	const formatData = async() =>{
	let assemblingData = Object.values(rawData.reduce((acc:any, cur:any,i:any) => {
	const notShowItems = ['Saldo Final', 'Saldo Inicial', 'Saldo'];
	const descricaoTipoMovimentacao = ['Compra', 'Pontos Extras', 'Estorno'];
	
	switch(filterSelected){
		case 'TODOS':
			if(!notShowItems.includes (cur.descricaoTipoMovimentacao)){
			if (!acc[cur.dataMovimentacao])
			acc[cur.dataMovimentacao] = {key:i,Date: cur.dataMovimentacao, Data: []};
			acc[cur.dataMovimentacao].Data.push(cur);}
			break;
		case 'ENTRADA':
			if(!notShowItems.includes (cur.descricaoTipoMovimentacao) && descricaoTipoMovimentacao.includes(cur.descricaoTipoMovimentacao)) {
				if (!acc[cur.dataMovimentacao])
				acc[cur.dataMovimentacao] = {key:i,Date: cur.dataMovimentacao, Data: []};
				acc[cur.dataMovimentacao].Data.push(cur);}
			break;
		case 'SAÍDA':
			if(![...notShowItems, ...descricaoTipoMovimentacao].includes(cur.descricaoTipoMovimentacao)) {
				if (!acc[cur.dataMovimentacao])
				acc[cur.dataMovimentacao] = {key:i,Date: cur.dataMovimentacao, Data: []};
				acc[cur.dataMovimentacao].Data.push(cur);}
			break
	}
	return acc;
	}, {}));
	setFormatedData(assemblingData)
	}
	
    const changeFilter = (filter:string) =>{
        setFilterSelected(filter)
    }
    const changeSubFilter = (filter:string) =>{
		(filter === 'DEMAIS PERÍODOS') ? setShowDatePickerFilter(true) : setShowDatePickerFilter(false);
        setSubFilterSelected(filter)
    }

  return (
	  <>
      <Container>



          <StatementWrapper>


                <Filter style={filterSelected==='TODOS'?{borderBottomWidth:1,borderBottomColor:defaultTheme.colors.statement.borderFilterColor}:null} onPress={()=>changeFilter('TODOS')}>
                    <MediumTextBold>TODOS</MediumTextBold>
                </Filter>
                <Filter style={filterSelected==='ENTRADA'?{borderBottomWidth:1,borderBottomColor:defaultTheme.colors.statement.borderFilterColor}:null} onPress={()=>changeFilter('ENTRADA')}>
                    <MediumTextBold>ENTRADA</MediumTextBold>
                </Filter>
                <Filter style={filterSelected==='SAÍDA'?{borderBottomWidth:1,borderBottomColor:defaultTheme.colors.statement.borderFilterColor}:null} onPress={()=>changeFilter('SAÍDA')}>
                    <MediumTextBold>SAÍDA</MediumTextBold>
                </Filter>
                {/* <Filter style={filterSelected==='Á EXPIRAR'?{borderBottomWidth:1,borderBottomColor:defaultTheme.colors.statement.borderFilterColor}:null} onPress={()=>changeFilter('Á EXPIRAR')}>
                    <MediumTextBold>Á EXPIRAR</MediumTextBold>
                </Filter> */}
            </StatementWrapper>
            <StatementWrapper>
                    <Filter style={subFilterSelected==='ÚLTIMOS LANÇAMENTOS'?{borderBottomWidth:1,borderBottomColor:defaultTheme.colors.statement.borderFilterColor}:null} onPress={()=>changeSubFilter('ÚLTIMOS LANÇAMENTOS')}>
                        <MediumTextBold>ÚLTIMOS LANÇAMENTOS</MediumTextBold>
                    </Filter>
                    <Filter style={subFilterSelected==='DEMAIS PERÍODOS'?{borderBottomWidth:1,borderBottomColor:defaultTheme.colors.statement.borderFilterColor}:null} onPress={()=>changeSubFilter('DEMAIS PERÍODOS')}>
                    <MediumTextBold>DEMAIS PERÍODOS</MediumTextBold>
                </Filter>

            </StatementWrapper>
			{ showDatePickerFilter ? 
			<DatePickerFilter
				handleDatePickerFilter={handleDatePickerFilter}
			/>
			: <></>
			}

            <ListResult horizontal={false} showsVerticalScrollIndicator={false} >
                    {formatedData.map((el, index, arr) =>(
						
						<View key={`${index} - ${el.index}`}>
                        	<DateView>
								<MediumTextBold style={{color: defaultTheme.colors.gray}}>
								{el.Date ? el.Date.split("-").reverse().join("/"): ""}
								</MediumTextBold>
							</DateView>
							{el.Data.map((element:any,index:any)=>{
								return (
								<View style={{width: "100%"}}key={index}>
									<DebitView  key={index}>	
										<MediumTextBold style={{color: defaultTheme.colors.gray}}>
											{element.descricao}
										</MediumTextBold>	
									</DebitView>

									<DebitView style={{marginBottom:4}} >	
										<MediumTextBold >
											{element.descricaoTipoMovimentacao}
										</MediumTextBold>	
										<MediumTextBold>
											{formatValue(element)}
										</MediumTextBold>
									</DebitView>
								</View>

							)})}
						</View>
                    ))}
                </ListResult>
			</Container>

	  </>

  )
}

export default StatementList;