import React, { useEffect, useRef, useState } from "react";
import { View, Text } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { AnimatedTransform } from "react-native-reanimated";
import { SafeAreaView } from "react-native-safe-area-context";
import { DefaultText } from "../../../styles/text/defaultText";
import { defaultTheme } from "../../../styles/themes/default";

import {
  Container,
  ItemContainer,
  Title,
  TextSpaces,
  DotsContainer,
  TouchableDots,
  DotsColor,
} from "./styles";

interface objData {
  id: any;
  title: string;
  desc: string[];
}

interface props {
  items: objData[];
  selectIndex: any;
  setSelectIndex: any;
}

const TextCarousel: React.FC<props> = ({
  items,
  selectIndex,
  setSelectIndex,
}) => {
  const refItem = useRef<FlatList>(null);
  const [dotsIndex, setDotsIndex] = useState(0);

  useEffect(() => {
    refItem.current?.scrollToIndex({ index: selectIndex, animated: true });
  }, [selectIndex]);

  const Dots = ({ isActive }: any) => {
    // setSelectIndex

    return (
      <>
        <DotsContainer>
          {items.length > 0
            ? items.map((e, i, arr) => (
                <TouchableDots
                  key={i}
                  onPress={() => {
                    setSelectIndex(i), setDotsIndex(i);
                  }}
                >
                  <DotsColor
                    onPress={() => {
                      setSelectIndex(i), setDotsIndex(i);
                    }}
                    style={
                      i == dotsIndex
                        ? {
                            backgroundColor:
                              defaultTheme.colors.textCarousel.dotsBackground,
                          }
                        : {
                            backgroundColor:
                              defaultTheme.colors.textCarousel
                                .dotsBackgroundDisabled,
                          }
                    }
                  />
                </TouchableDots>
              ))
            : null}
        </DotsContainer>
      </>
    );
  };

  const Item = ({ item, onPress, textColor }: any) => (
    <ItemContainer>
      <Title>{item.title}</Title>
      <View style={{ width: "100%" }}>
        {item.desc.map((e: string, i: any) => (
          <TextSpaces key={i}>
            <DefaultText>{e}</DefaultText>
          </TextSpaces>
        ))}
      </View>
    </ItemContainer>
  );

  const renderItem = ({ item }: any) => {
    return (
      <View>
        <Item item={item} onPress={() => setSelectIndex(item.id)} />
      </View>
    );
  };
  const viewConfigRef = React.useRef({ viewAreaCoveragePercentThreshold: 50 });

  const onViewRef = React.useRef((viewableItems: any) => {
    if (viewableItems.viewableItems.length > 0) {
      setDotsIndex(viewableItems.viewableItems[0].index);
    }
  });
  return (
    <Container>
      <SafeAreaView
        style={{
          backgroundColor: defaultTheme.colors.textCarousel.background,
        }}
      >
        <FlatList
          //   ref={refItem}
          onViewableItemsChanged={onViewRef.current}
          viewabilityConfig={viewConfigRef.current}
          onScrollToIndexFailed={(e) => console.log(e)}
          initialScrollIndex={selectIndex && 0}
          // style={{overflow:'scroll'}}
          keyExtractor={(item) => item.id}
          ref={refItem}
          //   extraData={selectIndex}
          //    data={items}
          data={items}
          renderItem={renderItem}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
        />
      </SafeAreaView>
      <Dots />
    </Container>
  );
};

export default TextCarousel;
