import React, { useEffect } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Signin from "../../../pages/Signin";
import PublicHome from "../../../pages/PublicHome";
import Signup from "../../../pages/Signup";
import fidelityGetToken from "../../../services/fidelityGetToken";
import AuthContext from "../../../context";
import Spinner from "react-native-loading-spinner-overlay";
import Header from "../../../components/header";

const Auth = createStackNavigator();

export default function StackRoutes() {
  const { setFidelityToken, fidelityToken } = React.useContext(AuthContext);

  useEffect(() => {
    getTokenReq();
  }, []);

  const getTokenReq = async () => {
    let fidelityTokenApi = await fidelityGetToken();
    setFidelityToken(fidelityTokenApi.token);
  };

  return fidelityToken ? (
    <Auth.Navigator
      screenOptions={{
        //headerShown: false,
        header: () => <Header />,

        headerStyle: {
          height: 0,
          backgroundColor: "#a71313",
        },
      }}
    >
      {/* <GlobalStyle theme={theme} > */}
      <Auth.Screen
        options={{ title: "" }}
        name="PublicHome"
        component={PublicHome}
      />

      <Auth.Screen options={{ title: "" }} name="Signin" component={Signin} />
      <Auth.Screen
        options={{ title: "", headerShown: false }}
        name="Signup"
        component={Signup}
      />
    </Auth.Navigator>
  ) : (
    <>
      <Spinner
        visible={true}
        textContent={"Carregando..."}
        textStyle={{ color: "#fff" }}
        overlayColor="rgba(0,0,0,0.8)"
      />
    </>
  );
}
