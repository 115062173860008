import React from 'react';
import { View, Text, TouchableOpacity, Platform} from 'react-native';
import {Feather} from "@expo/vector-icons"
import { defaultTheme } from '../../styles/themes/default';
import { Container } from './styles';
import { BigTextBold } from '../../styles/text/boldText/bigTextBold';
import Svg, { G, Circle, Rect, Path } from 'react-native-svg';
import { SmallTextBold } from '../../styles/text/boldText/smallTextBold';
import { flingGestureHandlerProps } from 'react-native-gesture-handler/lib/typescript/handlers/FlingGestureHandler';
import GlobalMessages from "../../config/Messages";
import thousandsSeparator from '../../pages/utils/thousandsSeparator';
const BackWithPoints: React.FC<any> = ({title, size= 'big',redirect, navigation, points = 0, text, disablePoints=false}) => {

  const handleBack:any = () => {
      if(redirect === 'back') {
        navigation.goBack();
      } else {
        navigation.navigate(redirect)
      }
  }

  return (
      <View style={{width: '98%'}}>
        <Container>
        
        <TouchableOpacity style={{display:'flex', flexDirection: 'row', alignItems: 'center'}} onPress={handleBack} >
          <Text style={{marginRight:14}}>
              <Feather  name='chevron-left' size={Platform.OS === "web"? 40: 28} color={defaultTheme.colors.text} />
          </Text>
          <View>
          <BigTextBold>{text}</BigTextBold>
          </View>
        </TouchableOpacity>

        <View style={{flexDirection: 'column', display:'flex', alignItems: 'flex-end'}}>
              <SmallTextBold>
                  {title}
              </SmallTextBold>
              {
                disablePoints ?null:(
                  <View style={{flexDirection: 'row', display:'flex'}}>      
                  <Svg 
                      width="18" 
                      height="18" 
                      viewBox="0 0 18 18" 
                      fill="none" 
                      style={{marginRight: 10}}
                  >
                      <Path d="M9 0C4.05 0 0 4.05 0 9C0 13.95 4.05 18 9 18C13.95 18 18 13.95 18 9C18 4.05 13.95 0 9 0ZM11.925 13.05L9 11.52L6.075 13.05L6.615 9.81L4.23 7.515L7.515 7.02L9 4.05L10.44 7.02L13.725 7.515L11.34 9.81L11.925 13.05Z" fill="#BA6344"/>  
                  </Svg>
              <BigTextBold style={{marginLeft:10}}>
                      {isNaN(points) ? 0 : thousandsSeparator(points, '.')} {GlobalMessages.pts}
                  </BigTextBold>
                </View>
                )
              }



        </View>
        

        


        </Container>
      </View>

    
  )
}

export default BackWithPoints;