import styled from "styled-components/native";

export const ListContainer = styled.View`
  flex: 1
  margin-horizontal: 20px;
`;

export const SectionHeaderContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between
  padding-vertical: 10px
`;

export const SingleRow = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const ListCard = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: space-between
  margin-horizontal: 20px;
  margin-bottom: 10px;
  background-color: ${props => props.theme.colors.secondBackground};
`;

export const BanefitImage = styled.Image`
width: 150px;
height: 150px;
`
