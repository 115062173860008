import * as React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { Feather } from "@expo/vector-icons";
import RestrictHome from "../../../pages/RestrictHome";
import { Platform } from "react-native";
import { defaultTheme } from "../../../styles/themes/default";
import Statement from "../../../pages/statement";
import Invoice from "../../../pages/Invoice";
import BarCodeScannerPage from "../../../pages/BarCodeScanner";
import Benefits from "../../../pages/Benefits";
import BenefitsNew from "../../../pages/BenefitsNew";
import BenefitsTier from "../../../pages/BenefitsTier";
import SpecificBenefit from "../../../pages/SpecificBenefit";
import Voucher from "../../../pages/Voucher";
import MyBenefits from "../../../pages/MyBenefits";
import AuthContext from "../../../context";
import getAlianseUser from "../../../services/getAlianseUser";
import getLoyaltyUser from "../../../services/getLoyaltyUser";
import Spinner from "react-native-loading-spinner-overlay/lib";
import fidelityGetToken from "../../../services/fidelityGetToken";
import Svg, { G, Path } from "react-native-svg";
import Header from "../../../components/header";
import ErrorScreen from "../../../pages/ErrorScreen";
const Tab = createBottomTabNavigator();

export default function TabRoutes({ navigation }: any) {
  const {
    setDrawer,
    cpf,
    setUserId,
    setBalance,
    setModality,
    setFidelityToken,
    setLoyaltyUser,
  }: any = React.useContext(AuthContext);

  const [isLoading, setIsLoading] = React.useState(false);
  React.useEffect(() => {
    if (cpf) {
      setDrawer(navigation);

      handlePopulateInformations();
    }
  }, [cpf]);

  const handlePopulateInformations = async () => {
    setIsLoading(true);

    try {
      let fidelityTokenApi = await fidelityGetToken();
      setFidelityToken(fidelityTokenApi.token);
      let user: any = await getAlianseUser(cpf, fidelityTokenApi.token);
      setUserId(user.data.pessoaFisica.id);

      const loyaltyUser = await getLoyaltyUser(
        user.data.pessoaFisica.id,
        fidelityTokenApi.token
      );
      setLoyaltyUser(loyaltyUser);
      setBalance(loyaltyUser.saldo);
      setModality(loyaltyUser.modalidade);

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const homeIcon = () => {
    return (
      <Svg width="27" height="22" viewBox="0 0 27 22" fill="none">
        <G clip-path="url(#clip0_497_77)">
          <Path
            d="M0.519226 9.27155L13.5 0.523926L26.4808 9.27155"
            stroke="white"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M23.8846 7.85693V19.9046C23.8846 20.795 23.2096 21.476 22.3269 21.476H16.0962V16.2379H10.9038V21.476H4.67308C3.79039 21.476 3.11539 20.795 3.11539 19.9046V7.85693"
            stroke="white"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </G>
      </Svg>
    );
  };
  const invoiceIcon = () => {
    return (
      <Svg width="22" height="22" viewBox="0 0 22 22" fill="none">
        <G clip-path="url(#clip0_497_71)">
          <Path
            d="M19.9048 21.4763H2.09524C1.20476 21.4763 0.523811 20.7954 0.523811 19.9049V2.09535C0.523811 1.20488 1.20476 0.523926 2.09524 0.523926H19.9048C20.7952 0.523926 21.4762 1.20488 21.4762 2.09535V19.9049C21.4762 20.7954 20.7952 21.4763 19.9048 21.4763Z"
            stroke="white"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M5.76191 5.76172H16.2381"
            stroke="white"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <Path
            d="M5.76191 11H16.2381"
            stroke="white"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <Path
            d="M5.76191 16.2383H11"
            stroke="white"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
        </G>
      </Svg>
    );
  };
  const benefitsIcon = () => {
    return (
      <Svg width="20" height="24" viewBox="0 0 20 24" fill="none">
        <G clip-path="url(#clip0_497_67)">
          <Path
            d="M19.3052 5.07676H12.1092L14.5409 3.79471C15.1365 3.48702 15.5831 2.87163 15.6824 2.20497C15.7816 1.48702 15.5831 0.82035 15.0868 0.30753C14.5906 -0.205291 13.9454 -0.410419 13.2506 -0.307855C12.5558 -0.205291 12.0099 0.256248 11.7122 0.871632L9.97519 4.41009L8.23821 0.871632C7.94045 0.256248 7.34491 -0.205291 6.69975 -0.307855C6.05459 -0.410419 5.3598 -0.205291 4.86352 0.30753C4.36725 0.82035 4.16873 1.48702 4.26799 2.20497C4.41687 2.92291 4.86352 3.48702 5.45906 3.79471L7.89082 5.07676H0.694789C0.397022 5.07676 0.198511 5.28189 0.198511 5.58958V23.7947C0.198511 24.1024 0.397022 24.3075 0.694789 24.3075H19.3052C19.603 24.3075 19.8015 24.1024 19.8015 23.7947V5.58958C19.8015 5.28189 19.603 5.07676 19.3052 5.07676ZM18.8089 14.1537H10.5211V6.1024H18.8586V14.1537H18.8089ZM12.6055 1.33317C12.7543 0.974196 13.0521 0.769068 13.3995 0.717786C13.4491 0.717786 13.4988 0.717786 13.598 0.717786C13.8958 0.717786 14.1935 0.82035 14.3921 1.02548C14.6402 1.28189 14.7395 1.64086 14.6898 1.99984C14.6402 2.35881 14.3921 2.6665 14.0943 2.82035L11.1166 4.35881L12.6055 1.33317ZM5.31017 2.05112C5.26055 1.69215 5.3598 1.33317 5.60794 1.07676C5.80645 0.871632 6.10422 0.717786 6.40198 0.717786C6.45161 0.717786 6.50124 0.717786 6.6005 0.717786C6.94789 0.769068 7.24566 1.02548 7.39454 1.33317L8.88337 4.41009L5.90571 2.87163C5.55831 2.71779 5.3598 2.41009 5.31017 2.05112ZM9.52854 6.1024V14.1537H1.19107V6.1024H9.52854ZM1.19107 15.1793H9.52854V23.2306H1.19107V15.1793ZM10.5211 23.2819V15.1793H18.8586V23.2306H10.5211V23.2819Z"
            fill="white"
          />
        </G>
      </Svg>
    );
  };

  const BenefitStack = createNativeStackNavigator();

  const BenefitStackNavigator = () => (
    <BenefitStack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName={"BenefitsNew"}
    >
      <BenefitStack.Screen name="BenefitsNew" component={BenefitsNew} />
      <BenefitStack.Screen name="BenefitsTier" component={BenefitsTier} />
      <BenefitStack.Screen name="SpecificBenefit" component={SpecificBenefit} />
      <BenefitStack.Screen name="Voucher" component={Voucher} />
      <BenefitStack.Screen name="ErrorScreen" component={ErrorScreen} />
    </BenefitStack.Navigator>
  );

  const MyBenefitStackNavigator = () => (
    <BenefitStack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName={"MyBenefits"}
    >
      <BenefitStack.Screen name="MyBenefits" component={MyBenefits} />
      <BenefitStack.Screen name="Voucher" component={Voucher} />
      <BenefitStack.Screen name="ErrorScreen" component={ErrorScreen} />
    </BenefitStack.Navigator>
  );

  return (
    <>
      {!isLoading ? (
        <Tab.Navigator
          screenOptions={{
            unmountOnBlur: true,
            tabBarActiveTintColor:
              defaultTheme.colors.tabBar.iconActiveTintColor,
            tabBarActiveBackgroundColor:
              defaultTheme.colors.tabBar.activeBackgroundColor,
            tabBarInactiveBackgroundColor:
              defaultTheme.colors.tabBar.inactiveBackgroundColor,
            tabBarStyle: {
              height: "15%",
            },
            header: () => <Header />,
            tabBarLabelStyle: {
              fontSize: 12,
              marginBottom: 15,
              marginTop: 0,
              fontWeight: "bold",
            },
          }}
        >
          <Tab.Screen
            options={{
              tabBarIcon: () => homeIcon(),
            }}
            name="Inicio"
            component={RestrictHome}
          />
          <Tab.Screen
            options={{
              tabBarIcon: () => invoiceIcon(),
            }}
            name="Nota Fiscal"
            component={Invoice}
          />
          <Tab.Screen
            options={{
              tabBarButton: () => null,
            }}
            name="BarCodeScanner"
            component={BarCodeScannerPage}
          />
          <Tab.Screen
            options={{
              tabBarIcon: ({ color }) => (
                <Feather
                  name="credit-card"
                  size={Platform.OS === "web" ? 20 : 20}
                  color={color}
                />
              ),
            }}
            name="Extrato"
            component={Statement}
          />
          <Tab.Screen
            options={{
              tabBarIcon: () => benefitsIcon(),
            }}
            name="Benefícios"
            component={BenefitStackNavigator}
          />
          <Tab.Screen
            options={{
              tabBarButton: () => null,
            }}
            name="MyBenefits"
            component={MyBenefitStackNavigator}
          />
        </Tab.Navigator>
      ) : (
        <>
          <Spinner
            visible={true}
            textContent={"Carregando...."}
            textStyle={{ color: "#fff" }}
            overlayColor="rgba(0,0,0,0.8)"
          />
        </>
      )}
    </>
  );
}
