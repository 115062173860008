import AuthRequest from "../config/AuthRequest";
import axios from "axios";

const getFidelityCategory = async (token: any) => {
  try {
    const getFidelityCategoryReq = await axios.get(
      `${AuthRequest.fidelityApi}/benefits/getBenefitCategory`,
      {
        headers: {
          "Content-Type": "application/json",
          application: AuthRequest.Application,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return getFidelityCategoryReq.data;
  } catch (error) {
    return error;
  }
};

export default getFidelityCategory;
