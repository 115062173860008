import * as React from "react";
import {
  Easing,
  TextInput,
  Animated,
  Text,
  View,
  StyleSheet,
  Platform,
} from "react-native";
import Svg, { G, Circle, Rect, Path } from "react-native-svg";
import { defaultTheme } from "../../../styles/themes/default";
import AuthContext from "../../../context";
import { BigTextBold } from "../../../styles/text/boldText/bigTextBold";
import thousandsSeparator from "../../../pages/utils/thousandsSeparator";
const AnimatedCircle = Animated.createAnimatedComponent(Circle);
const AnimatedTextInput = Animated.createAnimatedComponent(TextInput);

/**
 * To do:
 * Fazer um multiplicador para almentar a porcentagem percorrida de acordo com tier do usuario, levando em consideração a pontuação maxima ex:
 * se ele esta bronze, até chegar em 50 mil não tem multiplicador, dos 50 em diante colocar multiplcador
 * para ter certeza que o grafico vai ander de acordo com os circulos pintados que tem dentro de bronze, prata e ouro
 */
export default function Donut({
  percentage = 0,
  radius = 100,
  strokeWidth = 10,
  duration = 500,
  color = "#333333",
  delay = 1500,
  tierDescription = false,
  tierRadius = 78,
  tierStrokeWidth = 24,
  colorTierBronze = defaultTheme.colors.tier.bronze,
  colorTierSilver = defaultTheme.colors.tier.silver,
  colorTierGold = defaultTheme.colors.tier.gold,
}: any) {
  const { loyaltyUser } = React.useContext(AuthContext);
  const { saldo, pontosProximaModalidade } = loyaltyUser
  const balance = saldo;

  const maxPercent = pontosProximaModalidade + saldo || 1

  const animated = React.useRef(new Animated.Value(0)).current;
  const circleRef = React.useRef<any>();
  const inputRef = React.useRef<any>();
  const circumference = 2 * Math.PI * radius;
  const halfCircle = radius + strokeWidth;

  const animation = (toValue: any) => {
    return Animated.timing(animated, {
      delay,
      toValue,
      duration,
      useNativeDriver: true,
      easing: Easing.out(Easing.ease),
    }).start(() => {
      // animation(toValue === 0 ? percentage : 0);
    });
  };

  React.useEffect(() => {
    percentage = balance ? balance : 0;
    // animation(percentage.toLocaleString('pt-BR'));
    animation(percentage);
    animated.addListener((v: any) => {
      const maxPerc = (100 * v.value) / maxPercent;
      const strokeDashoffset = circumference - (circumference * maxPerc) / 100;
      if (inputRef?.current) {
        inputRef.current.setNativeProps({
          text: `${Math.round(v.value)}`,
        });
      }
      if (circleRef?.current) {
        circleRef.current.setNativeProps({
          strokeDashoffset,
        });
      }
    });

    return () => {
      animated.removeAllListeners();
    };
  }, [maxPercent, percentage, balance]);

  return (
    <View style={{ width: radius * 2, height: radius * 2 }}>
      <Svg
        height={radius * 2}
        width={radius * 2}
        viewBox={`0 0 ${halfCircle * 2} ${halfCircle * 2}`}
      >
        {tierDescription ? (
          Platform.OS === "android" ? (
            <View>
              <G>
                <Circle
                  cx="50%"
                  cy="50%"
                  stroke={colorTierGold}
                  strokeWidth={tierStrokeWidth}
                  r={tierRadius}
                  strokeOpacity={1}
                  fill="transparent"
                />
                <Circle
                  rotation="-90"
                  origin={`${halfCircle}, ${halfCircle}`}
                  cx="50%"
                  cy="50%"
                  stroke={colorTierBronze}
                  strokeWidth={tierStrokeWidth}
                  r={tierRadius}
                  strokeOpacity={1}
                  fill="transparent"
                  strokeDasharray={2 * Math.PI * 80}
                  strokeDashoffset={(2 * Math.PI * 80) / 1.5}
                />

                <Circle
                  rotation="-330"
                  origin={`${halfCircle}, ${halfCircle}`}
                  cx="50%"
                  cy="50%"
                  stroke={colorTierSilver}
                  strokeWidth={tierStrokeWidth}
                  r={tierRadius}
                  strokeOpacity={1}
                  fill="transparent"
                  strokeDasharray={2 * Math.PI * 80}
                  strokeDashoffset={(2 * Math.PI * 80) / 1.5}
                />
              </G>

              {/* 
            <G rotation="-90"
          origin={`${halfCircle}, ${halfCircle}`}>
            <Circle  cx="50%" cy='50%' stroke='black' strokeWidth={ tierStrokeWidth}  r={70} 
          strokeOpacity={1} fill="transparent" strokeDasharray={2 * Math.PI * 80}
          strokeDashoffset={2 * Math.PI * 80 /1.5} />
            </G> */}
            </View>
          ) : (
            <View style={{ marginTop: 20, marginLeft: 20 }}>
              <Svg width="160" height="160" viewBox="0 0 151 152" fill="none">
                <Path
                  d="M150.997 75.975C150.997 89.629 147.346 102.433 140.944 113.486L123.639 103.483C128.34 95.3806 130.991 85.9779 130.991 75.975C130.991 45.5663 106.334 20.9592 75.9751 20.9592V0.953491C117.437 0.953491 150.997 34.5631 150.997 75.975Z"
                  fill="#BA6344"
                />
                <Path
                  d="M123.639 103.533L140.944 113.536C127.99 135.942 103.733 151.047 75.975 151.047C48.217 151.047 24.0101 135.942 11.0063 113.536L28.3113 103.533C37.8141 119.988 55.6192 131.041 75.975 131.041C96.3309 131.041 114.136 119.938 123.639 103.533Z"
                  fill="#A1A1A1"
                />
                <Path
                  d="M75.9752 0.953491V20.9592C45.5664 20.9592 20.9594 45.6163 20.9594 75.975C20.9594 85.9779 23.6601 95.3806 28.3115 103.483L11.0065 113.536C4.60466 102.483 0.953613 89.679 0.953613 76.0251C0.953613 34.5632 34.5633 0.953491 75.9752 0.953491Z"
                  fill="#B4842B"
                />
              </Svg>
            </View>
          )
        ) : null}

        <G rotation="-90" origin={`${halfCircle}, ${halfCircle}`}>
          <Circle
            ref={circleRef}
            cx="50%"
            cy="50%"
            r={radius}
            fill="transparent"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeDashoffset={circumference}
            strokeDasharray={circumference}
          />
          <Circle
            cx="50%"
            cy="50%"
            r={radius}
            fill="transparent"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinejoin="round"
            strokeOpacity=".1"
          />
        </G>
      </Svg>

      <View
        style={{
          ...StyleSheet.absoluteFillObject,
          justifyContent: "center",
        }}
      >
        <AnimatedTextInput
          ref={inputRef}
          underlineColorAndroid="transparent"
          editable={false}
          defaultValue="0"
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: 24,
            fontWeight: "bold",
            color: defaultTheme.colors.text,
          }}
          // style={[
          //   StyleSheet.absoluteFillObject,
          //   { fontSize: radius / 3, color: textColor ?? color },
          //   styles.text,
          // ]}
        />
        <BigTextBold
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: 20,
          }}
        >
          PTS
        </BigTextBold>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  text: { fontWeight: "900", textAlign: "center" },
});

// import React, { useEffect, useRef} from 'react';
// import { View, Text, Platform, Animated } from 'react-native';
// import Svg, {G,Circle} from 'react-native-svg'
// // import { Container } from './styles';

// const AnimatedCircle = Animated.createAnimatedComponent(Circle)

// const Donut: React.FC<any> = ({
//   percentage = 75,
//   radius = 40,
//   strokeWidth = 10,
//   duration = 500,
//   color = 'tomato',
//   delay = 0,
//   textColor,
//   maxPercent = 100
// }) => {
//   const  animatedValue = useRef(new Animated.Value(0)).current
//   const circleRef = useRef()
//   const halfCircle = radius + strokeWidth
//   const circleCircunference = 2 * Math.PI * radius;

//   const animation = (toValue:any) => {
//     return Animated.timing(animatedValue,{
//       toValue,
//       duration,
//       delay,
//       useNativeDriver:true
//     }).start(()=>{
//       animation(toValue === 0 ? percentage:0);
//     })
//   }

//   useEffect(()=>{
//     animation(percentage)

//     animatedValue.addListener(v => {
//       if(circleRef?.current){
//         const maxPerc = (100 * v.value) / maxPercent;
//         const strokeDashOffset = circleCircunference - (circleCircunference * maxPerc) / 100
//       //@ts-ignore
//         circleRef.current.setNativeProps({
//           strokeDashOffset
//         })
//       }
//     })
//   },[])
//   return(
//     <View>
//       <Svg width={radius * 2} height={ radius * 2} viewBox={`0 0 ${halfCircle * 2} ${halfCircle * 2}`}>

//         {Platform.OS ==='web' ? (
//           <G >
//           <Circle cx="50%" cy='50%' stroke={color} strokeWidth={ strokeWidth}  r={radius} strokeOpacity={0.2} fill="transparent" />
//           <Circle cx="50%" cy='50%' stroke={color} strokeWidth={ strokeWidth}  r={radius}
//           strokeOpacity={0.2} fill="transparent" strokeDasharray={circleCircunference}
//           strokeDashoffset={circleCircunference/2}/>

//           </G>
//         ) :(
//           <G rotation='-90' origin={`${halfCircle}, ${halfCircle}`}>
//           <Circle cx="50%" cy='50%' stroke={color} strokeWidth={ strokeWidth}  r={radius} strokeOpacity={0.2} fill="transparent" />
//           <AnimatedCircle cx="50%" cy='50%' stroke={color} strokeWidth={ strokeWidth}  r={radius}
//           strokeOpacity={0.2} fill="transparent" strokeDasharray={circleCircunference}
//           strokeDashoffset={circleCircunference} strokeLinecap='round' ref={circleRef}/>
//           </G>
//         )}

//       </Svg>
//     </View>
//   )
// }

// export default Donut;
