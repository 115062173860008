import styled  from 'styled-components/native';

export const Container = styled.View`
display: flex;
flex-direction:row;
align-items: center;
justify-content: space-between;
width: 100%;
margin-top: 20px;
margin-bottom: 20px;

`

export const Items = styled.View`
/* background-color: pink; */
min-width: 98px;
min-height: 98px;
margin-bottom: 20px;
display: flex;
align-items: center;
margin-right: 20px;
`
export const Circle = styled.TouchableOpacity`
width: 80px;
height: 80px;
border-radius: 50px;
background-color: ${props => props.theme.colors.secondBackground};
justify-content: center;
align-items: center;
margin-bottom: 10px;
`

