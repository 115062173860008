import styled from "styled-components/native";

export const ListContainer = styled.View`
  flex: 1
  margin-horizontal: 20px;
`;

export const SectionHeaderContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between
  padding-vertical: 10px
`;

export const SingleRow = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const BanefitImage = styled.Image`
width: 170px;
height: 140px;
`
