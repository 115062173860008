import { Platform } from 'react-native';
import styled  from 'styled-components/native';

export const Container = styled.View`
width: 100%;
display: flex;
flex-direction: column;
`
export const InvoiceWrapper = styled.View`
display: flex;
width: 100%;
flex-direction: row;
justify-content: space-between;
border-bottom-color: ${props=> props.theme.colors.gray2};
border-bottom-width: 1px;
margin-top: 10px;
min-height: 50px;
align-items: center;
height: 50px;

`

export const Filter = styled.TouchableOpacity`
height: 50px;
display: flex;
justify-content: center;
align-items: center;
min-width: ${Platform.OS ==='web'?200:80}px;
`
export const ListResult = styled.ScrollView`
${Platform.OS==="web"?" max-height: 20%":" max-height: 350px"};
margin-top: 10px;
 /* background-color: aliceblue; */
`
export const ItemView = styled.View`
width: 100%;/*${props => ((props.theme.ScreenWidth*85)/100)}px;*/
display: flex;
justify-content: space-between;
flex-direction: row;
background-color: ${props=>props.theme.colors.secondBackground};
padding: 4px;
border-bottom-width:1px;
border-bottom-color:${props=>props.theme.colors.background} ;
`

export const ViewDatePicker = styled.View`
display: flex;
width: 100%;
margin-top: 14px;
`
export const DateFilterButton = styled.TouchableOpacity`
display: flex;
align-items: center;
justify-content: center;
background-color: #000;
min-width: 100px;
height: 40px;
margin-top: 20px;
`
