import React, { useContext, useEffect, useState } from "react";
import { View, Text, Platform, TouchableOpacity } from "react-native";
import { MediumTextBold } from "../../styles/text/boldText/mediumTextBold";
import { SmallTextBold } from "../../styles/text/boldText/smallTextBold";
import { defaultTheme } from "../../styles/themes/default";
import { Feather } from "@expo/vector-icons";
import {
  Container,
  InvoiceWrapper,
  Filter,
  ListResult,
  ItemView,
  ViewDatePicker,
  DateFilterButton,
} from "./styles";
import { MediumText } from "../../styles/text/texts/mediumText";
import getInvoice from "../../services/getInvoice";
import AuthContext from "../../context";
import moment from "moment";
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import Svg, { Path } from "react-native-svg";

const InvoiceList: React.FC = () => {
  const [formatedData, setFormatedData] = useState<any[]>([]);
  const [filter, setFilter] = useState("TODAS");
  const [invoiceOpenedIndex, setInvoiceOpenedIndex] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date());
  const [numValidation, setNumValidation] = useState("");
  const [keyValidation, seKeyValidation] = useState("");

  const [showStart, setShowStart] = useState(false);
  const [showFinal, setShowFinal] = useState(false);


  const { balance, userId, fidelityToken } =
    useContext(AuthContext);
  let rawData: any;

  useEffect(() => {
    handleMountData();
  }, [balance, filter]);

  const handleMountData = async (searchByDate?: any) => {
    if (searchByDate) {
      rawData = await getInvoice({
        id: userId,
        token: fidelityToken,
        startDate: moment(startDate).format("DD/MM/YYYY"),
        finalDate: moment(finalDate).format("DD/MM/YYYY"),
      });

      if (rawData !== "Request Error") return formatData();
      return;
    }

    rawData = await getInvoice({
      id: userId,
      token: fidelityToken,
    });
    if (rawData !== "Request Error") return formatData();
    return;
  };

  const formatData = async () => {
    // getInvoice
    let assemblingData = Object.values(
      rawData.reduce((acc: any, cur: any, i: any) => {
        if (!acc[cur.dataEnvio.split("T")[0]])
          acc[cur.dataEnvio.split("T")[0]] = {
            key: i,
            Date: cur.dataEnvio.split("T")[0],
            Data: [],
          };
        switch (filter) {
          case "TODAS":
            acc[cur.dataEnvio.split("T")[0]].Data.push(cur);
            break;
          case "APROVADAS":
            if (cur.status === "APROVADO")
              acc[cur.dataEnvio.split("T")[0]].Data.push(cur);
            break;
          case "PENDENTES":
            if (cur.status === "EM_PROCESSAMENTO")
              acc[cur.dataEnvio.split("T")[0]].Data.push(cur);
            break;
          case "REPROVADAS":
            if (cur.status === "REJEITADO")
              acc[cur.dataEnvio.split("T")[0]].Data.push(cur);
            break;
          // case 'Data':
          //     if(cur.dataEnvio.split('T')[0] === '2022-05-20') acc[cur.dataEnvio.split('T')[0]].Data.push(cur);
          //   break;
        }
        return acc;
      }, {})
    );
    setFormatedData(assemblingData);
  };

  const changeFilter = async (filterProps: any) => {
    setFilter(filterProps);
  };

  const handleOpenInvoice = (ind: any, num: any, key: any) => {
    if (invoiceOpenedIndex !== ind && numValidation !== num) {
      return (
        setInvoiceOpenedIndex(ind), setNumValidation(num), seKeyValidation(key)
      );
    }
    return setInvoiceOpenedIndex(""), setNumValidation(""), seKeyValidation("");
  };

  const approved = () => {
    return (
      <Svg width={15} height={15} viewBox="0 0 15 15" fill="none">
        <Path
          d="M7.5 0C3.36 0 0 3.36 0 7.5 0 11.64 3.36 15 7.5 15c4.14 0 7.5-3.36 7.5-7.5C15 3.36 11.64 0 7.5 0zM6 11.25L2.25 7.5l1.058-1.058L6 9.127l5.693-5.692L12.75 4.5 6 11.25z"
          fill="#578167"
        />
      </Svg>
    );
  };

  const pending = () => {
    return (
      <Svg width={15} height={15} viewBox="0 0 15 15" fill="none">
        <Path
          d="M7.5 0C3.36 0 0 3.36 0 7.5 0 11.64 3.36 15 7.5 15c4.14 0 7.5-3.36 7.5-7.5C15 3.36 11.64 0 7.5 0zM3.75 8.625A1.123 1.123 0 012.625 7.5c0-.622.502-1.125 1.125-1.125.622 0 1.125.503 1.125 1.125 0 .623-.503 1.125-1.125 1.125zm3.75 0A1.123 1.123 0 016.375 7.5c0-.622.503-1.125 1.125-1.125.623 0 1.125.503 1.125 1.125 0 .623-.502 1.125-1.125 1.125zm3.75 0A1.123 1.123 0 0110.125 7.5c0-.622.502-1.125 1.125-1.125s1.125.503 1.125 1.125c0 .623-.502 1.125-1.125 1.125z"
          fill="#898989"
        />
      </Svg>
    );
  };

  const rejected = () => {
    return (
      <Svg width={15} height={15} viewBox="0 0 15 15" fill="none">
        <Path
          d="M7.5 0A7.493 7.493 0 000 7.5C0 11.648 3.353 15 7.5 15c4.148 0 7.5-3.352 7.5-7.5C15 3.353 11.648 0 7.5 0zm3.75 10.193l-1.057 1.057L7.5 8.557 4.808 11.25 3.75 10.193 6.442 7.5 3.75 4.808 4.808 3.75 7.5 6.442l2.693-2.692 1.057 1.058L8.557 7.5l2.693 2.693z"
          fill="#99212F"
        />
      </Svg>
    );
  };

  return (
    <Container>
      <ViewDatePicker>
        <MediumTextBold>FILTRAR POR PERIODO</MediumTextBold>
        <View
          style={{
            marginTop: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View>
            <MediumTextBold>De</MediumTextBold>
              <TouchableOpacity onPress={()=>setShowStart(true)}>
                <Text style={{
                  borderColor: '#000',
                  borderWidth: 1,
                  padding: 5
                }}>{moment(startDate).format("DD/MM/YYYY")}</Text>
              </TouchableOpacity>
                <DateTimePickerModal
                  isVisible={showStart}
                  value={startDate}
                  mode={'date'}
                  display={Platform.OS === 'ios' ? 'spinner' : 'default'}
                  maximumDate={new Date()}
                  onConfirm={(selectedDate) => { 
                    const currentDate = selectedDate;
                    setShowStart(false);
                    setStartDate(currentDate);
                  }}
                  onCancel={()=>setShowStart(false)}
                />
          </View> 

          <View>
            <MediumTextBold>Até</MediumTextBold>
            <TouchableOpacity onPress={()=>setShowFinal(true)}>
                <Text style={{
                  borderColor: '#000',
                  borderWidth: 1,
                  padding: 5
                }}>{moment(finalDate).format("DD/MM/YYYY")}</Text>
              </TouchableOpacity>
              <DateTimePickerModal
                  isVisible={showFinal}
                  value={finalDate}
                  mode={'date'}
                  display={Platform.OS === 'ios' ? 'spinner' : 'default'}
                  maximumDate={new Date()}
                  onConfirm={(selectedDate) => { 
                    const currentDate = selectedDate;
                    setShowFinal(false);
                    setFinalDate(currentDate);
                  }}
                  onCancel={()=>setShowFinal(false)}
                />
          </View>

          <DateFilterButton style={{top: -9}} onPress={() => handleMountData(true)}>
            <MediumText style={{ color: "white" }}>Buscar</MediumText>
          </DateFilterButton>
        </View>
      </ViewDatePicker>

      <InvoiceWrapper>
        <Filter
          style={
            filter === "TODAS"
              ? {
                  borderBottomWidth: 1,
                  borderBottomColor:
                    defaultTheme.colors.statement.borderFilterColor,
                }
              : null
          }
          onPress={() => changeFilter("TODAS")}
        >
          <SmallTextBold>TODOS</SmallTextBold>
        </Filter>
        <Filter
          style={
            filter === "APROVADAS"
              ? {
                  borderBottomWidth: 1,
                  borderBottomColor:
                    defaultTheme.colors.statement.borderFilterColor,
                }
              : null
          }
          onPress={() => changeFilter("APROVADAS")}
        >
          <SmallTextBold>APROVADAS</SmallTextBold>
        </Filter>
        <Filter
          style={
            filter === "PENDENTES"
              ? {
                  borderBottomWidth: 1,
                  borderBottomColor:
                    defaultTheme.colors.statement.borderFilterColor,
                }
              : null
          }
          onPress={() => changeFilter("PENDENTES")}
        >
          <SmallTextBold>PENDENTES</SmallTextBold>
        </Filter>
        <Filter
          style={
            filter === "REPROVADAS"
              ? {
                  borderBottomWidth: 1,
                  borderBottomColor:
                    defaultTheme.colors.statement.borderFilterColor,
                }
              : null
          }
          onPress={() => changeFilter("REPROVADAS")}
        >
          <SmallTextBold>REPROVADAS</SmallTextBold>
        </Filter>
      </InvoiceWrapper>
      <ListResult showsVerticalScrollIndicator={false}>
        {formatedData.map((el, i) =>
          el.Data.map((element: any, index: any, arr: any) => (
            <View key={el.key + index}>
              <ItemView>
                <View
                  style={{
                    display: "flex",
                    left: 2,
                    flexDirection: "row",
                    width: "65%",
                  }}
                >
                  {element.status == "APROVADO" ? (
                    approved()
                  ) : element.status == "EM_PROCESSAMENTO" ? (
                    pending()
                  ) : (
                    rejected()
                  )}
                  <MediumTextBold style={{ marginLeft: 5 }} key={el.key}>
                    { element.status == "APROVADO"
                      ? "APROVADO"
                      : element.status == "EM_PROCESSAMENTO" ?
                      "EM PROCESSAMENTO" : "REPROVADO"}
                  </MediumTextBold>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "35%",
                    justifyContent: "space-between",
                  }}
                >
                  <MediumTextBold key={el.key}>
                    {element.valorNotaFormatado}
                  </MediumTextBold>
                  <TouchableOpacity
                    onPress={() =>
                      handleOpenInvoice(index, element.numero, el.key)
                    }
                  >
                    <Feather
                      name="chevron-down"
                      size={Platform.OS === "web" ? 40 : 30}
                      color={defaultTheme.colors.text}
                    />
                  </TouchableOpacity>
                </View>
              </ItemView>

              {invoiceOpenedIndex == index &&
              numValidation == element.numero &&
              keyValidation == el.key ? (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "99%",
                    backgroundColor: defaultTheme.colors.secondBackground,
                  }}
                >
                  {console.log(el.key)}
                  <View style={{ padding: 8 }}>
                    <MediumTextBold>Data:</MediumTextBold>
                    <MediumText style={{ color: defaultTheme.colors.gray }}>
                      {el.Date ? el.Date.split("-").reverse().join("/") : ""}
                    </MediumText>
                  </View>

                  <View style={{ padding: 8 }}>
                    <MediumTextBold>Número da Nota:</MediumTextBold>
                    <MediumText style={{ color: defaultTheme.colors.gray }}>
                      {element.numero}
                    </MediumText>
                  </View>

                  <View style={{ padding: 8 }}>
                    <MediumTextBold>Status:</MediumTextBold>
                    <MediumText style={{ color: defaultTheme.colors.gray }}>
                      {element.status === "REJEITADO"
                        ? "REPROVADO"
                        : element.status}{" "}
                      {element.status === "REJEITADO"
                        ? ` - ${element.detalheStatus}`
                        : null}
                    </MediumText>
                  </View>
                </View>
              ) : null}
            </View>
          ))
        )}
      </ListResult>
    </Container>
  );
};

export default InvoiceList;
