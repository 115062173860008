import { Dimensions, Platform } from 'react-native';
import {ThemeProvider, DefaultTheme} from 'styled-components/native'
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
export  const defaultTheme: DefaultTheme = {
    title: 'default',
    ScreenHeight: windowHeight,
    ScreenWidth: windowWidth,
    fontFamily: Platform.OS === 'ios' ? 'Arial' : 'sans-serif',
    score:{
        bronze:'0',
        silver:'50.000',
        gold: '100.000'
    },
    colors: {
        invoice:{
            aproveIcon: '#578167',
            rejectedIcon:'#99212f',
            processIcon: '#ed9e12'
        },
        statement:{
            borderFilterColor:'#ba6345',
        },
        balance:{
            starIcon:'#b96244'
        },
        tabBar:{
            activeBackgroundColor: '#000',
            inactiveBackgroundColor: '#000',
            iconActiveTintColor: '#d9964f'

        },
        alert: '#ba6345',
        statusBar: '#f9f9f9',
        primary: '#C62E65',
        secundary: '#D63Af9',
        tertiary: '#d3d3d3',
        // background: '#f5f5f5',
        // background: '#C62E65',
        background: '#f9f9f9',
        secondBackground: '#efebe2',
        bronze: '#ba6344',
        silver:'#a1a1a1',
        gold: '#b4842b',
        profits:{
            borderColor: '#d3d3d3',
            backgroundColor:'#f9f9f9',
            titleColor: '#939393',
            nameColor:  '#050404',
        },
        tier:{
            bronze: '#ba6344',
            silver:'#a1a1a1',
            gold: '#b4842b',
            
            backgroundColor: '#f9f9f9',
            bronzeBorder: '#ba6344',
            silverBorder:'#a1a1a1',
            goldBorder: '#b4842b',
            title: '#050404',
            desc:'#939393'
        },
        imageCarousel:{
            background: '#efebe2',
            dotsBackground:'#050404',
            dotsBorderColor:'#050404',
            dotsBackgroundDisabled: '#f9f9f9',
        },
        textCarousel:{
            background: 'transparent',
            dotsBackground:'#d9964f',
            dotsBorderColor:'#050404',
            dotsBackgroundDisabled: '#ddd7c8', 
        },
        gray: '#939393',
        gray2: '#d3d3d3',
        header: '#f9f9f9',
        text: '#333',
        defaultButton:{
            background: '#f9f9f9',
            textColor: '#333',
            borderColor: '#333'
        },
        redemptionButton:{
            background: '#000',
            textColor: '#fff',
            borderColor: '#000'
        },
        redemptionInactiveButton:{
            background: 'transparent',
            textColor: '#939393',
            borderColor: '#939393'
        },
        confirmButton:{
            background: '#000',
            textColor: '#fff',
            borderColor: '#000'
        },
        cancelButton:{
            background: 'transparent',
            textColor: '#939393',
            borderColor: '#939393'
        },

    }
}