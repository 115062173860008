






import styled  from 'styled-components/native';

// background-color: ${props => props.theme["_W"].colors.primary};
export const ContentAligned = styled.View`
display: flex;
/* align-items: center; */
justify-content: center;
/* align-items: center; */
background-color: transparent;
margin-top:7%;
width:  ${props=> ((props.theme.ScreenWidth * 92)/100)}px;

`