import React, { useState, useContext, useRef, useEffect } from "react";
import { View, Text, Linking, KeyboardAvoidingView } from "react-native";
import { useForm, Controller } from "react-hook-form";
import {
  ScrollViewContainer,
  SolarLogo,
  LeblonLogo,
  FormContainer,
  FormRow,
  TextInputField,
} from "./styles";
import RNPickerSelect from "react-native-picker-select";
import ConfirmButton from "../../components/buttons/confirmButton";
import { Masks, formatWithMask } from "react-native-mask-input";
import addClient from "../../services/addClient";
import AuthContext from "../../context";
import Spinner from "react-native-loading-spinner-overlay";
import ModalAlert from "../../components/modalAlert";
import getAlianseUser from "../../services/getAlianseUser";
import updateClient from "../../services/updateClient";
import privacyPolicy from "../../services/privacyPolicy";
import validateCPF from "../utils/validateCpf";
import GlobalMessages from "../../config/Messages";
import getLoyaltyUser from "../../services/getLoyaltyUser";
import sendMail from "../../services/sendEmail";
import { Feather } from "@expo/vector-icons";
import Checkbox from "expo-checkbox";

const Signup: React.FC<any> = ({ navigation, route }) => {
  const params = route.params;
  const ref: any = useRef();

  const {
    fidelityToken,
    signIn,
    setUserId,
    setGivenName,
    setFamilyName,
    setCpf,
    setTelephoneNumber,
    setModality,
    setBalance,
  } = useContext(AuthContext);

  const [alert, setAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [privacyPolicyState, setPrivacyPolicyState] = useState("");
  const [loyaltyRegulation, setLoyaltyRegulation] = useState("");
  const [sellerChecked, setSellerChecked] = useState(false);

  const populeLinkToOpenBrowser = async () => {
    const getPolicies = await privacyPolicy(fidelityToken);
    if (!getPolicies) return;

    setPrivacyPolicyState(getPolicies[0]?.conteudo);
    setLoyaltyRegulation(getPolicies[1]?.conteudo);
  };

  useEffect(() => {
    populeLinkToOpenBrowser();
  }, []);

  interface IUserData {
    id: string;
    givenName: string;
    familyName: string;
    cpf: string;
    phoneNumber: string;
  }

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      nome: `${params?.givenName}` || "",
      sobrenome: params?.familyName || "",
      email: params?.email,
      data_nascimento: params?.birthDate || "",
      celular: params?.phoneNumber,
      cpf: params?.cpf,
      sexo: null,
      codIndicacao: "",
    },
  });

  const validateEmail = (email: any) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const moveToTop = () => {
    ref.current.scrollTo({
      y: 0,
      animated: true,
    });
  };

  const onSubmit = async (response: any) => {
    var error = false;

    if (response.nome === undefined) {
      setAlertText("O campo nome é obrigatório");
      setAlert(true);
      error = true;
    }
    if (response.sobrenome === undefined) {
      setAlertText("O campo sobrenome é obrigatório");
      setAlert(true);
      error = true;
    }
    if (response.email === undefined) {
      setAlertText("O campo email é obrigatório");
      setAlert(true);
      error = true;
    }
    if (response.data_nascimento === undefined) {
      setAlertText("O campo data de nascimento é obrigatório");
      setAlert(true);
      error = true;
    }
    if (response.celular === undefined) {
      setAlertText("O campo celular é obrigatório");
      setAlert(true);
      error = true;
    }
    if (response.cpf === undefined) {
      setAlertText("O campo cpf é obrigatório");
      setAlert(true);
      error = true;
    }

    if (sellerChecked && response.codIndicacao === undefined) {
      setAlertText("O campo vendedor é obrigatório");
      setAlert(true);
      error = true;
    }

    var message: any = [];
    Object.keys(response).map(function (key, value) {
      if (
        response[key] === undefined ||
        response[key] === null ||
        response[key].length <= 0
      ) {
        if (key.toString() == "nome") {
          message.push("O campo nome é obrigatório");
        }
        if (key.toString() == "sobrenome") {
          message.push("O campo sobrenome é obrigatório");
        }
        if (key.toString() == "email") {
          message.push("O campo email é obrigatório");
        }
        if (key.toString() == "data_nascimento") {
          message.push("O campo data de nascimento é obrigatório");
        }
        if (key.toString() == "celular") {
          message.push("O campo celular é obrigatório");
        }
        if (key.toString() == "cpf") {
          message.push("O campo cpf é obrigatório");
        }
        if (sellerChecked) {
          if (key.toString() == "codIndicacao") {
            message.push("O campo vendedor é obrigatório");
          }
        }
      }
    });

    if (message.length > 0) {
      setAlertText(message[0]);
      setAlert(true);
      moveToTop();
      return false;
    }

    const celular = formatWithMask({
      text: response.celular,
      mask: Masks.BRL_PHONE,
    });

    const cpf = formatWithMask({
      text: response.cpf,
      mask: Masks.BRL_CPF,
    });

    const maskedBirthday = formatWithMask({
      text: response.data_nascimento,
      mask: Masks.DATE_DDMMYYYY,
    });

    if (!validateEmail(response.email)) {
      setAlertText("Preencha um email válido");
      error = true;
    }

    if (!validateCPF(cpf.unmasked)) {
      setAlertText("Preencha um cpf válido");
      error = true;
    }

    if (maskedBirthday.unmasked.length !== 8) {
      setAlertText("Preencha uma data de nascimento válida");
      error = true;
    }
    if (celular.unmasked.length !== 11) {
      setAlertText("Preencha um número de celular válido");
      error = true;
    }

    if (error) {
      setAlert(true);
      moveToTop();
      return false;
    }

    setAlert(false);

    const gender = response.sexo === "NÃO_INFORMAR" ? null : response.sexo;
    const [ddd, phone] = [
      celular.unmasked.slice(0, 2),
      celular.unmasked.slice(2),
    ];

    var data = {
      extension_cpf: cpf.unmasked,
      given_name: response.nome,
      family_name: response.sobrenome,
      extension_Nascimento: maskedBirthday.masked,
      sexo: gender,
      email: response.email,
      celular: phone,
      ddd: ddd,
      sellerCode: response.codIndicacao,
    };

    const userData: IUserData = {
      id: "",
      givenName: response.nome,
      familyName: response.sobrenome,
      cpf: cpf.unmasked,
      phoneNumber: celular.unmasked,
    };

    setIsLoading(true);
    const addClientResponse: any = await addClient(
      data,
      fidelityToken,
      true
    );
    if (addClientResponse?.response?.status === 201) {
      let template = `<p> Nome: ${data.given_name} ${data.family_name} </p> <br/> <p>CPF: ${data.extension_cpf} </p> <br/>  <p> Data de Nascimento: ${data.extension_Nascimento} </p> <br/> <p> Celular: ${data.celular} </p> <br/> <p>Sexo: ${data.sexo} </p> <br/> <p>Email: ${data.email} </p>`;
      await sendMail(fidelityToken, "Cadastro Cliente Solar", template);
      const alianseUser: any = await getAlianseUser(
        cpf.unmasked,
        fidelityToken,
      );
      if (alianseUser?.status === 200) {
        userData.id = alianseUser?.data?.pessoaFisica?.id;
        signInUser(userData);
      }
    } else {
      if (addClientResponse?.response.status === 409) {
        const alianseUser: any = await getAlianseUser(
          cpf.unmasked,
          fidelityToken,
        );

        if (alianseUser?.status === 200) {
          await updateClient(
            alianseUser?.data?.pessoaFisica?.id,
            data,
            fidelityToken,
            true
          );
          userData.id = alianseUser?.data?.pessoaFisica?.id;
          signInUser(userData);
        }
      }
    }
  };

  const signInUser = async (user: IUserData) => {
    const { id, givenName, familyName, cpf, phoneNumber } = user;
    let fidelityMaxAttempts = 3;
    let hasFidelityUser = false;

    for (let i = 1; i <= fidelityMaxAttempts; i++) {
      const loyaltyUser = await getLoyaltyUser(id, fidelityToken);

      if (!!loyaltyUser["modalidade"]) {
        hasFidelityUser = true;
        setIsLoading(false);

        setUserId(id);
        setGivenName(givenName);
        setFamilyName(familyName);
        setCpf(cpf);
        setTelephoneNumber(phoneNumber);
        setBalance(loyaltyUser.saldo);
        setModality(loyaltyUser.modalidade);

        signIn();
        break;
      }
    }

    if (!hasFidelityUser) {
      setIsLoading(false);
      setAlertText(GlobalMessages.serviceError);
      setAlert(true);
      moveToTop();
    }
  };

  return (
    <KeyboardAvoidingView behavior="padding" enabled>
      <ScrollViewContainer
        ref={ref}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ alignItems: "center" }}
      >
        <SolarLogo source={require("../../../assets/LogoSolar.png")} />
        <LeblonLogo source={require("../../../assets/LogoLeblon.png")} />
        <FormContainer>
          <View style={{ alignItems: "center", marginBottom: 20 }}>
            <Text style={{ fontWeight: "bold", fontSize: 18 }}>
              Confirme seu cadastro
            </Text>
          </View>
          <FormRow>
            <Controller
              control={control}
              name="nome"
              render={({ field: { onChange, value, onBlur } }) => (
                <TextInputField
                  placeholder="Nome"
                  placeholderTextColor="#656666"
                  value={value}
                  onBlur={onBlur}
                  onChangeText={(value) => onChange(value)}
                />
              )}
            />
            <Controller
              control={control}
              name="sobrenome"
              render={({ field: { onChange, value, onBlur } }) => (
                <TextInputField
                  placeholder="Sobrenome"
                  placeholderTextColor="#656666"
                  value={value}
                  onBlur={onBlur}
                  onChangeText={(value) => onChange(value)}
                />
              )}
            />
          </FormRow>
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value, onBlur } }) => (
              <TextInputField
                editable={false}
                style={{ opacity: 0.5 }}
                autoCapitalize="none"
                autoCorrect={false}
                autoCompleteType="email"
                placeholder="email@exemplo.com.br"
                placeholderTextColor="#656666"
                value={value}
                onBlur={onBlur}
                onChangeText={(value) => onChange(value)}
              />
            )}
          />
          <FormRow>
            <Controller
              control={control}
              name="data_nascimento"
              render={({ field: { onChange, value, onBlur } }) => (
                <TextInputField
                  placeholder="00/00/0000"
                  placeholderTextColor="#656666"
                  value={value}
                  onBlur={onBlur}
                  onChangeText={(value) => onChange(value)}
                  mask={Masks.DATE_DDMMYYYY}
                />
              )}
            />
            <Controller
              control={control}
              name="celular"
              render={({ field: { onChange, value, onBlur } }) => (
                <TextInputField
                  placeholder="(00) 00000-0000"
                  placeholderTextColor="#656666"
                  value={value}
                  onBlur={onBlur}
                  onChangeText={(value) => onChange(value)}
                  mask={Masks.BRL_PHONE}
                />
              )}
            />
          </FormRow>
          <FormRow>
            <Controller
              control={control}
              name="cpf"
              render={({ field: { onChange, value, onBlur } }) => (
                <TextInputField
                  editable={false}
                  style={{ opacity: 0.5 }}
                  placeholder="000.000.000-00"
                  placeholderTextColor="#656666"
                  value={value}
                  onBlur={onBlur}
                  onChangeText={(value) => onChange(value)}
                  mask={Masks.BRL_CPF}
                />
              )}
            />
            <Controller
              control={control}
              name="sexo"
              render={({ field: { onChange, value, onBlur } }) => (
                <RNPickerSelect
                  style={{
                    ...RNPickerConfig,
                    iconContainer: { right: 0, top: 22 },
                  }}
                  useNativeAndroidPickerStyle={false}
                  onValueChange={(value) => onChange(value)}
                  placeholder={{
                    label: "Selecione o gênero",
                    value: null,
                  }}
                  items={[
                    { label: "Masculino", value: "MASCULINO" },
                    { label: "Feminino", value: "FEMININO" },
                    { label: "Prefiro não informar", value: "NÃO_INFORMAR" },
                  ]}
                  Icon={() => (
                    <Feather name="chevron-down" size={20} color={"#656666"} />
                  )}
                />
              )}
            />
          </FormRow>
          <View
            style={{
              marginTop: 30,
              marginHorizontal: 10,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Checkbox
              disabled={false}
              value={sellerChecked}
              onValueChange={() => setSellerChecked(!sellerChecked)}
              color={"#000"}
            />
            <Text style={{ fontWeight: "bold", marginLeft: 10 }}>
              Fui indicado por um lojista
            </Text>
          </View>
          {sellerChecked && (
            <Controller
              control={control}
              name="codIndicacao"
              render={({ field: { onChange, value, onBlur } }) => (
                <TextInputField
                  editable={true}
                  autoCapitalize="none"
                  autoCorrect={false}
                  placeholder="Insira o código do lojista"
                  placeholderTextColor="#656666"
                  value={value}
                  onBlur={onBlur}
                  onChangeText={(value) => onChange(value)}
                />
              )}
            />
          )}
          <View style={{ marginTop: 40 }}>
            <Text
              style={{
                textAlign: "center",
                letterSpacing: 0.3,
                lineHeight: 22,
              }}
            >
              Ao clicar em “Enviar” você estará concordando com a nossa
              <Text
                onPress={() => Linking.openURL(privacyPolicyState)}
                style={{ fontWeight: "bold" }}
              >
                {` Politica de Privacidade`}
              </Text>
              <Text> e </Text>
              <Text
                onPress={() => Linking.openURL(loyaltyRegulation)}
                style={{ fontWeight: "bold" }}
              >
                {`Regulamento do Programa.`}
              </Text>
            </Text>
          </View>
          <View style={{ marginTop: 50, marginBottom: 20 }}>
            <ConfirmButton text="ENVIAR" onPress={handleSubmit(onSubmit)} />
          </View>
        </FormContainer>
        <ModalAlert
          text={alertText}
          textButtonConfirm="OK"
          isVisible={alert}
          onPressConfirm={() => {
            setAlert(false);
            if (
              alertText === GlobalMessages.serviceError ||
              alertText === GlobalMessages.userInactive
            ) {
              navigation.navigate("PublicHome");
            }
          }}
        />
        {isLoading && (
          <Spinner
            visible={true}
            textContent={"Carregando..."}
            textStyle={{ color: "#fff" }}
            overlayColor="rgba(0,0,0,0.8)"
          />
        )}
      </ScrollViewContainer>
    </KeyboardAvoidingView>
  );
};

const PickerGlobalConfig = {
  borderBottomWidth: 1,
  borderBottomColor: "#939393",
  marginHorizontal: 10,
  paddingTop: 6,
  height: 60,
  minWidth: "50%",
};

const RNPickerConfig = {
  inputAndroid: {
    color: "black",
    ...PickerGlobalConfig,
  },
  inputIOS: { ...PickerGlobalConfig },
};

export default Signup;
