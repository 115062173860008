import React, { useContext, useEffect, useState } from 'react';
import { View, Text, Button,Alert, Platform,PermissionsAndroid, Image, Linking } from 'react-native';
import { BarCodeScanner } from 'expo-barcode-scanner';
import createInvoice from '../../services/createInvoice';
import AuthContext from '../../context';
import Back from '../../components/back';
import {Feather} from "@expo/vector-icons"
import { defaultTheme } from '../../styles/themes/default';
import { MediumTextBold } from '../../styles/text/boldText/mediumTextBold';
import { SmallText } from '../../styles/text/texts/smallText';
import DefaultButton from '../../components/buttons/defaultButton';
import { Camera } from 'expo-camera';
// import { Container } from './styles';
import BackWithPoints from '../../components/backWithPoints/index';
import { BigTextBold } from '../../styles/text/boldText/bigTextBold';
import readQRFailed from '../../../assets/readQRFailed.png'
import readQRSucces from '../../../assets/readQRSucces.png'
import cameraIcon from '../../../assets/cameraIcon.png'
import { BigText } from '../../styles/text/texts/bigText';
import Svg, { G, Path } from 'react-native-svg';
import {SvgUri} from 'react-native-svg'
import QRCODEIMG from '../../../assets/qr_code_scan.png'

const BarCodeScannerPage: React.FC = ({navigation}:any) => {
  const [scaned, setScanned] = useState<boolean>(true)
  const [introduction, setIntroduction] = useState<boolean>(true)
  const [readError, setReadError] = useState<boolean>(false)
  const [readSucces, setReadSucces] = useState<boolean>(false)

const {userId, requestCameraAccess, setRequestCameraAccess, balance, fidelityToken} = useContext(AuthContext)

  const handleScan = async(data:any)=>{
    setScanned(true)
    
    let postInvoice = await createInvoice(userId,data,fidelityToken)

    if(postInvoice && postInvoice == 200 || postInvoice && postInvoice == 201){
      setReadSucces(true)
      return setScanned(false)
    }

    return setReadError(true)
  }

  const requestCameraPermission = async () => {
    const granted = await PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS.CAMERA,
      {
        title: "App Permissão de Câmera",
        message: "Vamos pedir acesso a sua camera para fazer a leitura de seu QR Code na proxima tela",
        buttonNeutral: "Pergunte-me depois",
        buttonNegative: "Cancelar",
        buttonPositive: "OK"
      }
    );
    if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      setScanned(false)
    } else {
      setScanned(false)
    }
};
const timer = (clear:boolean) =>{
  setReadSucces(false)
  if(!introduction && requestCameraAccess ){

    let timerVar = setTimeout(()=>{
      setReadError(true)
      // setIntroduction(true)
    },30000 )

    if(clear){
      return clearTimeout(timerVar)
    }
    return timerVar
  }

}
  const createTwoButtonAlert = (message:string) =>
    Alert.alert('Alerta', message, [
      // {
      //   text: 'Cancel',
      //   onPress: () => console.log('Cancel Pressed'),
      //   style: 'cancel',
      // },
      { text: 'OK', onPress: () => setScanned(false) },
    ]);
  useEffect(() => {
    timer(true)
  }, [requestCameraAccess]);

  const onTextPress = () => {
    return Linking.openURL('https://wa.me/5521990128905')
  }

  return(
    <View style={{flex:1}}>

      {readSucces?
 <View style={{width:'100%'}}>
   <View style={{width:'98%', alignItems: 'center'}}>
     <BackWithPoints text="ADICIONAR NOTA FISCAL" disablePoints={true} redirect='Inicio' navigation={navigation}/>
       <View style={{width:'90%', alignItems: 'center', margin:40}}>
         <BigTextBold>
         Nota fiscal enviada para processamento.
         </BigTextBold>
       </View>
       <Image style={{margin:20}} source={readQRSucces} />
       <View style={{width:'90%', alignItems: 'center', margin:40}}>
         <BigText>
          Você pode acompanhar o status da sua nota em <BigTextBold>“Nota Fiscal.” </BigTextBold>
         </BigText>
         <BigText style={{ marginTop: 10 }}>
          Lembre-se também de cadastrar suas notas nas promoções vigentes do shopping.
         </BigText>
       </View>
       <View style={{width:'90%', alignItems: 'center', margin:40}}>

       <DefaultButton theme='Dark' onPress={()=>{setReadSucces(false)}} text='ESCANEAR MAIS UMA NOTA'/>
       </View>

   </View>
 </ View>
      
      :
      readError && !introduction ?
      <View style={{width:'100%'}}>
        <View style={{width:'98%', alignItems: 'center'}}>
          <BackWithPoints text="ADICIONAR NOTA FISCAL" disablePoints={true} redirect='Inicio' navigation={navigation}/>
            <View style={{width:'90%', alignItems: 'center', margin:40}}>
              <BigTextBold>
              Não foi possivel ler o QR Code apresentado.
              </BigTextBold>
            </View>
            <Image style={{margin:20}} source={readQRFailed} />
            <View style={{width:'90%', alignItems: 'center', margin:30}}>
              <BigText >
              Tente novamente ou apresente a nota fiscal no lounge Solar.
              </BigText>
            </View>
            <View style={{width:'90%', alignItems: 'center', margin:20}}>

            <DefaultButton theme='Dark' onPress={()=>{setReadError(false)}} text='TENTAR NOVAMENTE'/>
            </View>

        </View>
      </ View>:
        !requestCameraAccess || introduction? 
        <View style={{width:'100%'}}>
          <View style={{width:'98%'}}>
          <BackWithPoints  text="ADICIONAR NOTA FISCAL" disablePoints={true}  size="big" redirect='Inicio' navigation={navigation}/>
            </View>
          {/* <Back title="ADICIONAR NOTA FISCAL" size="big" redirect='Inicio' navigation={navigation} /> */}
          <View style={{display:'flex',width:'100%', justifyContent:'center', alignItems:'center', marginTop:40, padding:13}}>

            <Image resizeMode='stretch' style={{width:'38.4%', height:'27.7%', marginBottom:40}} source={QRCODEIMG} />

            <BigText style={{textAlign:'center', marginBottom:40 , marginTop:30}}>
              Você está próximo a acumular pontos {'\n'}
              para usufruir de diversos benefícios {'\n'}
              disponíveis no SOLAR.
            </BigText>
            <DefaultButton theme="Dark" onPress={()=>{setScanned(false), setIntroduction(false), requestCameraPermission(), setRequestCameraAccess(true)}} text='ESCANEAR QR CODE DA NOTA FISCAL'/>
            <MediumTextBold style={{textAlign:'center', marginBottom:20 , marginTop:30, lineHeight: 26}}>
              Caso sua nota não tenha QRCode, envie por {''}
               <Text style={{ color: '#0000FF', textDecorationLine: 'underline'}} onPress={onTextPress}>aqui</Text>, 
               ou compareça ao lounge SOLAR.
            </MediumTextBold>
          </View>
        </View>
        :
        <>
        <Camera
        onCameraReady={()=>timer(false)}
        onBarCodeScanned={(e)=> {scaned === false? handleScan(e.data):null}}
        style={{flex:1, top:-40}}
        >
        <>
        <View style={{position: 'absolute', bottom: 0, left: 0, right: 0,top: 40, height: '100%', flexDirection: 'row'}}>
          <View style={{display:'flex', width:'100%', backgroundColor:'transparent', flexDirection:'column'}}>
          <View style={{backgroundColor:'rgba(0,0,0,0.8)', height:'30%', justifyContent:'center',alignItems:'center'}}>
            <View style={{width:200}}>

              <BigTextBold style={{color: "#FFF"}}>
              Posicione o QR-Code da nota no espaço abaixo

              </BigTextBold>
            </View>

          </View>
          <View style={{display:'flex', flexDirection: 'row', justifyContent:'space-between', width:'100%', height:'40%'}} >
            <View style={{backgroundColor:'rgba(0,0,0,0.8)', height:'100%', width:'20%', justifyContent:'center',alignItems:'center'}}>
            </View>
            <View style={{backgroundColor:'rgba(0,0,0,0.8)', height:'100%', width:'20%', justifyContent:'center',alignItems:'center'}}>
            </View>
          </View>

          <View style={{backgroundColor:'rgba(0,0,0,0.8)', height:'30%', justifyContent:'center',alignItems:'center'}}>
          </View>

          </View>

        </View>
        <View style={{ backgroundColor:'transparent' ,position: 'absolute', bottom: 0, left: '18%', right: 0,top: '36%', height: '44%', width:'64%', flexDirection: 'row'}}>
          <View style={{display:'flex', width:'100%', flexDirection:'column',justifyContent:'space-between'}}>
        <View>
          <View style={{display:'flex', width:'100%', height:2 , flexDirection:'row',justifyContent:'space-between'}}>
          <View style={{display:'flex', width:40, height:2 ,backgroundColor:'#FFF'}}></View>
          <View style={{display:'flex', width:40, height:2 ,backgroundColor:'#FFF'}}></View>
          </View>

          <View style={{display:'flex', width:'100%', height:2 , flexDirection:'row', justifyContent:'space-between'}}>
          <View style={{display:'flex', width:2, height:40 ,backgroundColor:'#FFF'}}></View>
          <View style={{display:'flex', width:2, height:40 ,backgroundColor:'#FFF'}}></View>
          </View>
        </View>

        <View>
          <View style={{display:'flex', width:'100%', height:40 , flexDirection:'row',justifyContent:'space-between'}}>
          <View style={{display:'flex', width:2, height:40 ,backgroundColor:'#FFF'}}></View>
          <View style={{display:'flex', width:2, height:40 ,backgroundColor:'#FFF'}}></View>
          </View>

          
          <View style={{display:'flex', width:'100%', height:2 , flexDirection:'row',justifyContent:'space-between'}}>
          <View style={{display:'flex', width:40, height:2 ,backgroundColor:'#FFF'}}></View>
          <View style={{display:'flex', width:40, height:2 ,backgroundColor:'#FFF'}}></View>
          </View>

        </View>


          </View>

        </View>
  
        </>
        </Camera>
        </>
        }

      {/* {scanned && <Button title={'Tap to Scan Again'} onPress={() => setScanned(false)} />} */}
    </View>
  )
}

export default BarCodeScannerPage;