import { Platform } from 'react-native';
import styled  from 'styled-components/native';

export const Container = styled.View`
width: 100%;
min-height: 300px;
/* background-color: aqua; */
/* background-color: red; */
`


export const ItemContainer = styled.View`
/* width: ${props=> ((props.theme.ScreenWidth)*20)/100}px; */
/* width: 300; */
/* height: 400px; */
/* background-color: aqua; */
display: flex;
justify-content: center;
align-items: center;
align-items: center;
width:  ${props=> Platform.OS ==='web' ? ((props.theme.ScreenWidth * 50)/100):((props.theme.ScreenWidth * 85)/100)}px;
margin-left: ${props=> Platform.OS==='web'?((props.theme.ScreenWidth * 20)/100):10}px;
margin-right: ${props=>Platform.OS==='web'?((props.theme.ScreenWidth * 20)/100):((props.theme.ScreenWidth * 8)/100)}px;
margin-bottom: 0px;

`

export const Title = styled.Text`
  font-size: 26px;
  color: ${props =>props.theme.colors.text};
  font-weight: bold;
font-family: ${props => props.theme.fontFamily};
`

export const TextSpaces = styled.View`
margin-top: 20px;
/* background-color: blue; */
`


export const TouchableDots = styled.TouchableOpacity`
width:50px; 
height:50px;
border-radius:4px;

/* border-width:2px; */
margin-right: 8px;
display: flex;
justify-content: center;
align-items: center;
background-color: transparent;
`;

export const DotsColor = styled.TouchableOpacity`
width:98%; 
height:2px;
/* background-color: aliceblue; */
border-radius:4px;

`
export const DotsContainer = styled.View`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-top: ${Platform.OS ==="web" ? '0px': '0px'};

`;