import { Platform, Dimensions } from 'react-native';
import styled  from 'styled-components/native';

const { width, height } = Dimensions.get('window');

export const Container = styled.View`
width: 100%;
padding-horizontal: 10px;
padding-vertical: 20px;
align-items: center;
background-color: ${props=> props.theme.colors.profits.backgroundColor};
display: flex;
`
export const ImageProfit = styled.Image`

${props => Platform.OS ==='web' && width > 1600 ? 'width: 400px;':  Platform.OS ==='web' && width < 1600 ? 'width: 320px':null };
${Platform.OS ==='web'? 'height: 200px': 'flex: 1;width: 200px'};

background-color: pink;
`
/* ${Platform.OS !='web'? 'width: 400px': null } */
// max-height: 260px; min-height: 260px; min-width: 300px; max-width: 300px
export const ProfitText = styled.Text`
font-family: ${props=> props.theme.fontFamily};
font-weight: bold;
font-size: 20px;
`
export const Title = styled.Text`
font-family: ${props=> props.theme.fontFamily};
font-weight: bold;
color: ${props => props.theme.colors.profits.titleColor};
font-size: 18px;
`
export const Name = styled.Text`
font-family: ${props=> props.theme.fontFamily};
font-weight: bold;
color: ${props => props.theme.colors.profits.nameColor};
margin-top: 10px;
`


export const ItemContainer = styled.View`
width: 200px;
align-items: center;
margin-horizontal: 10px;
margin-bottom: 30px;


/* background-color: green; */
border-bottom-width: 1.4px;
border-bottom-color: ${props => props.theme.colors.profits.borderColor};
padding-bottom: 6px;

`
// display:'flex', marginRight:10, height: '100%', backgroundColor: 'green'