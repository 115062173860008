import AuthRequest from '../config/AuthRequest';
import axios from 'axios';
import Version from '../config/Version';

const verifyVersion = async () => {

    try{
        const response = await axios.get(`${AuthRequest.fidelityApi}/version?version=${Version.currentVersion}`,
        {
            headers:{
                "Content-Type": "application/json",
            }
        })
        return response
    }catch(e:any){

        return e.response;
    }

    
}

export default verifyVersion;