import React, { useContext, useEffect, useState } from "react";
import { View, Text } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { useQuery } from "react-query";
import Back from "../../components/back";
import BackWithPoints from "../../components/backWithPoints";
import BenefitsMenus from "../../components/benefitsMenus";
import ListBenefitsTierNew from "../../components/listBenefitsTierNew";
import AuthContext from "../../context";
import { getBenefitImagesLumisApi, getBenefits } from "../../services/get";
import { ContentAligned } from "../../styles/containers/main/contentAligned";
import { MainCotainer } from "../../styles/containers/main/mainContainer";
import { Wrapper } from "../../styles/containers/main/wrapper";
import { SecondContentAligned } from "../../styles/containers/second/secondContentAligned";
import { SecondMainCotainer } from "../../styles/containers/second/secondMainContainer";
import { SecondWrapper } from "../../styles/containers/second/secondWrapper";
import { benefitsBronzeData } from "../Benefits/fakaData";

// import { Container } from './styles';

const BenefitsTier: React.FC = ({ navigation, route }: any) => {
  const [menusIsFilter, setMenusIsFilter] = useState(false);
  const [filter, setFilter] = useState("");
  const { loyaltyUser, fidelityToken } = useContext(AuthContext);

  const benefitsQuery = useQuery(["benefits", fidelityToken], getBenefits);
  const benefitsImagesQuery = useQuery(
    ["benefits-images", fidelityToken],
    getBenefitImagesLumisApi
  );

  const { title } = route?.params;

  useEffect(() => {
    if (+route.params?.categoryId !== 0) {
      setFilter(route.params?.categoryId);
      setMenusIsFilter(true);
    }
  }, [route.params]);

  const handleCategoryFilter = (categoryId: any) => {
    setFilter(categoryId);
  };

  const getFilteredList = () => {
    const benefitList: any = [];
  
    benefitsQuery.data?.data.forEach((benefit: any) => {
      benefitList.push({
        benefit: null,
        benefitDetails: benefit,
      });
    });
    return benefitList;
  };

  return (
    <>
      <SecondMainCotainer>
        <SecondWrapper>
          <SecondContentAligned>
            <BackWithPoints
              text="BENEFÍCIOS"
              title="MEUS PONTOS"
              points={loyaltyUser.saldo}
              size="big"
              redirect="back"
              navigation={navigation}
            />

            {/* <Back title="BENEFÍCIOS" size="big" redirect='Inicio' navigation={navigation} /> */}
          </SecondContentAligned>
        </SecondWrapper>
      </SecondMainCotainer>

      <MainCotainer>
        <Wrapper>
          <ContentAligned style={{ marginTop: 0 }}>
            <ScrollView showsVerticalScrollIndicator={false}>
              {
                // To do:
                // Fazer os dados atualizarem quando CB for assionado
              }

              <BenefitsMenus
                cb={(categoryId: any) => handleCategoryFilter(categoryId)}
                filter={menusIsFilter}
                nav={navigation}
              />

              <ListBenefitsTierNew
                data={benefitsQuery.data?.data}
                benefitsImages={benefitsImagesQuery.data?.data}
                nav={navigation}
                params={route.params}
                filter={filter}
                myBenefits={false}
                modality={title}
                myBenefitList={getFilteredList()}
              />
            </ScrollView>
          </ContentAligned>
        </Wrapper>
      </MainCotainer>
    </>
  );
};
export default BenefitsTier;
