import { useContext } from 'react'
import AuthContext from '../context';
import getLoyaltyUser from '../services/getLoyaltyUser';

export const useLoyaltyUser = () => {
    const { setLoyaltyUser, userId, fidelityToken }: any = useContext(AuthContext);

    const getUpdateLoyaltyUser = () => {
        getLoyaltyUser(userId, fidelityToken).then(loyalty => setLoyaltyUser(loyalty))
    }

    return { getUpdateLoyaltyUser }
}