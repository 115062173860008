import AuthRequest from '../config/AuthRequest';
import axios from 'axios';

export const getPointsExpire = async (props:any) => {
    
    const response =  await axios.get(`${AuthRequest.fidelityApi}/benefits/getPointsExpire/${props.queryKey[1]}`, {
        headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer ${props.queryKey[2]}`,
        }
    })

    return response;
  
}

export const getBenefits = async ({queryKey}:any) => {

    const response =  await axios.get(`${AuthRequest.fidelityApi}/benefits/getBenefits`, {
        headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer ${queryKey[1]}`,
        }
    })
    return response;
}

export const getUserBenefits = async ({queryKey}:any) => {

    const response =  await axios.get(`${AuthRequest.fidelityApi}/benefits/getUserBenefits/${queryKey[1]}`, {
        headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer ${queryKey[2]}`,
        }
    })
    
    return response;  
 
}

export const getBenefitImageLumisApi = async ({queryKey}:any) => {

    const response =  await axios.get(`${AuthRequest.fidelityApi}/benefits/getBenefitImageLumisApi/${queryKey[1]}`, {
        headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer ${queryKey[2]}`
        }
    })
    return response;

}

export const getBenefitImagesLumisApi = async ({queryKey}:any) => {
   
    const response =  await axios.get(`${AuthRequest.fidelityApi}/benefits/getBenefitImagesLumisApi`, {
        headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer ${queryKey[1]}`,
        }
    })
    return response;
   
}

export const getSpecificBenefit = async (props:any) => {
  
    const response =  await axios.get(`${AuthRequest.fidelityApi}/benefits/getSpecificBenefit/${props.queryKey[1]}`, {
        headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer ${props.queryKey[2]}`
        }
    })
    return response;
    
}