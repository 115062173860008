import React, { useContext } from 'react';
import { View, Text, Platform } from 'react-native';
import Back from '../../components/back';
import BackWithPoints from '../../components/backWithPoints';
import CurrentBalance from '../../components/currentBalance';
import StatementList from '../../components/statementList';
import AuthContext from '../../context';
import { ContentAligned } from '../../styles/containers/main/contentAligned';
import { MainCotainer } from '../../styles/containers/main/mainContainer';
import { Wrapper } from '../../styles/containers/main/wrapper';
import { SecondContentAligned } from '../../styles/containers/second/secondContentAligned';
import { SecondMainCotainer } from '../../styles/containers/second/secondMainContainer';
import { SecondWrapper } from '../../styles/containers/second/secondWrapper';

// import { Container } from './styles';

const Statement: React.FC<any> = ({navigation}) => {
const {loyaltyUser} = useContext(AuthContext)

  return (
    <>
        <SecondMainCotainer>
            <SecondWrapper>
                <SecondContentAligned> 
                  <BackWithPoints text="EXTRATO" title="MEUS PONTOS" points={loyaltyUser.saldo} size="big" redirect='Inicio' navigation={navigation}/>

                    {/* <Back title="EXTRATO" size="big" redirect='Inicio' navigation={navigation} /> */}
                    {/* <CurrentBalance /> */}
                </SecondContentAligned>
            </SecondWrapper>
        </SecondMainCotainer>

        <MainCotainer style={Platform.OS==='web' ?{minHeight:0}:{  minHeight:280}}>
    
          <Wrapper>
            <ContentAligned>

              <StatementList/>

            </ContentAligned>
            </Wrapper>
        </MainCotainer>
              {/* <StatementList/> */}

    </>

  )
}

export default Statement;