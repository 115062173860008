import AuthRequest from '../config/AuthRequest';
import axios from 'axios';
import URL from '../config/URL';


const getLoyaltyUser = async (id:any, token:any)=>{
    try{

    const getLayalty = await axios.get(`${AuthRequest.fidelityApi}/sensedia/getLoyaltyUser/`,
    {
        params: {
            id
        },
        headers:{
            "Content-Type": "application/json",
            "application": AuthRequest.Application,
            "Authorization": `Bearer ${token}`,
        }
    })

return getLayalty.data
} catch(e){
    return "Request Error"
}


}

export default getLoyaltyUser