
 const __message =  `
 Instruções para fazer parte:   \n
 1.  Para que você fique ativo no programa, a 1ª nota deve ser cadastrada no Lounge do Shopping.
 \n
 2.  Em até 24h você receberá um e-mail com instruções de login e senha.
 \n
 3.  Após essas etapas, as demais notas poderão ser cadastradas diretamente no aplicativo SOLAR.
`

const GlobalMessages = {
    //"userInactive": __message, //"Para concluir o cadastro no Solar, dirija-se ao lounge do Shopping e cadastre a sua primeira nota fiscal.",
    "userInactive": 'Cadastre uma nota para ter acesso aos benefícios do programa.',
    "serviceError": 'Ocorreu um erro ao processar sua requisição \n Tente novamente mais tarde',
    "signupSucess": __message, //"Obrigado pelo seu cadastro! Para concluir o ingresso no Solar, dirija-se ao lounge do Shopping.",
    "signupComplete": __message, //"Para concluir o cadastro no Solar, dirija-se ao lounge do Shopping e cadastre a sua primeira nota fiscal.",
    "signupExistingUser": "Cliente já cadastrado. Para acessar o Solar, faça o login em “já sou cliente”.",
    "pts": "PTS"
}

export default GlobalMessages