import React, { useContext } from 'react';
import { View, Platform } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { MediumTextBold } from '../../styles/text/boldText/mediumTextBold';
import { MediumText } from '../../styles/text/texts/mediumText';
import {Feather} from "@expo/vector-icons"
import { Container, Item,ImageItem } from './styles';
import { defaultTheme } from '../../styles/themes/default';
import AuthContext from '../../context';
import URL from '../../config/URL';
import { BigTextBold } from '../../styles/text/boldText/bigTextBold';
import GlobalMessages from "../../config/Messages";
import thousandsSeparator from '../../pages/utils/thousandsSeparator';

interface BenefitsUsedResp {
    user_id: string;
    benefits: any;
}

const ListBenefitsTierNew = ({ nav, params, myBenefitsIDs, benefitsImages, modality, filter = 0, myBenefits = false, myBenefitList}:any) => {
    const { userId, benefitsUsed, setBenefitsUsed } = useContext(AuthContext);
    
    if(!Array.isArray(myBenefitList)) myBenefitList = []

    let count = 0

  return (
      <Container>
          {
              myBenefitList.map((e:any) => {
                const { benefit, benefitDetails } = e
                
                if(myBenefits) {
                    if(Array.isArray(myBenefitsIDs)) {
                        if(!myBenefitsIDs.includes(benefitDetails.idBeneficio)) {
                            return false
                        }
                    } else {
                        return false;
                    }
                }

                if(modality !== undefined) {
                    let isBronze = benefitDetails?.modalidades.findIndex((element:any) => element.nome == 'BRONZE')
					let isSilver = benefitDetails?.modalidades.findIndex((element:any) => element.nome == 'PRATA')
					let isGold   = benefitDetails?.modalidades.findIndex((element:any) => element.nome == 'OURO')
					
					if(modality === 'SOLAR BRONZE' && isBronze < 0) return false
					if(modality === 'SOLAR PRATA' && isSilver < 0) return false
					if(modality === 'SOLAR OURO' && isGold < 0) return false
                }

                if(Array.isArray(benefitsImages)) {
                    var benefitImageIndex = benefitsImages.findIndex((img:any) => img.idBeneficio == benefitDetails.idBeneficio)
                    var isImage = benefitImageIndex < 0 ? false : true
                    if(benefitImageIndex >= 0) {
                        const [baseURL, dataURL] = benefitsImages[benefitImageIndex]?.imagem.split("data/");
                        const cleanBaseUrl = baseURL.split('/..').join('');
                        var imageURI = `${cleanBaseUrl}data/${dataURL}`;
                    } else {
                        var imageURI = 'https://i.picsum.photos/id/237/536/354.jpg?hmac=i0yVXW1ORpyCZpQ-CknuyV-jbtU7_x9EBQVhvT5aRr0';
                    }
                } else {
                    var imageURI = 'https://i.picsum.photos/id/237/536/354.jpg?hmac=i0yVXW1ORpyCZpQ-CknuyV-jbtU7_x9EBQVhvT5aRr0';
                }
                
                var isUsed = false;
                if(myBenefits) {
                    if(Array.isArray(benefitsUsed)) {
                        benefitsUsed.forEach((t:BenefitsUsedResp) => {
                            if(t.user_id === userId) {
                                if(t.benefits.includes(benefitDetails.idBeneficio)) {
                                    isUsed = true;
                                }
                            }
                        })
                    }   
                }

                if(+filter !== 0 && filter !== 'TODOS') {
                    if(+benefitDetails.categoria.idCategoria !== +filter) {
                        return false;
                    }
                }

                if(benefitDetails.quantidadeEstoque !== null && benefitDetails.quantidadeEstoque <= 0) {
                    return false;
                }

                count++
                return (
                  <Item
                    key={`${benefitDetails.idBeneficio}${count}`}
                    onPress={() => {
                      if (myBenefits) {
                        nav.navigate("Voucher", {
                          benefitID: benefitDetails.idBeneficio,
                          vouchers: benefit.vouchers,
                          voucherId: benefit.id,
                          imageURI,
                        });
                      } else {
                        nav.navigate("SpecificBenefit", {
                          modality: params.title,
                          benefitID: benefitDetails.idBeneficio,
                          imageURI,
                          benefitCategory: benefitDetails?.categoria?.nome,
                        });
                      }
                    }}
                  >
                    <View>
                      <ImageItem source={{ uri: imageURI }} />
                    </View>
                    <View
                      style={{ width: "40%", marginTop: 10, marginLeft: 10 }}
                    >
                      <MediumTextBold>{benefitDetails.nome}</MediumTextBold>
                      <MediumText>{benefitDetails?.loja?.nome}</MediumText>
                      {myBenefits ? (
                        !isUsed ? null : (
                          <MediumTextBold style={{ marginTop: 10 }}>
                            Benefício já utilizado
                          </MediumTextBold>
                        )
                      ) : (
                        <MediumTextBold>
                          {thousandsSeparator(benefitDetails.modalidades[0]?.pontos || 0, ".")}{" "}
                          {GlobalMessages.pts}{" "}
                        </MediumTextBold>
                      )}
                    </View>
                    <View style={{ display: "flex", justifyContent: "center" }}>
                      {myBenefits && benefitDetails.validade === false ? null : (
                        <TouchableOpacity
                          onPress={() => {
                            if (myBenefits) {
                              nav.navigate("Voucher", {
                                benefitID: benefitDetails.idBeneficio,
                                imageURI,
                              });
                            } else {
                              nav.navigate("SpecificBenefit", {
                                modality: params.title,
                                benefitID: benefitDetails.idBeneficio,
                                imageURI,
                              });
                            }
                          }}
                        >
                          <Feather
                            name="chevron-right"
                            size={Platform.OS === "web" ? 30 : 30}
                            color={defaultTheme.colors.text}
                          />
                        </TouchableOpacity>
                      )}
                    </View>
                  </Item>
                );
            }
            )
          }

          {
            count <= 0 ? 
                <BigTextBold style={{textAlign: 'center', marginTop: 20}}>Nenhum benefício encontrado</BigTextBold> 
            : null
          }
      </Container>
  )
}

export default ListBenefitsTierNew;