import AuthRequest from "../config/AuthRequest";
import axios from "axios";

const privacyPolicy = async (token: any) => {
  try {
    const getPolicy = await axios.get(
      `${AuthRequest.fidelityApi}/lumis/getPrivacyTermsPolicyAndRegulation`,
      {
        headers: {
          "Content-Type": "application/json",
          application: AuthRequest.Application,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return getPolicy.data;
  } catch (error) {
    return error;
  }
};

export default privacyPolicy;
