import React from "react";
import { View, FlatList, ListRenderItemInfo } from "react-native";
import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";

import { formatLumisUrl } from "../../pages/utils";
import thousandsSeparator from "../../pages/utils/thousandsSeparator";
import GlobalMessages from "../../config/Messages";

import { IBenefit } from "../../pages/BenefitsNew/types";
import { BanefitImage, ListCard } from "./styles";
import { MediumTextBold } from "../../styles/text/boldText/mediumTextBold";
import { MediumText } from "../../styles/text/texts/mediumText";
import { defaultTheme } from "../../styles/themes/default";

interface IBenefitItem {
  title: "SOLAR BRONZE";
  tier: "BRONZE";
  data: IBenefit[];
}

type IBenefitListProps = {
  listData: IBenefitItem;
};

type Nav = {
  navigate: (value: string, params: any) => void;
};

const BenefitsFullList: React.FC<IBenefitListProps> = ({ listData }) => {
  const navigation = useNavigation<Nav>();
  const { colors } = defaultTheme;

  const onListCardPress = (
    modality: string,
    id: number,
    uri: string,
    category: string
  ) => {
    navigation.navigate("SpecificBenefit", {
      modality: modality,
      benefitID: id,
      imageURI: uri,
      benefitCategory: category,
    });
  };

  const renderListItem = (benefit: IBenefit) => {
    return (
      <ListCard
        onPress={() =>
          onListCardPress(
            listData.tier,
            benefit.idBeneficio,
            formatLumisUrl(benefit.imagem),
            benefit.categoria.nome
          )
        }
      >
        <BanefitImage source={{ uri: formatLumisUrl(benefit.imagem) }} />
        <View style={{ flex: 1, marginHorizontal: 10 }}>
          <MediumTextBold style={{ marginBottom: 20 }}>
            {benefit.nome}
          </MediumTextBold>
          <MediumText>{benefit.loja?.nome}</MediumText>
          <MediumTextBold>
            {thousandsSeparator(benefit.redemptionPoints, ".")}{" "}
            {GlobalMessages.pts}{" "}
          </MediumTextBold>
        </View>
        <Feather name="chevron-right" size={30} color={colors.text} />
      </ListCard>
    );
  };
  return (
    <View style={{ flex: 1 }}>
      <FlatList
        data={listData.data}
        keyExtractor={(item, index) => `${item}${index}`}
        renderItem={({ item }: ListRenderItemInfo<IBenefit>) =>
          renderListItem(item)
        }
      />
    </View>
  );
};

export default BenefitsFullList;
