import React, { useEffect, useRef, useState } from "react";
import { View, Text, ScrollView, Platform, Button, Image } from "react-native";
import { FlatList, TouchableOpacity } from "react-native-gesture-handler";
import { SafeAreaView } from "react-native-safe-area-context";
import { defaultTheme } from "../../../styles/themes/default";
import {
  DotsContainer,
  ItemContainer,
  TouchableDots,
  CarouselImg,
  TextInCarousel,
  ItemContent,
  TextCarouselContent,
  TextCarousel,
  TextDecoration,
  DotsColor,
} from "./styles";

// import { Container } from './styles';
interface items {
  key: number | string;
  id: number | string;
  title: string;
  body: string;
  imgUrl: any;
}
interface props {
  items: items[];
  selectIndex: any;
  setSelectIndex: any;
}
const ImageCarousel: React.FC<props> = ({
  items,
  selectIndex,
  setSelectIndex,
}) => {
  const [dotsIndex, setDotsIndex] = useState(0);

  const refItem = useRef<FlatList>(null);
  useEffect(() => {
    refItem.current?.scrollToIndex({ index: selectIndex, animated: true });
  }, [selectIndex]);

  const Item = ({ item, onPress, backgroundColor, textColor }: any) => {
    var titleSplit = item.title.split(" ");

    return (
      <>
        <ItemContainer
          style={{ backgroundColor: backgroundColor.backgroundColor }}
        >
          <ItemContent>
            <TextCarouselContent style={{ zIndex: 99 }}>
              <TextCarousel onPress={onPress}>
                <TextDecoration />
                {titleSplit.map((element: any, index: any) => {
                  return <TextInCarousel key={index}>{element}</TextInCarousel>;
                })}
              </TextCarousel>
            </TextCarouselContent>

            <CarouselImg
              style={{ resizeMode: "cover", zIndex: 1 }}
              source={item.imgUrl}
            />
          </ItemContent>
        </ItemContainer>
      </>
    );
  };
  const Dots = ({ isActive }: any) => {
    // setSelectIndex

    return (
      <>
        <DotsContainer>
          {items.length > 0
            ? items.map((e, i, arr) => (
                <TouchableDots
                  key={i}
                  onPress={() => {
                    setSelectIndex(i), setDotsIndex(i);
                  }}
                >
                  <DotsColor
                    onPress={() => {
                      setSelectIndex(i), setDotsIndex(i);
                    }}
                    style={
                      i == dotsIndex
                        ? {
                            backgroundColor:
                              defaultTheme.colors.imageCarousel.dotsBackground,
                          }
                        : {
                            backgroundColor:
                              defaultTheme.colors.imageCarousel
                                .dotsBackgroundDisabled,
                          }
                    }
                  />
                </TouchableDots>
              ))
            : null}
        </DotsContainer>
      </>
    );
  };
  const renderItem = ({ item, index }: any) => {
    const backgroundColor =
      item.id === selectIndex
        ? defaultTheme.colors.background
        : defaultTheme.colors.background;
    const color = item.id === selectIndex ? "white" : "black";

    return (
      <Item
        key={index}
        item={item}
        onPress={() => setSelectIndex(item.id)}
        backgroundColor={{ backgroundColor }}
        textColor={{ color }}
      />
    );
  };
  const viewConfigRef = React.useRef({ viewAreaCoveragePercentThreshold: 30 });

  const onViewRef = React.useRef((viewableItems: any) => {
    if (viewableItems.viewableItems.length > 0) {
      setDotsIndex(viewableItems.viewableItems[0].index);
    }
  });

  return (
    <>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SafeAreaView
          style={
            Platform.OS === "web"
              ? { display: "flex", width: "100%", justifyContent: "center" }
              : { height: 300 }
          }
        >
          <FlatList
            onViewableItemsChanged={onViewRef.current}
            viewabilityConfig={viewConfigRef.current}
            ref={refItem}
            onScrollToIndexFailed={(e) => console.log(e)}
            initialScrollIndex={selectIndex || 0}
            pagingEnabled={true}
            // style={{overflow:'scroll'}}
            // keyExtractor={item => item.key}
            extraData={selectIndex}
            data={items}
            renderItem={renderItem}
            horizontal={true}
            showsHorizontalScrollIndicator={false}
          />
        </SafeAreaView>
        <Dots />
      </View>
    </>
  );
};

export default ImageCarousel;
