import AuthRequest from '../config/AuthRequest';
import URL from '../config/URL';
import axios from 'axios';


const createInvoice = async (id:any, qrcode:any, token:any )=>{
        try{
            const invoice = await axios.post(`${AuthRequest.fidelityApi}/sensedia/createInvoice/${id}`,{
                qrcode
            },
            {
                headers:{
                    "Content-Type": "application/json",
                    "application": AuthRequest.Application,
                    "Authorization": `Bearer ${token}`,
                }
            })

        return invoice.status
    } catch(e:any){
        return 'Request Error'
    }


}

export default createInvoice