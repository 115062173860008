import AuthRequest from '../config/AuthRequest';
import axios from 'axios';
import URL from '../config/URL';


const fidelityGetToken = async ()=>{
    console.log(`${AuthRequest.fidelityApi}/oauth/session`)
    try{
        const getToken = await axios.post(`${AuthRequest.fidelityApi}/oauth/session`,{},
        {
            headers:{
                "Content-Type": "application/json",
                "application": AuthRequest.Application
            }
        })
        return getToken.data
    }catch(e){
        return "Request Error"
    }


}

export default fidelityGetToken