import styled from 'styled-components/native';

export const ViewDatePicker = styled.View`
display: flex;
width: 100%;
margin-top: 14px;
`

export const DateFilterButton = styled.TouchableOpacity`
display: flex;
align-items: center;
justify-content: center;
background-color: #000;
min-width: 100px;
height: 40px;
margin-top: 20px;
`