import AuthRequest from '../config/AuthRequest';
import axios from 'axios';


const updateClient = async (userId:any, data:any, token:any, signup = false)=>{
  try{

    const headers = {
      "Content-Type": "application/json",
      "application": AuthRequest.Application,
      "Authorization": `Bearer ${token}`,
    };
    
    await axios.post(`${AuthRequest.fidelityApi}/sensedia/updateClient`,{
      ...data,
      userId,
      signup
    },
    {
      headers
    })

    const update = await axios.post(`${AuthRequest.fidelityApi}/sensedia/classifyClient`,{
      ...data,
      userId,
      signup
    },
    {
      headers
    })

return update.data
} catch(e:any){
return 'Request Error'
}


}

export default updateClient