import React, { useContext } from "react";
import AuthContext from "../../../context";
import {
  Button,
  TextButton,
  InactiveButton,
  TextInactiveButton,
} from "./style";
import { MaterialIcons } from "@expo/vector-icons";
import { defaultTheme } from "../../../styles/themes/default";
import { MediumText } from "../../../styles/text/texts/mediumText/index";
import GlobalMessages from "../../../config/Messages";
import thousandsSeparator from "../../../pages/utils/thousandsSeparator";

const RedemptionBenefit: React.FC<any> = ({
  lowActiveMode,
  modality,
  points,
  onPress,
}: any) => {
  const blockedBenefits = modality === "INATIVOS";
  const lowActiveModeLevel =
    lowActiveMode === "BRONZE" ? 1 : lowActiveMode === "PRATA" ? 2 : 3;
  const modalityLevel =
    modality === "BRONZE" ? 1 : modality === "PRATA" ? 2 : 3;

  const { balance }: any = useContext(AuthContext);

  const missingPoints: number = +points - balance;

  const renderInactiveButton = () => {
    return (
      <>
        <InactiveButton>
          <MaterialIcons
            name="lock-outline"
            size={24}
            color={defaultTheme.colors.silver}
          />
          <TextInactiveButton>RESGATAR BENEFÍCIO</TextInactiveButton>
        </InactiveButton>
        {!blockedBenefits && (
          <MediumText>
            Faltam {thousandsSeparator(missingPoints, ".")}
            {GlobalMessages.pts} para você resgatar esse benefício
          </MediumText>
        )}
      </>
    );
  };

  if (modalityLevel < lowActiveModeLevel) {
    return (
      <InactiveButton>
        <MaterialIcons
          name="lock-outline"
          size={24}
          color={
            lowActiveMode === "PRATA"
              ? defaultTheme.colors.silver
              : defaultTheme.colors.gold
          }
        />
        <TextInactiveButton>
          BENEFÍCIO DISPONÍVEL PARA SOLAR {lowActiveMode}
        </TextInactiveButton>
      </InactiveButton>
    );
  }

  if (balance < points || blockedBenefits) {
    return renderInactiveButton();
  }

  return (
    <Button onPress={onPress}>
      <TextButton>RESGATAR BENEFÍCIO</TextButton>
    </Button>
  );
};

export default RedemptionBenefit;
