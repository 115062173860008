import React from 'react';
import { Platform } from 'react-native';
import styled  from 'styled-components/native';

// background-color: ${props => props.theme["_W"].colors.primary};
export const MainCotainer = styled.View`
  /* flex: 1; */
  /* height: ${props=> props.theme.ScreenHeight}; */
  flex: 1;
  display: flex;
  flex-direction: column;
  color: ${props=> props.theme.colors.text};
  background-color: ${props=> props.theme.colors.background};
  `;
// background-color: ${DefaultTheme.colors.secundary};
