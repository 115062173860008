import React, { useContext, useState, useEffect } from "react";
import { Platform, ScrollView, ActivityIndicator, View } from "react-native";
import AuthContext from "../../context";
import { useQuery } from "react-query";
import { getSpecificBenefit } from "../../services/get";
import BackWithPoints from "../../components/backWithPoints";
import RedemptionBenefit from "../../components/buttons/redemptionBenefit";
import ModalAlert from "../../components/modalAlert";

import { MainCotainer } from "../../styles/containers/main/mainContainer";
import { Wrapper } from "../../styles/containers/main/wrapper";
import { SecondContentAligned } from "../../styles/containers/second/secondContentAligned";
import { SecondMainCotainer } from "../../styles/containers/second/secondMainContainer";
import { SecondWrapper } from "../../styles/containers/second/secondWrapper";
import { ContentAligned } from "../../styles/containers/main/contentAligned";
import { defaultTheme } from "../../styles/themes/default";
import { BigTextBold } from "../../styles/text/boldText/bigTextBold";
import { MediumTextBold } from "../../styles/text/boldText/mediumTextBold";
import { BigText } from "../../styles/text/texts/bigText";
import { Container, ImageBenefit, BenefitPoints, Loading, LoadingContainer } from "./styles";
import DefaultButton from "../../components/buttons/defaultButton/index";
import { ContentCenter } from "../../styles/containers/main/contentCenter";
import sendMail from "../../services/sendEmail";
import AuthRequest from "../../config/AuthRequest";
import GlobalMessages from "../../config/Messages";
import thousandsSeparator from "../utils/thousandsSeparator";
import { redemptionBenefit } from "../../services/benefits/index";
import { useLoyaltyUser } from "../../hooks/useLoyaltyUser";

const SpecificBenefit: React.FC<any> = ({ navigation, route }: any) => {
  const {
    modality,
    userId,
    givenName,
    familyName,
    cpf,
    fidelityToken,
    loyaltyUser
  } = useContext(AuthContext);
  const { benefitID, benefitCategory, imageURI } = route?.params;
  const [textConfirmRedemption, setTextConfirmRedemption] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [benefitLoading, setBenefitLoading] = useState(false)
  const { getUpdateLoyaltyUser } = useLoyaltyUser()
  const { data, isError, isLoading, status } = useQuery(
    ["specific-benefit", benefitID, fidelityToken],
    getSpecificBenefit
  );

  var modalitys = [];
  var benefitPoints = 0;
  var hasInModality;
  var lowActiveMode;

  if (isError) {
    navigation.navigate("Inicio", {
      error: "Erro ao buscar informações do benefício.",
    });
  }
  if (!isLoading && !isError) {
    modalitys = data?.data.modalidades.map((modality: any) => {
      return "SOLAR " + modality.nome;
    });
    let isBronze = data?.data.modalidades.findIndex(
      (element: any) => element.nome == "BRONZE"
    );
    let isSilver = data?.data.modalidades.findIndex(
      (element: any) => element.nome == "PRATA"
    );
    let isGold = data?.data.modalidades.findIndex(
      (element: any) => element.nome == "OURO"
    );

    data?.data.modalidades.forEach((elem: any) => {
      if (modality === "BRONZE") {
        benefitPoints =
          isBronze >= 0
            ? data?.data?.modalidades[isBronze]?.pontos
            : isSilver >= 0
            ? data?.data?.modalidades[isSilver]?.pontos
            : data?.data?.modalidades[isGold]?.pontos;
        hasInModality = isBronze >= 0 ? true : false;
      } else if (modality === "PRATA") {
        benefitPoints =
          isSilver >= 0
            ? data?.data?.modalidades[isSilver]?.pontos
            : data?.data?.modalidades[isGold]?.pontos;
        hasInModality = isSilver >= 0 ? true : false;
      } else {
        benefitPoints = data?.data?.modalidades[isGold]?.pontos;
        hasInModality = true;
      }
    });
    lowActiveMode = isBronze >= 0 ? "BRONZE" : isSilver >= 0 ? "PRATA" : "OURO";
  }

  useEffect(() => {
    setTextConfirmRedemption(
      `Você quer resgatar o benefício ${data?.data?.nome} no valor de ${benefitPoints} ${GlobalMessages.pts}?`
    );
  }, [data, benefitPoints]);

  const sendMailFnc = () => {
    sendMail(
      fidelityToken,
      `Resgate de benefício ${givenName} ${familyName}`,
      `<p> Nome: ${givenName} ${familyName} </p> <br/> <p>CPF: ${cpf} </p> <br/> <p> Beneficio Resgatado: ${data?.data?.nome} </p> <br/>  <p> Pontos: ${benefitPoints} </p>`
    );
  };

  const onBenefitConfirmPress = async () => {
    setModalVisible(false);
    setBenefitLoading(true)
    const redeemBenefit:any = await redemptionBenefit(
      userId!,
      benefitID,
      fidelityToken,
    );

    if (redeemBenefit.status !== 200) {
      setBenefitLoading(false)
      setModalVisible(false);
      navigation.navigate("ErrorScreen", {
        errorMessage: "OPS, Algo deu errado. Tente mais tarde.",
      });
      return;
    }
    getUpdateLoyaltyUser()
    setBenefitLoading(false)
    sendMailFnc()
   
    navigation.navigate("Voucher", {
      benefitID: redeemBenefit.data.beneficio.id,
      vouchers: redeemBenefit.data.vouchers,
      voucherId: redeemBenefit.data.id,
      imageURI,
    });
  };

  const benefitText =
    route?.params?.modality === "All"
      ? "SOLAR " + lowActiveMode
      : route?.params?.modality;
  const pageTitle = !!benefitCategory
    ? `${benefitText} - ${benefitCategory}`
    : benefitText;

  const render = (lowActiveMode: any) => {
    return (
      <>
        <ScrollView showsVerticalScrollIndicator={false}>
          <MainCotainer
            style={
              Platform.OS === "web" ? { minHeight: 0 } : { minHeight: 280 }
            }
          >
            <Wrapper>
              <ContentAligned>
                <Container>
                  {/* <BigTextBold style={{color: defaultTheme.colors.gray}}>SOLAR {isError ? '' : lowActiveMode} / {data?.data?.categoria?.nome}</BigTextBold> */}
                  <BigTextBold
                    style={{ color: defaultTheme.colors.gray }}
                  >{`${pageTitle}`}</BigTextBold>
                  <BigTextBold style={{ marginTop: 20 }}>
                    {data?.data?.nome}
                  </BigTextBold>
                  <ImageBenefit
                    style={{ width: "100%", height: 218 }}
                    source={{ uri: imageURI }}
                  />
                  <BenefitPoints>
                    <MediumTextBold>Valor do benefício:</MediumTextBold>
                    <MediumTextBold>
                      {thousandsSeparator(benefitPoints, ".")}{" "}
                      {GlobalMessages.pts}
                    </MediumTextBold>
                  </BenefitPoints>

                  <RedemptionBenefit
                    lowActiveMode={lowActiveMode}
                    modality={modality}
                    points={benefitPoints}
                    onPress={() => setModalVisible(true)}
                  />
                </Container>
              </ContentAligned>
            </Wrapper>
            <Wrapper
              style={{
                backgroundColor: defaultTheme.colors.secondBackground,
                marginTop: 20,
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              <ContentAligned>
                <BigTextBold>SOBRE</BigTextBold>
                <BigText>{data?.data?.descricao}</BigText>
                <BigTextBold style={{ marginTop: 20 }}>REGRAS</BigTextBold>
                <BigText>{data?.data?.regrasUso}</BigText>
              </ContentAligned>
            </Wrapper>
          </MainCotainer>
        </ScrollView>
      </>
    );
  };

  const renderLoading = () => {
    return (
      <>
        <ScrollView showsVerticalScrollIndicator={false}>
          <MainCotainer
            style={
              Platform.OS === "web" ? { minHeight: 0 } : { minHeight: 280 }
            }
          >
            <Wrapper>
              <ContentAligned>
                <Container>
                  <Loading
                    primaryColor="#ffffff"
                    secondaryColor="lightgray"
                    duration={5000}
                    style={{
                      height: 20,
                      marginTop: 20,
                    }}
                  />
                  <Loading
                    primaryColor="#ffffff"
                    secondaryColor="lightgray"
                    duration={5000}
                    style={{
                      height: 20,
                      marginTop: 20,
                    }}
                  />
                  <Loading
                    primaryColor="#ffffff"
                    secondaryColor="lightgray"
                    duration={5000}
                    style={{
                      height: 218,
                      marginTop: 20,
                    }}
                  />
                  <Loading
                    primaryColor="#ffffff"
                    secondaryColor="lightgray"
                    duration={5000}
                    style={{
                      height: 20,
                      marginTop: 20,
                    }}
                  />

                  <Loading
                    primaryColor="#ffffff"
                    secondaryColor="lightgray"
                    duration={5000}
                    style={{
                      height: 50,
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  />
                </Container>
              </ContentAligned>
            </Wrapper>
            <Wrapper
              style={{
                backgroundColor: defaultTheme.colors.secondBackground,
                marginTop: 20,
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              <ContentAligned>
                <Loading
                  primaryColor="#ffffff"
                  secondaryColor="lightgray"
                  duration={5000}
                  style={{
                    height: 20,
                    marginTop: 20,
                  }}
                />
                <Loading
                  primaryColor="#ffffff"
                  secondaryColor="lightgray"
                  duration={5000}
                  style={{
                    height: 100,
                    marginTop: 20,
                  }}
                />
                <Loading
                  primaryColor="#ffffff"
                  secondaryColor="lightgray"
                  duration={5000}
                  style={{
                    height: 20,
                    marginTop: 20,
                  }}
                />
                <Loading
                  primaryColor="#ffffff"
                  secondaryColor="lightgray"
                  duration={5000}
                  style={{
                    height: 100,
                    marginTop: 20,
                  }}
                />
              </ContentAligned>
            </Wrapper>
          </MainCotainer>
        </ScrollView>
      </>
    );
  };

  return (
    <>
      <SecondMainCotainer>
        <SecondWrapper>
          <SecondContentAligned>
            <BackWithPoints
              text="BENEFÍCIOS"
              title="MEUS PONTOS"
              points={loyaltyUser.saldo}
              size="big"
              redirect="back"
              navigation={navigation}
            />
          </SecondContentAligned>
        </SecondWrapper>
      </SecondMainCotainer>

      {isLoading ? renderLoading() : render(lowActiveMode)}

      <ModalAlert
        text={textConfirmRedemption}
        textButtonConfirm="CONFIRMAR"
        textButtonCancel="CANCELAR"
        isVisible={modalVisible}
        onPressConfirm={onBenefitConfirmPress}
        onPressCancel={() => setModalVisible(false)}
      />
      {benefitLoading && <LoadingContainer>
        <ActivityIndicator size="large" color="#FFF"></ActivityIndicator>
      </LoadingContainer>}
    </>
  );
};

export default SpecificBenefit;
