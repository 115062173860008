import AuthRequest from '../config/AuthRequest';
import axios from 'axios';
import GlobalMessages from '../config/Messages';


const addClient = async (data:any, token:any, signup = false)=>{

  try{
    // ${AuthRequest.fidelityApi}
    const addClientReq = await axios.post(`${AuthRequest.fidelityApi}/sensedia/addClient`,{
        ...data,
        signup
    },
    {
        headers:{
            "Content-Type": "application/json",
            "application": AuthRequest.Application,
            "Authorization": `Bearer ${token}`,
        }
    })

    return {
        response:{
            status:201,
            data:{
                mensagem: GlobalMessages.signupSucess

            }
        },
        addClientReq}
}catch(e:any){
    if(e.message ==='Network Error' || e.message === "Request failed with status code 400" ||e.response.status == 400 ){
        return {
            response:{
                data:{
                    mensagem: 'Algo deu errado no Cadastro, Tente novamente'
                }
            }
        }
    }
    else if(e.response.status === 409 || e.response.status === 422){
        return {
            response:{
                status:409,
                data:{
                    mensagem: GlobalMessages.signupExistingUser
                }
            }
        } 
    }

    return {
        response:{
            data:{
                mensagem: 'Ocorreu um erro inesperado, Tente novamente'
            }
        }
    }
}

    

}

export default addClient