import React, { useState } from 'react';
import moment from 'moment';
import { View, Text, TouchableOpacity, Platform } from 'react-native';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import { MediumTextBold } from '../../styles/text/boldText/mediumTextBold';
import { DateFilterButton, ViewDatePicker } from './style';
import { MediumText } from '../../styles/text/texts/mediumText';


export const DatePickerFilter:React.FC<any> = (onFilter: any) => {

    const [startDate, setStartDate] = useState(new Date())
    const [finalDate, setFinalDate] = useState(new Date())

    const [showStart, setShowStart] = useState(false);
    const [showFinal, setShowFinal] = useState(false);

    const handleClick = () : void => {
      onFilter.handleDatePickerFilter({  startDate: moment(startDate).format("DD/MM/YYYY"), finalDate: moment(finalDate).format("DD/MM/YYYY") });
    }

    return(
        <ViewDatePicker>
          <MediumTextBold>FILTRAR POR PERIODO</MediumTextBold>
          <View style={{marginTop:10, display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
            <View>
              <MediumTextBold>De</MediumTextBold>
              <TouchableOpacity onPress={()=>setShowStart(true)}>
                <Text style={{
                  borderColor: '#000',
                  borderWidth: 1,
                  padding: 5
                }}>{moment(startDate).format("DD/MM/YYYY")}</Text>
              </TouchableOpacity>
                <DateTimePickerModal
                  isVisible={showStart}
                  value={startDate}
                  mode={'date'}
                  display={Platform.OS === 'ios' ? 'spinner' : 'default'}
                  maximumDate={new Date()}
                  onConfirm={(selectedDate) => { 
                    const currentDate = selectedDate;
                    setShowStart(false);
                    setStartDate(currentDate);
                  }}
                  onCancel={()=>setShowStart(false)}
                />
            </View>

            <View>
                <MediumTextBold>Até</MediumTextBold>
                <TouchableOpacity onPress={()=>setShowFinal(true)}>
                <Text style={{
                  borderColor: '#000',
                  borderWidth: 1,
                  padding: 5
                }}>{moment(finalDate).format("DD/MM/YYYY")}</Text>
              </TouchableOpacity>
              <DateTimePickerModal
                  isVisible={showFinal}
                  value={finalDate}
                  mode={'date'}
                  display={Platform.OS === 'ios' ? 'spinner' : 'default'}
                  maximumDate={new Date()}
                  onConfirm={(selectedDate) => { 
                    const currentDate = selectedDate;
                    setShowFinal(false);
                    setFinalDate(currentDate);
                  }}
                  onCancel={()=>setShowFinal(false)}
                />
            </View>


            
            <DateFilterButton style={{top: -10}} onPress={()=>handleClick()}>
              <MediumText style={{color:'white'}}>Buscar</MediumText>
            </DateFilterButton>

          </View>
        </ViewDatePicker>
    )

}

export default DatePickerFilter;   

