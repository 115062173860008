import React, { useState, useEffect, useContext } from "react";
import { View, Platform, Image, Pressable, Dimensions } from "react-native";
import * as Linking from "expo-linking";
import * as WebBrowser from "expo-web-browser";
import { ScrollView } from "react-native-gesture-handler";
import jwt from "jwt-decode";
import { MainCotainer } from "../../styles/containers/main/mainContainer";
import { Wrapper } from "../../styles/containers/main/wrapper";
import ImageCarousel from "../../components/carousel/imageCarousel/";
import { ContentAligned } from "../../styles/containers/main/contentAligned";
import Celular from "../../../assets/Celular.png";
import qrCode from "../../../assets/qrCode.png";
import parceiro1 from "../../../assets/parceiro1.png";
import parceiro2 from "../../../assets/parceiro2.png";
import parceiro3 from "../../../assets/parceiro3.png";
import TextCarousel from "../../components/carousel/textCarousel";
import DefaultButton from "../../components/buttons/defaultButton";
import { ButtonsWrapper } from "../../styles/containers/buttonsWrapper";
import Profits from "../../components/profits";
import { SecondMainCotainer } from "../../styles/containers/second/secondMainContainer";
import TierProfits from "../../components/tierProfits";
import { SecondWrapper } from "../../styles/containers/second/secondWrapper";
import { SecondContentAligned } from "../../styles/containers/second/secondContentAligned";
import AuthContext from "../../context";
import { BigTextBold } from "../../styles/text/boldText/bigTextBold";
import { ModalAlert } from "../../components/modalAlert/index";
import { getBenefits } from "../../services/get";
import fidelityGetToken from "../../services/fidelityGetToken";
import privacyPolicy from "../../services/privacyPolicy";
import { MediumTextBold } from "../../styles/text/boldText/mediumTextBold";
// import {SimplePaginationDot} from './component';

// marginTop: StatusBar.currentHeight || 0,

interface decodeInterface {
  family_name: any;
  extension_Telefone: any;
  extension_cpf: any;
  given_name: any;
}

const tiersBronze = [
  {
    title: "Descontos",
    promotion: ["Lojas", "Gastronomia", "Hospedagem"],
  },
  {
    title: "Troca de Pontos:",
    promotion: ["Ingressos para eventos", "Gastronomia", "Doações"],
  },
];
const tiersSilver = [
  {
    title: "Descontos",
    promotion: ["Lojas", "Gastronomia", "Hospedagem"],
  },
  {
    title: "Troca de Pontos:",
    promotion: [
      "Ingressos VIPs",
      "Experiências",
      "Doações",
      "Personal Shopper",
      "Isenção de Estacionamento",
    ],
  },
  {
    title: "Cortesia",
    promotion: ["Experiências no lounge SOLAR"],
  },
];
const tiersGold = [
  {
    title: "Descontos",
    promotion: ["Lojas", "Gastronomia", "Hospedagens Premium"],
  },
  {
    title: "Troca de Pontos:",
    promotion: [
      "Ingressos VIPs",
      "Experiências",
      "Doações",
      "Personal Shopper",
      "Pacotes de viagens premium",
    ],
  },
  {
    title: "Cortesia",
    promotion: [
      "Experiências no lounge SOLAR",
      "Ingressos VIPs",
      "Personal Shopper",
      "Gifts personalizados",
      "Isenção de estacionamento",
    ],
  },
];
// import { Container } from './styles';
const data = [
  {
    key: 0,
    id: 0,
    title: "Vila da Santa",
    body: "Ut tincidunt tincidunt erat. Sed cursus turpis vitae tortor. Quisque malesuada placerat nisl. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.",
    imgUrl: parceiro1,
  },
  {
    id: 1,
    key: 1,
    title: "Copacabana Palace",
    body: "Aenean ut eros et nisl sagittis vestibulum. Donec posuere vulputate arcu. Proin faucibus arcu quis ante. Curabitur at lacus ac velit ornare lobortis. ",
    // imgUrl: "https://picsum.photos/id/10/200/300",
    // imgUrl: "https://picsum.photos/id/10/200/300",
    imgUrl: parceiro2,
  },
  {
    key: 2,
    id: 2,
    title: "Cortés",
    body: "Phasellus ullamcorper ipsum rutrum nunc. Nullam quis ante. Etiam ultricies nisi vel augue. Aenean tellus metus, bibendum sed, posuere ac, mattis non, nunc.",
    // imgUrl: "https://picsum.photos/id/12/200/300",
    imgUrl: parceiro3,
  },
];

/*
const profitData: any[] = [
  {
    title: 'Gastronomia',
    data:[
      {
        name: 'Pizzaria',
        imgUrl:profit,
        imgName: 'profit.png'
      },
      {
        name: 'Restaurante',
        imgUrl:Gastronomia2,
        imgName: 'Gastronomia2.png'
      },
      {
        name: 'Lanches',
        imgUrl:gastronomia3,
        imgName: 'gastronomia3.png' 
      }
    ]
  },
  {
    title: 'Saúde e Beleza',
    data:[
      {
        name: 'Salão Ouro',
        imgUrl:beleza1,
        imgName: 'beleza1.png'
      },
      {
        name: 'Maquiagens',
        imgUrl:beleza2,
        imgName: 'beleza2.png'
      },
      {
        name: 'beleza',
        imgUrl:beleza3,
        imgName: 'beleza3.png'
      }
    ]
  },
    {
    title: 'Hospedagem',
    data:[
      {
        name: 'Copacabana',
        imgUrl:hospedagem1,
        imgName: 'hospedagem1.png'
      },
      {
        name: 'Hotel',
        imgUrl:hospedagem2,
        imgName: 'hospedagem2.png'
      },
      {
        name: 'Praia',
        imgUrl:hospedagem3,
        imgName: 'hospedagem3.png'
      }
    ]
  }
] */

const textData = [
  {
    id: 0,
    title: "O PROGRAMA",
    desc: [
      "O Solar Shopping Leblon é um programa de relacionamento que entrega aquilo que você procura: benefícios verdadeiros através de um sistema de pontuação prático, ágil e transparente.",
      "Como cliente Solar, você terá acesso a descontos, serviços, brindes, eventos e experiências customizadas, pensandas para agradar todos os gostos.",
      "A cada R$ 1 em compras, você acumula 1 ponto para aproveitar os benefícios.",
    ],
  },
  {
    id: 1,
    title: "SOLAR – MODALIDADES",
    desc: [
      "Clientes Bronze: Clientes que se cadastraram no Solar e tiverem pontuação válida no programa entre 1 e 49.999 (quarenta e nove mil novecentos e noventa e nove) pontos em um período de até 12 meses.",

      "Clientes Prata: Clientes que se cadastraram no Solar e tiverem pontuação válida no programa entre 50.000 (cinquenta mil) e 99.999 (noventa e nove mil novecentos e noventa e nove) pontos em um período de até 12 meses.",

      "Clientes Ouro: Clientes que se cadastraram no Solar e tiverem pontuação válida no programa igual ou superior a 100.000 (cem mil) pontos em um período de até 12 meses.",
    ],
  },
];

const PublicHome = (props: any) => {
  const { navigation, route } = props;
  const [selectImageCarouselIndex, setSelectImageCarouselIndex] = useState(0);
  const [selectTextCarouselIndex, setSelectTextCarouselIndex] = useState(0);
  const [testLinking, setTestLinking] = useState({});
  const [redirectData, setRedirectData] = useState({});
  const [profitData, setProfitData] = useState([]);
  const [privacyPolicyState, setPrivacyPolicyState] = useState("");
  const {
    signed,
    cpf,
    givenName,
    familyName,
    signIn,
    setB2CLoginToken,
    setCpf,
    setGivenName,
    setFamilyName,
    setTelephoneNumber,
  } = useContext(AuthContext);
  const [redirectToWebLogin, setRedirectToWebLogin] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalText, setModalText] = useState("");
  const {
    fidelityToken,
    setFidelityToken,
  } = React.useContext(AuthContext);

  const webLogin = async (token: any) => {
    const decode: decodeInterface = await jwt(token);
    if (!decode) return;
    signIn();
    setB2CLoginToken(token);
    setCpf(decode?.extension_cpf);
    setGivenName(decode?.given_name);
    setFamilyName(decode?.family_name);
    setTelephoneNumber(decode?.extension_Telefone);
  };

  const getTokenReq = async () => {
    let fidelityTokenApi: any = await fidelityGetToken();
    setFidelityToken(fidelityTokenApi.token);
  };

  const formatbenefitsQuery = async () => {
    let _data: any = [];

    const benefitsQuery: any = await getBenefits({
      queryKey: [null, fidelityToken],
    });

    if (benefitsQuery.error?.response.status) return null;

    benefitsQuery.data.map((elem: any) => {
      if (elem.destaque === "true") {
        const check = _data.find(
          (item: any) => item.title === elem.categoria.nome
        );
        if (!check) {
          _data.push({
            title: elem.categoria.nome,
            data: [],
          });
        }

        _data.map((item: any, index: any) => {
          if (item.title === elem.categoria.nome) {
            item.data.push({
              name: elem.loja.nome,
              imgUrl: elem.imagem,
              imgName: elem.nome,
            });
          }
        });
      }
    });
    //console.log(_data);
    setProfitData(_data);
  };

  useEffect(() => {
    (async () => {
      await getTokenReq();
      await formatbenefitsQuery();
      const getPolicies = await privacyPolicy(fidelityToken);
      setPrivacyPolicyState(getPolicies[0]?.conteudo);
    })();

    if (
      typeof route.params !== "undefined" &&
      route.params.messageAlertShopping
    ) {
      setModalText(
        "Seu cadastro está inativo, por favor se dirija ao shopping para ativa-lo!"
      );
      setModalVisible(true);
    }

    if (
      typeof route.params !== "undefined" &&
      route.params.messageAlertSignup
    ) {
      setModalText(
        "Cadastro enviado com sucesso. Em breve você receberá o e-mail de confirmação."
      );
      setModalVisible(true);
    }

    /**
     * ----------------------------- Login Web ------------------------------
     * To do:
     * No futuro, seria legal criar um estado ou utilizar um estado global já existente para
     * quando a pagina web cair aqui, ela ser transferida para o signin sem fazer o redirecionamento
     * e essa logica ser transferida para lá, assim o codigo ficará mais organizado.
     *
     */
    if (Platform.OS === "web") {
      const checkIfSigned = localStorage.getItem("signed");
      if (checkIfSigned) {
        const b2cToken = localStorage.getItem("B2CToken");
        webLogin(b2cToken);
      }
      const queryString = window.location.search;
      const [, token] = queryString.split("id_token=");
      if (token) {
        webLogin(token);
      }
    }
    /**
     * -----------------------------------------------------------------------
     */
  }, [route]);

  const _handleRedirect = (event: any) => {
    if (Platform.OS === "ios") {
      WebBrowser.dismissBrowser();
    } else {
      _removeLinkingListener();
    }

    let data = Linking.parse(event.url);
    navigation.navigate("Signin", { token: data.queryParams?.token });
  };

  // const directAccess = () => {
  //   const token = 'token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6InJqUHZSSHZjTzhCNm5NbzAtRzlsRm1lNFNmbkxtaVJKVzh0TTB0WTM4WXcifQ.eyJleHAiOjE2NjA2ODk3MzUsIm5iZiI6MTY2MDY4NjEzNSwidmVyIjoiMS4wIiwiaXNzIjoiaHR0cHM6Ly9hcGlsb2dpbmhtbC5iMmNsb2dpbi5jb20vMGE3NTg5YTMtMTNmZi00OGFkLWE0MzAtZDIyYjBlOGFmNzQ0L3YyLjAvIiwic3ViIjoiOGYxZWEzNmItZThlOC00ZDllLThhMmItZTY3NWE2MzNhOTk0IiwiYXVkIjoiZjk4ZGM0NGQtYmVlMy00MDE4LWFhYjAtYTc2NTVhZGEyNjNlIiwiYWNyIjoiYjJjXzFhX2xvZ2luY2FkYXN0cm8iLCJub25jZSI6ImRlZmF1bHROb25jZSIsImlhdCI6MTY2MDY4NjEzNSwiYXV0aF90aW1lIjoxNjYwNjg2MTM1LCJleHRlbnNpb25fTmFzY2ltZW50byI6IjA0LzA5LzE5NzciLCJleHRlbnNpb25fY3BmIjoiMjY4ODY5MTM4NDAiLCJleHRlbnNpb25fVGVsZWZvbmUiOiIoMTMpOTkxMzgxNTU4Iiwic2lnbkluTmFtZXMuZW1haWxBZGRyZXNzIjoiaGFtaWx0b250eEBnbWFpbC5jb20iLCJnaXZlbl9uYW1lIjoiSGFtaWx0b24iLCJmYW1pbHlfbmFtZSI6IlRlaXhlaXJhIiwiaW5kUGVybWlzc2FvIjoiZmFsc2UiLCJuZXdVc2VyIjpmYWxzZSwiaXNGb3Jnb3RQYXNzd29yZCI6ZmFsc2UsInRpZCI6IjBhNzU4OWEzLTEzZmYtNDhhZC1hNDMwLWQyMmIwZThhZjc0NCJ9.HYjLdzHvAE2gZddXd4n6f9L6Rsup_AkpaObuhvprpz6zgn0jUNjT6AjpSPz9hpsXtvrcZL4U8c4JfXWgtsxSQLbwm6BjEYAS4QnzwZ4SQnVtzbDOtsEhixAZnNTQ1IZ-2Yph2c8kU5tOFQtD1d91obE0yXP-yC7Knwy27xRZ0UJAOmQfo97CvchuMoZw3rOU058TaRMx_erlYYWbel7zJqK6xESvB6p2j8g2MPqZDMRrsiCsmB-dHB91X57udhOFcwMWaMXnY3IGeZGsOswy3l6eXdkKf1Z6TmSX-Lm5LjDq7ZeqJG8rqO5_DwfLSucVmmF5KFxPWxG1MzwUPtrIhA'
  //   const tokenJO = 'token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6InJqUHZSSHZjTzhCNm5NbzAtRzlsRm1lNFNmbkxtaVJKVzh0TTB0WTM4WXcifQ.eyJleHAiOjE2NjA2OTA2MzcsIm5iZiI6MTY2MDY4NzAzNywidmVyIjoiMS4wIiwiaXNzIjoiaHR0cHM6Ly9hcGlsb2dpbmhtbC5iMmNsb2dpbi5jb20vMGE3NTg5YTMtMTNmZi00OGFkLWE0MzAtZDIyYjBlOGFmNzQ0L3YyLjAvIiwic3ViIjoiZTE5NDdmYjItYjVlMS00NjgyLWE4YjEtZGI5ZmI2MDdjMTBmIiwiYXVkIjoiZjk4ZGM0NGQtYmVlMy00MDE4LWFhYjAtYTc2NTVhZGEyNjNlIiwiYWNyIjoiYjJjXzFhX2xvZ2luY2FkYXN0cm8iLCJub25jZSI6ImRlZmF1bHROb25jZSIsImlhdCI6MTY2MDY4NzAzNywiYXV0aF90aW1lIjoxNjYwNjg3MDM3LCJleHRlbnNpb25fTmFzY2ltZW50byI6IjIxLzAyLzE5ODciLCJleHRlbnNpb25fY3BmIjoiMDgxMDI1Njc2MjciLCJleHRlbnNpb25fVGVsZWZvbmUiOiIoMTIpOTgxMjE0NDM4Iiwic2lnbkluTmFtZXMuZW1haWxBZGRyZXNzIjoiam9zZS5vdGF2aW9AaXRsZWFuLmNvbS5iciIsImdpdmVuX25hbWUiOiJKb3PDqSBPdMOhdmlvIiwiZmFtaWx5X25hbWUiOiJTYW50b3MiLCJpbmRQZXJtaXNzYW8iOiJmYWxzZSIsIm5ld1VzZXIiOmZhbHNlLCJpc0ZvcmdvdFBhc3N3b3JkIjpmYWxzZSwidGlkIjoiMGE3NTg5YTMtMTNmZi00OGFkLWE0MzAtZDIyYjBlOGFmNzQ0In0.m3r3AEVZWIWbGIHGtVkmyzd29ehlyeStSBZsgcRtdKIL0rXScJrvtKMP6s-hIMbmbfSy-M0eQ_ZPh7oiEMOkjPYOXsPwsRQvcwwRmBz0K96orIJ5r1Dpo2lWgehcKAbzBKTx4IjPYghZvfdFhoYrYMNxHC1Brt8c3iYxbkN5Zns1Sy7MuwdlGG8ylxESYpXM-m61d4n6qrdGp34LykwOCdutm_BnI3yv_ehQhzYcCzqdHKc1M6i4ej7tMMutA8dVzIaMyNhf__2gr0cWrLLEnMXIjlCVcILyZv_fSdGN1ZvL6d0Y-DBYrhM9igk6fFaHx3b1B9GnEssoQmBUoM4AAg'
  //   const tokenTL = 'token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6InJqUHZSSHZjTzhCNm5NbzAtRzlsRm1lNFNmbkxtaVJKVzh0TTB0WTM4WXcifQ.eyJleHAiOjE2NjA5MjQ3ODMsIm5iZiI6MTY2MDkyMTE4MywidmVyIjoiMS4wIiwiaXNzIjoiaHR0cHM6Ly9hcGlsb2dpbmhtbC5iMmNsb2dpbi5jb20vMGE3NTg5YTMtMTNmZi00OGFkLWE0MzAtZDIyYjBlOGFmNzQ0L3YyLjAvIiwic3ViIjoiMTRiNWIwNmUtNjA1ZS00MTM5LWJiZjctNDUyODhhOWNhNGZhIiwiYXVkIjoiZjk4ZGM0NGQtYmVlMy00MDE4LWFhYjAtYTc2NTVhZGEyNjNlIiwiYWNyIjoiYjJjXzFhX2xvZ2luY2FkYXN0cm8iLCJub25jZSI6ImRlZmF1bHROb25jZSIsImlhdCI6MTY2MDkyMTE4MywiYXV0aF90aW1lIjoxNjYwOTIxMTgzLCJleHRlbnNpb25fTmFzY2ltZW50byI6IjExLzA3LzE5ODAiLCJleHRlbnNpb25fY3BmIjoiMDg2NzcwOTI3ODEiLCJleHRlbnNpb25fVGVsZWZvbmUiOiIoMjEpOTgxODI4ODI4Iiwic2lnbkluTmFtZXMuZW1haWxBZGRyZXNzIjoidGFsaXR0YXNAZ21haWwuY29tIiwiZ2l2ZW5fbmFtZSI6IlRhbGl0dGEiLCJmYW1pbHlfbmFtZSI6IkZhbGJvIiwiaW5kUGVybWlzc2FvIjoiZmFsc2UiLCJuZXdVc2VyIjpmYWxzZSwiaXNGb3Jnb3RQYXNzd29yZCI6ZmFsc2UsInRpZCI6IjBhNzU4OWEzLTEzZmYtNDhhZC1hNDMwLWQyMmIwZThhZjc0NCJ9.hjXj2Pz7xItBD9hgrePuh4QL7-F33B9YOjgbXuyPfJ6yqoJHtT27JOVF5bfcQePLnqV-Z5nUsHnotiOEK6V-PRKK7Bfhn9v7HJKWPt24oq7dV__meT-DDuIl2xey61NSjF_XXf_LMs52Z5QrrKOm05YYsuntJ1YYS5OabiTQkksqX8YHjSLSUAHqP5sCvwZaNkdYCHfLYBN-kW4FgHYNvILhBJkfPUAukxIGDQWcP-MS_Q6VXGBeR_IuT-aEDRHrZD1XW5O4vxm-Lm4SXEH-5fum2vnfnkxrh0KfsC5k3sw-P1mmFE77obQhKekx1IjVL-SoynjW3eixppjG2HQEjA'
  //   const tokenPK = 'token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6InJqUHZSSHZjTzhCNm5NbzAtRzlsRm1lNFNmbkxtaVJKVzh0TTB0WTM4WXcifQ.eyJleHAiOjE2NjEyMTQzMTEsIm5iZiI6MTY2MTIxMDcxMSwidmVyIjoiMS4wIiwiaXNzIjoiaHR0cHM6Ly9hcGlsb2dpbmhtbC5iMmNsb2dpbi5jb20vMGE3NTg5YTMtMTNmZi00OGFkLWE0MzAtZDIyYjBlOGFmNzQ0L3YyLjAvIiwic3ViIjoiNWRiOTJmOGUtMDg0My00NjU0LWEwNjQtZmY5MmM0NmNiMGU3IiwiYXVkIjoiZjk4ZGM0NGQtYmVlMy00MDE4LWFhYjAtYTc2NTVhZGEyNjNlIiwiYWNyIjoiYjJjXzFhX2xvZ2luY2FkYXN0cm8iLCJub25jZSI6ImRlZmF1bHROb25jZSIsImlhdCI6MTY2MTIxMDcxMSwiYXV0aF90aW1lIjoxNjYxMjEwNzExLCJuZXdVc2VyIjp0cnVlLCJnaXZlbl9uYW1lIjoiUGVkcm8gS2FpcXVlIiwiZmFtaWx5X25hbWUiOiJOb3ZhZXMiLCJleHRlbnNpb25fTmFzY2ltZW50byI6IjAyLzA3LzE5ODkiLCJleHRlbnNpb25fY3BmIjoiMDMyMTcwNzM2MjIiLCJleHRlbnNpb25fVGVsZWZvbmUiOiIoNDgpOTg1MTQxODgzIiwiaW5kUGVybWlzc2FvIjoidHJ1ZSIsInNpZ25Jbk5hbWVzLmVtYWlsQWRkcmVzcyI6ImttcHN3bnZ0c2h3cXJocmpsakBidmhyay5jb20iLCJpc0ZvcmdvdFBhc3N3b3JkIjpmYWxzZSwidGlkIjoiMGE3NTg5YTMtMTNmZi00OGFkLWE0MzAtZDIyYjBlOGFmNzQ0In0.bUMO4LnM2fKwfTGvBuoOhrSPfLivk66BrIHm13i9yIW2RFIgoTmhPHUAFCdLPnLdfd3Gh5UXg6XGn4oUoarRf7YMKobHObIoYj_VKXnyyszy-bJw8TaAaKZDpEUgXaYpL4rt6gpkcLZthKMlMOfQMv9sF_fpTkv_AmijPjbSZ5jg70cd6vVeZC_sx5OtdTWITfXKs4akZo4nkxscE09p_qgCi6ZSpScPMV9fReGrQ96-aMbisrTBZ6vKWgmP0z0sp4LqNzQgXcr8BkUQq2Z2pbbw9YjG89GkHI4WCJ_JBYqIj1fuqtwIfbygPAKrwpScUdGRzZckTdP3MmwLSGzKQw'
  //   const tokenBENTO = '?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6InJqUHZSSHZjTzhCNm5NbzAtRzlsRm1lNFNmbkxtaVJKVzh0TTB0WTM4WXcifQ.eyJleHAiOjE2NjEzOTA1NTgsIm5iZiI6MTY2MTM4Njk1OCwidmVyIjoiMS4wIiwiaXNzIjoiaHR0cHM6Ly9hcGlsb2dpbmhtbC5iMmNsb2dpbi5jb20vMGE3NTg5YTMtMTNmZi00OGFkLWE0MzAtZDIyYjBlOGFmNzQ0L3YyLjAvIiwic3ViIjoiN2JiZGMzOWMtZjAxNy00ZmIyLTk1MTYtMzBkYWIzOTJiMTVlIiwiYXVkIjoiZjk4ZGM0NGQtYmVlMy00MDE4LWFhYjAtYTc2NTVhZGEyNjNlIiwiYWNyIjoiYjJjXzFhX2xvZ2luY2FkYXN0cm8iLCJub25jZSI6ImRlZmF1bHROb25jZSIsImlhdCI6MTY2MTM4Njk1OCwiYXV0aF90aW1lIjoxNjYxMzg2OTU4LCJuZXdVc2VyIjp0cnVlLCJnaXZlbl9uYW1lIjoiQmVudG8gUnlhbiBKdWFuIiwiZmFtaWx5X25hbWUiOiJDdW5oYSIsImV4dGVuc2lvbl9OYXNjaW1lbnRvIjoiMTIvMDEvMTk3NiIsImV4dGVuc2lvbl9jcGYiOiIzNjY2Njk2ODcyNyIsImV4dGVuc2lvbl9UZWxlZm9uZSI6Iig2Myk5ODIxNzY2NzEiLCJpbmRQZXJtaXNzYW8iOiJ0cnVlIiwic2lnbkluTmFtZXMuZW1haWxBZGRyZXNzIjoiam9zYW50b3NAdHVhbWFlYXF1ZWxhdXJzYS5jb20iLCJpc0ZvcmdvdFBhc3N3b3JkIjpmYWxzZSwidGlkIjoiMGE3NTg5YTMtMTNmZi00OGFkLWE0MzAtZDIyYjBlOGFmNzQ0In0.YeIJNTaICMyz05M07xcCHbZb1LveMD-Ga6l5v2RjCpdNkW65ddAlgEKXUGkTp2BJNcEbrK5SlF4gvsN0TTGbNT0_HMp27x6M2Uegebk04zAV7-e7-OWbwJYhtfj9ecqAWDLWaaRNyt-AliCsoERb3sVHeyujtVc9cAGNOz6oZHbymbkvdALEybT_rjDQoaS2ZIUn2OIlMpj8zpZYQG7CE2Wg9_EFWdtBJmFvoKMLZGnUwIZV1oaCGc_w6y1eebUFZpb4lGLDjoZzNaaX3r_KZYwGe5sKV5QbP8B3uTmuX57C2jQhSIOC4HliKPuVik6ewFx3__juto1ebE7QkCs4Ww'
  //   const tokenYAGO = 'token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6InJqUHZSSHZjTzhCNm5NbzAtRzlsRm1lNFNmbkxtaVJKVzh0TTB0WTM4WXcifQ.eyJleHAiOjE2NjEzOTEwODIsIm5iZiI6MTY2MTM4NzQ4MiwidmVyIjoiMS4wIiwiaXNzIjoiaHR0cHM6Ly9hcGlsb2dpbmhtbC5iMmNsb2dpbi5jb20vMGE3NTg5YTMtMTNmZi00OGFkLWE0MzAtZDIyYjBlOGFmNzQ0L3YyLjAvIiwic3ViIjoiODU2MjJjZTMtOTE2My00ZDMzLWFiZjMtNzEzNTM3OWMxNjMzIiwiYXVkIjoiZjk4ZGM0NGQtYmVlMy00MDE4LWFhYjAtYTc2NTVhZGEyNjNlIiwiYWNyIjoiYjJjXzFhX2xvZ2luY2FkYXN0cm8iLCJub25jZSI6ImRlZmF1bHROb25jZSIsImlhdCI6MTY2MTM4NzQ4MiwiYXV0aF90aW1lIjoxNjYxMzg3NDgyLCJuZXdVc2VyIjp0cnVlLCJnaXZlbl9uYW1lIjoiWWFnbyBBcnRodXIgUm9iZXJ0byIsImZhbWlseV9uYW1lIjoiRmFyaWFzIiwiZXh0ZW5zaW9uX05hc2NpbWVudG8iOiIyMS8wMi8xOTg3IiwiZXh0ZW5zaW9uX2NwZiI6IjI0NTg2ODcxNDgyIiwiZXh0ZW5zaW9uX1RlbGVmb25lIjoiKDg2KTk5MTY3OTIyNiIsImluZFBlcm1pc3NhbyI6InRydWUiLCJzaWduSW5OYW1lcy5lbWFpbEFkZHJlc3MiOiJ5YWdvQHR1YW1hZWFxdWVsYXVyc2EuY29tIiwiaXNGb3Jnb3RQYXNzd29yZCI6ZmFsc2UsInRpZCI6IjBhNzU4OWEzLTEzZmYtNDhhZC1hNDMwLWQyMmIwZThhZjc0NCJ9.WehQHslaKvgsehJHP0kZKBvVdB_tApAJTwtKaRrh3PDwm5pScnNZj1OWnXzXFI1Qe9Fj35JyR9BXAqjqf9ZcLCcqy0s_rxFQwduiB8ReKfKSlKkB2OOipLVC5-vtOKor8DxklxZmKclAyvB515P-fSS2zK4qX1H9qcs7uNiV_3r24ssDXeLAKu8RhlD4j7YQWyjapn2ASX4OAws9b8g5Qn2YBBHcjIz6QSPcnx_SuHjWoHqSu0-AQ4SeLhKhxC4FyGOlJHuzZfIsPvXOQcYg2hOzpT9b_TMEHQaNpZCFi5H-ViNr6uce29QMeAayIl-Xw90elKgGfbnZ_UBh8tqZ1g'
  //   const tokenBRYAN = 'token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6InJqUHZSSHZjTzhCNm5NbzAtRzlsRm1lNFNmbkxtaVJKVzh0TTB0WTM4WXcifQ.eyJleHAiOjE2NjEzOTEzNjMsIm5iZiI6MTY2MTM4Nzc2MywidmVyIjoiMS4wIiwiaXNzIjoiaHR0cHM6Ly9hcGlsb2dpbmhtbC5iMmNsb2dpbi5jb20vMGE3NTg5YTMtMTNmZi00OGFkLWE0MzAtZDIyYjBlOGFmNzQ0L3YyLjAvIiwic3ViIjoiMTFhZDE1MWMtNzEzYS00NTE1LWFkZTgtOGNjODg3YjFhN2ExIiwiYXVkIjoiZjk4ZGM0NGQtYmVlMy00MDE4LWFhYjAtYTc2NTVhZGEyNjNlIiwiYWNyIjoiYjJjXzFhX2xvZ2luY2FkYXN0cm8iLCJub25jZSI6ImRlZmF1bHROb25jZSIsImlhdCI6MTY2MTM4Nzc2MywiYXV0aF90aW1lIjoxNjYxMzg3NzYzLCJuZXdVc2VyIjp0cnVlLCJnaXZlbl9uYW1lIjoiQnJ5YW4gUm9iZXJ0byIsImZhbWlseV9uYW1lIjoiU2lsdmEiLCJleHRlbnNpb25fTmFzY2ltZW50byI6IjIwLzA0LzE5NjUiLCJleHRlbnNpb25fY3BmIjoiNTgxMzkxMDY4OTUiLCJleHRlbnNpb25fVGVsZWZvbmUiOiIoOTYpOTk5Mjk3Nzc4IiwiaW5kUGVybWlzc2FvIjoidHJ1ZSIsInNpZ25Jbk5hbWVzLmVtYWlsQWRkcmVzcyI6ImJyeWFuQHR1YW1hZWFxdWVsYXVyc2EuY29tIiwiaXNGb3Jnb3RQYXNzd29yZCI6ZmFsc2UsInRpZCI6IjBhNzU4OWEzLTEzZmYtNDhhZC1hNDMwLWQyMmIwZThhZjc0NCJ9.beEjzRaJ5-rqt1BCUTVWGM7lxsP2uhtBZbniFWmUK02ZTww6gwqt55ZRn8MoEQS1dU-hmewTZO-zKomuN7KQlK7E3pQ4RPEdGyOOa8945wtgsTwKUKXa6el-Ud5ZIqyZ53XiecdZPwx3VmWdpb514gaoazuJNTeh5FAuhf71zros_WwFhG_VrUCxuu9n1bcYcZrl7Z8oLks9kqbsR46SKbRpw1h9S8o8VWno3Mp9G4gPhngW9zEw9vyJ2GWStT9CQv-2BDO2FAeKX3H9JvZUFrpZS1egViD7p0AqnGjJH26rbpSWe3Pqh5IbdVYhRYaWnZiid9Eipotrv9blhL8GoA'
  //   const tokenLUIS = 'token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6InJqUHZSSHZjTzhCNm5NbzAtRzlsRm1lNFNmbkxtaVJKVzh0TTB0WTM4WXcifQ.eyJleHAiOjE2NjEzOTIzMDAsIm5iZiI6MTY2MTM4ODcwMCwidmVyIjoiMS4wIiwiaXNzIjoiaHR0cHM6Ly9hcGlsb2dpbmhtbC5iMmNsb2dpbi5jb20vMGE3NTg5YTMtMTNmZi00OGFkLWE0MzAtZDIyYjBlOGFmNzQ0L3YyLjAvIiwic3ViIjoiNzJiMmU4ZWYtZjMwNC00MWZlLTlhZjMtOTUzZjI2NDBiZTIxIiwiYXVkIjoiZjk4ZGM0NGQtYmVlMy00MDE4LWFhYjAtYTc2NTVhZGEyNjNlIiwiYWNyIjoiYjJjXzFhX2xvZ2luY2FkYXN0cm8iLCJub25jZSI6ImRlZmF1bHROb25jZSIsImlhdCI6MTY2MTM4ODcwMCwiYXV0aF90aW1lIjoxNjYxMzg4NzAwLCJuZXdVc2VyIjp0cnVlLCJnaXZlbl9uYW1lIjoiTHXDrXMgSXNhYWMgQ2F1w6MiLCJmYW1pbHlfbmFtZSI6IlJhbW9zIiwiZXh0ZW5zaW9uX05hc2NpbWVudG8iOiIyMi8wNi8xOTc3IiwiZXh0ZW5zaW9uX2NwZiI6IjE3MDUyMjM2OTkwIiwiZXh0ZW5zaW9uX1RlbGVmb25lIjoiKDg4KTk5OTUxNjkyMCIsImluZFBlcm1pc3NhbyI6InRydWUiLCJzaWduSW5OYW1lcy5lbWFpbEFkZHJlc3MiOiJsdWlzQHR1YW1hZWFxdWVsYXVyc2EuY29tIiwiaXNGb3Jnb3RQYXNzd29yZCI6ZmFsc2UsInRpZCI6IjBhNzU4OWEzLTEzZmYtNDhhZC1hNDMwLWQyMmIwZThhZjc0NCJ9.OwkIsUHBj_VL0amBpIhW1d2B5sJx5fpotnugC3Uz1oKx9h3UP2UccJ10MGoQCGQzoGJxgtq6APK3iqTDVS-sXPAcs5qUpJAGmfe_VCCII3YprxFkhySSj-u8l319tRrlgH-Ntl6-qAt2SFaAScy1dH0fX-5oe7omlwlaQCcsdqGC_gAK7pinsnJ-k5jhR1KoMjpMnCtlb2GgOef2lwBcMG0rlzeNBwUjr8RbWwTUeIxAWf_7k2_EdaPPQUUYX2SUbJAGQWdTOt4TF1dg5Zspnu93xoPfqpgCGmUaxDxvsXQ7VjvuiaMfB3vlK-Rr54H7MXsQ8ROehh0u8ln-j19TuA'
  //   const tokenLEVI = 'token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6InJqUHZSSHZjTzhCNm5NbzAtRzlsRm1lNFNmbkxtaVJKVzh0TTB0WTM4WXcifQ.eyJleHAiOjE2NjEzOTI3ODUsIm5iZiI6MTY2MTM4OTE4NSwidmVyIjoiMS4wIiwiaXNzIjoiaHR0cHM6Ly9hcGlsb2dpbmhtbC5iMmNsb2dpbi5jb20vMGE3NTg5YTMtMTNmZi00OGFkLWE0MzAtZDIyYjBlOGFmNzQ0L3YyLjAvIiwic3ViIjoiZWE0NWFmZjQtNjgwMS00NTc0LWI2YWEtZTEwM2NkNDEwNjliIiwiYXVkIjoiZjk4ZGM0NGQtYmVlMy00MDE4LWFhYjAtYTc2NTVhZGEyNjNlIiwiYWNyIjoiYjJjXzFhX2xvZ2luY2FkYXN0cm8iLCJub25jZSI6ImRlZmF1bHROb25jZSIsImlhdCI6MTY2MTM4OTE4NSwiYXV0aF90aW1lIjoxNjYxMzg5MTg1LCJuZXdVc2VyIjp0cnVlLCJnaXZlbl9uYW1lIjoiTGV2aSBNYXJ0aW4iLCJmYW1pbHlfbmFtZSI6IkNhcnZhbGhvIiwiZXh0ZW5zaW9uX05hc2NpbWVudG8iOiIxMy8wMy8xOTg0IiwiZXh0ZW5zaW9uX2NwZiI6IjE5ODkxNzAzNjY1IiwiZXh0ZW5zaW9uX1RlbGVmb25lIjoiKDcxKTk4NTg3OTQ0MiIsImluZFBlcm1pc3NhbyI6InRydWUiLCJzaWduSW5OYW1lcy5lbWFpbEFkZHJlc3MiOiJsZXZpQHR1YW1hZWFxdWVsYXVyc2EuY29tIiwiaXNGb3Jnb3RQYXNzd29yZCI6ZmFsc2UsInRpZCI6IjBhNzU4OWEzLTEzZmYtNDhhZC1hNDMwLWQyMmIwZThhZjc0NCJ9.AabBlO28ea1Q4uLbwfnekkAiCidlTTE4brVsBI-ZwGGGzpe4H8IZUgl3PvkTtIHmNC4TcXq_nQLnmP5W1cxMFi-50744svNkVoQySdWRZxouZLl4wvS4-i_43AXGNKltgE4UwJqQRtvbBEa-udavQ2s32zv3eiiT6Ds9qcmL04grRQm2fAOpp56EZelRJVOb9rDhK2uCyLBXs8iJiQrLhCJ2KSEWf7qeYzgUsbaa_8-F_Llf62ZykmRLvwSp-UCCIEextzOeliuUt0IzxLwtkB5C_8qYptLpR3wdTj28TEVt15E3ZoWnCzf4sMfrVuZW198oSt1Nth3Z1EMwfqyZgA'
  //   const tokenPIETRO = 'token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6InJqUHZSSHZjTzhCNm5NbzAtRzlsRm1lNFNmbkxtaVJKVzh0TTB0WTM4WXcifQ.eyJleHAiOjE2NjEzOTMxOTUsIm5iZiI6MTY2MTM4OTU5NSwidmVyIjoiMS4wIiwiaXNzIjoiaHR0cHM6Ly9hcGlsb2dpbmhtbC5iMmNsb2dpbi5jb20vMGE3NTg5YTMtMTNmZi00OGFkLWE0MzAtZDIyYjBlOGFmNzQ0L3YyLjAvIiwic3ViIjoiNGZhMjY1ODAtY2U1OC00ZTI5LTgwZGYtYTg3NTBlMmNkMjg2IiwiYXVkIjoiZjk4ZGM0NGQtYmVlMy00MDE4LWFhYjAtYTc2NTVhZGEyNjNlIiwiYWNyIjoiYjJjXzFhX2xvZ2luY2FkYXN0cm8iLCJub25jZSI6ImRlZmF1bHROb25jZSIsImlhdCI6MTY2MTM4OTU5NSwiYXV0aF90aW1lIjoxNjYxMzg5NTk1LCJuZXdVc2VyIjp0cnVlLCJnaXZlbl9uYW1lIjoiUGlldHJvIEJyZW5vIiwiZmFtaWx5X25hbWUiOiJBbHZlcyIsImV4dGVuc2lvbl9OYXNjaW1lbnRvIjoiMTQvMDYvMTk2MSIsImV4dGVuc2lvbl9jcGYiOiIxOTI5NjU3NzgxMCIsImV4dGVuc2lvbl9UZWxlZm9uZSI6Iig0Myk5OTc1OTE0NzEiLCJpbmRQZXJtaXNzYW8iOiJ0cnVlIiwic2lnbkluTmFtZXMuZW1haWxBZGRyZXNzIjoicGlldHJvQHR1YW1hZWFxdWVsYXVyc2EuY29tIiwiaXNGb3Jnb3RQYXNzd29yZCI6ZmFsc2UsInRpZCI6IjBhNzU4OWEzLTEzZmYtNDhhZC1hNDMwLWQyMmIwZThhZjc0NCJ9.fhK1YIHNMLzNdnpghL1pbyeMLXdbNZK7pfDhSo1qLDpJgY1FBuJI9EkM8LEDUwR9DSuOq1mYiZ6koJCPfIKQpU446XTv4QHfALZo1tFd9BSEmPjuuus7yVPRlWbPYlO-aA7UyORd3X3gRC76gAS3XxBSIJHM5KHOk33HwaJcE-FK5gLBtjMhwlvOFej7ZDbmvNz3hQocBZG0nmYP95N6zmXUbO5kIxRmvq2AfxnStAcxy38rWVlRQyFQJnnKhJ307iAXu9P7LwYAxBl3fn5I_3TnEFML1gpkMwWMSgCoOB2KpTvzln203sgNNEtREnEppC79uf4tMk1D3QSV4mAa2w'
  //   const tokenENZO = 'token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6InJqUHZSSHZjTzhCNm5NbzAtRzlsRm1lNFNmbkxtaVJKVzh0TTB0WTM4WXcifQ.eyJleHAiOjE2NjEzOTM1MTksIm5iZiI6MTY2MTM4OTkxOSwidmVyIjoiMS4wIiwiaXNzIjoiaHR0cHM6Ly9hcGlsb2dpbmhtbC5iMmNsb2dpbi5jb20vMGE3NTg5YTMtMTNmZi00OGFkLWE0MzAtZDIyYjBlOGFmNzQ0L3YyLjAvIiwic3ViIjoiZTY1ZWFhZWMtNjFiYi00ZDVmLTg0MzQtZmIzMjk4NjlkMzVhIiwiYXVkIjoiZjk4ZGM0NGQtYmVlMy00MDE4LWFhYjAtYTc2NTVhZGEyNjNlIiwiYWNyIjoiYjJjXzFhX2xvZ2luY2FkYXN0cm8iLCJub25jZSI6ImRlZmF1bHROb25jZSIsImlhdCI6MTY2MTM4OTkxOSwiYXV0aF90aW1lIjoxNjYxMzg5OTE5LCJuZXdVc2VyIjp0cnVlLCJnaXZlbl9uYW1lIjoiRW56byBMZXZpIiwiZmFtaWx5X25hbWUiOiJTaWx2ZWlyYSIsImV4dGVuc2lvbl9OYXNjaW1lbnRvIjoiMDgvMDMvMTk1MiIsImV4dGVuc2lvbl9jcGYiOiIwMjQxNjQ0OTc2MSIsImV4dGVuc2lvbl9UZWxlZm9uZSI6Iig2Nyk5ODE2NzkxODEiLCJpbmRQZXJtaXNzYW8iOiJ0cnVlIiwic2lnbkluTmFtZXMuZW1haWxBZGRyZXNzIjoiZW56b0B0dWFtYWVhcXVlbGF1cnNhLmNvbSIsImlzRm9yZ290UGFzc3dvcmQiOmZhbHNlLCJ0aWQiOiIwYTc1ODlhMy0xM2ZmLTQ4YWQtYTQzMC1kMjJiMGU4YWY3NDQifQ.YmJj0o07OupGESWCPVxymlQQmATeCp9CPFjrTbISUTvwTIqoP8Gvif34HokN7Cu3hLjhQmXZ0qpl2qpyR_3hH0mDVRH7EqLuACNz_aW1gBWTacHL61QCftslG55UocvHaUnTgVYLLn8qoovZfgba2FAUltjqxa0ZJGArtM72XheE3EItDjQizzJCSVQ9eVqFrxbjMiaIhpU2sLuOFDAvbHojmgvnuMzmkFBmReVG4yd_g9mbGBE9-TlRrCG3ArYVT8HYq-IXE7QLULxcm1BKoNH8VLRDeVfnzoz3x9XCwn2RFNFq7ryvYfXqNgEoY6s5D3bKcpvnKv0RV1qaLV2-TA'
  //   const tokenMARCIO = 'token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6InJqUHZSSHZjTzhCNm5NbzAtRzlsRm1lNFNmbkxtaVJKVzh0TTB0WTM4WXcifQ.eyJleHAiOjE2NjEzOTM4MzUsIm5iZiI6MTY2MTM5MDIzNSwidmVyIjoiMS4wIiwiaXNzIjoiaHR0cHM6Ly9hcGlsb2dpbmhtbC5iMmNsb2dpbi5jb20vMGE3NTg5YTMtMTNmZi00OGFkLWE0MzAtZDIyYjBlOGFmNzQ0L3YyLjAvIiwic3ViIjoiNTRjMmRlNTAtNDdkOS00Nzc5LTkwMDctZDdlYzQ1OGYxODYwIiwiYXVkIjoiZjk4ZGM0NGQtYmVlMy00MDE4LWFhYjAtYTc2NTVhZGEyNjNlIiwiYWNyIjoiYjJjXzFhX2xvZ2luY2FkYXN0cm8iLCJub25jZSI6ImRlZmF1bHROb25jZSIsImlhdCI6MTY2MTM5MDIzNSwiYXV0aF90aW1lIjoxNjYxMzkwMjM1LCJuZXdVc2VyIjp0cnVlLCJnaXZlbl9uYW1lIjoiQ2FybG9zIE5vYWgiLCJmYW1pbHlfbmFtZSI6IlBheiIsImV4dGVuc2lvbl9OYXNjaW1lbnRvIjoiMDMvMDYvMTk4NSIsImV4dGVuc2lvbl9jcGYiOiIyMTU1MjgyMTg0NiIsImV4dGVuc2lvbl9UZWxlZm9uZSI6Iig3NSk5OTQ2OTk2MzkiLCJpbmRQZXJtaXNzYW8iOiJ0cnVlIiwic2lnbkluTmFtZXMuZW1haWxBZGRyZXNzIjoiY2FybG9zQHR1YW1hZWFxdWVsYXVyc2EuY29tIiwiaXNGb3Jnb3RQYXNzd29yZCI6ZmFsc2UsInRpZCI6IjBhNzU4OWEzLTEzZmYtNDhhZC1hNDMwLWQyMmIwZThhZjc0NCJ9.lc5PWiJSNTSHP5rQNeaO3p3SCEBw0ljmwkaZxJ4XWLxOc1iJHtOdueHL_fOiQlwp1fHDW9TMzmeRSdKWppNWM0m0VjhLIVGWqOf8QcChoigBel8g8CDcBOycUYX5S7sF2JMb783Ppje9M_TUgfSiCCsMOg-lYbX1VuXLUFO2r8PUXNstMij4VpojuKdEs6_GeFLfYcbOQppiiycyO1kI1BmcRJPo9N4v2XC-OIT8s_cC7Plu6U9I65SpAgvuJi3IB3LPsq07zMBVw6_In5wKaSzMb0bIPb2JY_PR3OaDL3vF1NuSXDgAGKSyHHRTRl3DU8n1Y4ewQU4iYoPJrhLibA'
  //   const tokenERICK = 'token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6InJqUHZSSHZjTzhCNm5NbzAtRzlsRm1lNFNmbkxtaVJKVzh0TTB0WTM4WXcifQ.eyJleHAiOjE2NjEzOTQ2MzksIm5iZiI6MTY2MTM5MTAzOSwidmVyIjoiMS4wIiwiaXNzIjoiaHR0cHM6Ly9hcGlsb2dpbmhtbC5iMmNsb2dpbi5jb20vMGE3NTg5YTMtMTNmZi00OGFkLWE0MzAtZDIyYjBlOGFmNzQ0L3YyLjAvIiwic3ViIjoiMjBkNjkwMmItMGUwZi00YjBkLTljY2UtZDYxZTM5YWMwNzk5IiwiYXVkIjoiZjk4ZGM0NGQtYmVlMy00MDE4LWFhYjAtYTc2NTVhZGEyNjNlIiwiYWNyIjoiYjJjXzFhX2xvZ2luY2FkYXN0cm8iLCJub25jZSI6ImRlZmF1bHROb25jZSIsImlhdCI6MTY2MTM5MTAzOSwiYXV0aF90aW1lIjoxNjYxMzkxMDM5LCJuZXdVc2VyIjp0cnVlLCJnaXZlbl9uYW1lIjoiRXJpY2sgTWFydGluIiwiZmFtaWx5X25hbWUiOiJNZWxvIiwiZXh0ZW5zaW9uX05hc2NpbWVudG8iOiIyNC8wMi8xOTUxIiwiZXh0ZW5zaW9uX2NwZiI6IjU5NjkzODYzNTkzIiwiZXh0ZW5zaW9uX1RlbGVmb25lIjoiKDQ0KTk4Njk4NDIyMSIsImluZFBlcm1pc3NhbyI6InRydWUiLCJzaWduSW5OYW1lcy5lbWFpbEFkZHJlc3MiOiJlcmlja0B0dWFtYWVhcXVlbGF1cnNhLmNvbSIsImlzRm9yZ290UGFzc3dvcmQiOmZhbHNlLCJ0aWQiOiIwYTc1ODlhMy0xM2ZmLTQ4YWQtYTQzMC1kMjJiMGU4YWY3NDQifQ.Lw4DBtaxyn8uMcFkKkU-3RhOu0Deh_d9e45Sib0lYyJ9X6MbC8c7Qdzhs782bR4eYodDuulzXNpNeG0TVSqMD2DmZpdSq7lZeNgUW2NoDNAYeFK6DZmh_D9zfTSj_jqVGcquke3II24AOTncncouJtiT9dKNUvqaocgF6sPW6GRNBErRSZhYI7FqH6FrvKawjYXTzl6MGImD8-HvDvnWiBpIuOoNf8AqaTRS8b1SuXvI7A8LyAYarfTnCmScNf1Ozo0Ydth13eaXHou7ecNCZJw2sazy93ZX8OvkAwpS5r7SPIozPApe0UUELux_REyssnlPMVnN5gXekmIjoAc6eQ'
  //   const tokenYURI = 'token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6InJqUHZSSHZjTzhCNm5NbzAtRzlsRm1lNFNmbkxtaVJKVzh0TTB0WTM4WXcifQ.eyJleHAiOjE2NjEzOTUzMjgsIm5iZiI6MTY2MTM5MTcyOCwidmVyIjoiMS4wIiwiaXNzIjoiaHR0cHM6Ly9hcGlsb2dpbmhtbC5iMmNsb2dpbi5jb20vMGE3NTg5YTMtMTNmZi00OGFkLWE0MzAtZDIyYjBlOGFmNzQ0L3YyLjAvIiwic3ViIjoiZTQwZmU2Y2MtMDlkMy00ZWYwLWFiNzUtNjg1ZmM2YTY2MGQzIiwiYXVkIjoiZjk4ZGM0NGQtYmVlMy00MDE4LWFhYjAtYTc2NTVhZGEyNjNlIiwiYWNyIjoiYjJjXzFhX2xvZ2luY2FkYXN0cm8iLCJub25jZSI6ImRlZmF1bHROb25jZSIsImlhdCI6MTY2MTM5MTcyOCwiYXV0aF90aW1lIjoxNjYxMzkxNzI4LCJuZXdVc2VyIjp0cnVlLCJnaXZlbl9uYW1lIjoiWXVyaSBFbnJpY28iLCJmYW1pbHlfbmFtZSI6Ik9saXZlaXJhIiwiZXh0ZW5zaW9uX05hc2NpbWVudG8iOiIyMy8wMi8xOTgxIiwiZXh0ZW5zaW9uX2NwZiI6IjA5MDk0MTkzNjEyIiwiZXh0ZW5zaW9uX1RlbGVmb25lIjoiKDkxKTk4MjQ1NDczOSIsImluZFBlcm1pc3NhbyI6InRydWUiLCJzaWduSW5OYW1lcy5lbWFpbEFkZHJlc3MiOiJ5dXJpQHR1YW1hZWFxdWVsYXVyc2EuY29tIiwiaXNGb3Jnb3RQYXNzd29yZCI6ZmFsc2UsInRpZCI6IjBhNzU4OWEzLTEzZmYtNDhhZC1hNDMwLWQyMmIwZThhZjc0NCJ9.ZCKA1jpfrO_t701PC2FNZ51-R88PAYxbezqQzIf63nl52zjXfcqnLf2JdqZc9D5pTJiNgYvSWKDIKMOgbiVXAR3zh2MfuTB8sEU95s6Mu4FObqUr5m0p93zJ4XyPOuw9A1x-wk1FIQpcr2OGl2Xp-_BeqN0amkSvTfg4xd-Zz_QgvtQQKoY1zz9h8MZosEsKAaPqKfcnbAzr4dPhQ5yJiJE1YoZSWTw6x8IjbclL1soHrfq-upXQcZVUGpaGbJPYW5Spao857vNlFTy3Yqh21S0bNKMZ7vv5TeVZBIkGvWLKC-OnKweFlvdzJPgki1PcZY_aIFofLW6UAec5Nsa7iw'
  //   const tokenPRD = 'token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IlZ0M1JhRkpSdnRsbUNOSUNfUnFuX3EyLURwVVpJSnQzNlZrUjhpNWhIb28ifQ.eyJleHAiOjE2NjM2Mjk3MTIsIm5iZiI6MTY2MzYyNjExMiwidmVyIjoiMS4wIiwiaXNzIjoiaHR0cHM6Ly9hcGlhY2Vzc28uYjJjbG9naW4uY29tLzRlMGJjNWMwLTE2NGMtNGUyNS1hYmVhLTZmMzk0ZGUxZmFhZS92Mi4wLyIsInN1YiI6ImU1MTU1NGNmLWJiNDEtNGY3Zi1iZGNhLTJlOThiM2NjODIzMyIsImF1ZCI6IjlhYmQ0OGY1LTRiNDYtNGI5YS05OGU0LTQ1ZWUyMjc4NjE5ZiIsImFjciI6ImIyY18xYV9sb2dpbmNhZGFzdHJvIiwibm9uY2UiOiJkZWZhdWx0Tm9uY2UiLCJpYXQiOjE2NjM2MjYxMTIsImF1dGhfdGltZSI6MTY2MzYyNjExMiwiZXh0ZW5zaW9uX05hc2NpbWVudG8iOiIwNi8wMi8yMDAwIiwiZXh0ZW5zaW9uX2NwZiI6IjA1NzgxODgxNzE5IiwiZXh0ZW5zaW9uX1RlbGVmb25lIjoiKDIxKTk5MDUyMTcwNyIsInNpZ25Jbk5hbWVzLmVtYWlsQWRkcmVzcyI6Im5hbmFmYWxib0BnbWFpbC5jb20iLCJnaXZlbl9uYW1lIjoiQW5uYSIsImZhbWlseV9uYW1lIjoiRG9taW5nb3MiLCJpbmRQZXJtaXNzYW8iOiJmYWxzZSIsIm5ld1VzZXIiOmZhbHNlLCJpc0ZvcmdvdFBhc3N3b3JkIjpmYWxzZSwidGlkIjoiNGUwYmM1YzAtMTY0Yy00ZTI1LWFiZWEtNmYzOTRkZTFmYWFlIn0.Q9EMA-q6kvXrtwpZzpKGDR9M7QzVx3ogAY_cg-rDkmYKomjPnVJjOigEV8osDMi1S6AZXnvsL72F8q5kNTec5jEcHbzoYLsE_94o9F1Y2qzVl4fRtYmvkk8Nb1FPcJANX3kqOi5yIn-LQQVLGbZjrMD8ADjdKTgYqT0ejYiHns5dWIIPpviN8o2RlUKZulA53Y830c-o-oQ8n4YzNS8pBPARJj6ge6as1i62_eBEqUK0LxYq322af2ZwoWC9q3e1f94OO4Bnm1QuzWCAzARH_gxjFk5SM3a3j4B2E8AkESSonk2WnIlKSEgfeNnyYElsd4URIQufq7-19fl9x2Vlpg'
  //   navigation.navigate('Signin', {token: tokenTL})
  // }

  const redirectLogin = async () => {
    // navigation.navigate('Signin')

    try {
      _addLinkingListener();
      /*let result = await WebBrowser.openBrowserAsync(
        // We add `?` at the end of the URL since the test backend that is used
        // just appends `authToken=<token>` to the URL provided.
        `https://apiloginhml.b2clogin.com/apiloginhml.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_LOGINCADASTRO&response_mode=query&client_id=f98dc44d-bee3-4018-aab0-a7655ada263e&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fapi-solar-dev.msalso.com%2Fsignin&scope=openid&response_type=id_token&prompt=login`
      );*/

      // var url = "https://apiloginhml.b2clogin.com/apiloginhml.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_LOGINCADASTRO&response_mode=query&client_id=f98dc44d-bee3-4018-aab0-a7655ada263e&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fapi-solar-dev.msalso.com%2Fsignin&scope=openid&response_type=id_token&prompt=login"
      // var url = `https://login-solarfidelidade.vercel.app/`
     var url = `https://apiacesso.b2clogin.com/apiacesso.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_LOGINCADASTRO&response_mode=query&client_id=9abd48f5-4b46-4b9a-98e4-45ee2278619f&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fapi-solar.msalso.com%2Fsignin&scope=openid&response_type=id_token&prompt=login`;
      // var url = `http://192.168.1.163:3333/signin`
      // var url = `https://apiacesso.b2clogin.com/apiacesso.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_LOGINCADASTRO&response_mode=query&client_id=9abd48f5-4b46-4b9a-98e4-45ee2278619f&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fapi-solar.msalso.com%2Fsignin&scope=openid&response_type=id_token&prompt=login`;
      // var url = `http://192.168.1.100:3333/signin`

      let result = await WebBrowser.openBrowserAsync(
        // We add `?` at the end of the URL since the test backend that is used
        // just appends `authToken=<token>` to the URL provided.
        url
      );

      // https://github.com/expo/expo/issues/5555
      if (Platform.OS === "ios") {
        _removeLinkingListener();
      }
    } catch (error) {
      alert(error);
      console.log(error);
    }
  };

  const _addLinkingListener = () => {
    Linking.addEventListener("url", _handleRedirect);
  };

  const _removeLinkingListener = () => {
    Linking.removeEventListener("url", _handleRedirect);
  };

  const redirectRegister = async () => {
    navigation.navigate("Signup");
  };

  const openPrivacyPolitic = async () => {
    await WebBrowser.openBrowserAsync(privacyPolicyState);
  };

  return (
    <MainCotainer>
      <ScrollView
        horizontal={false}
        contentContainerStyle={{ flexGrow: 1 }}
        showsVerticalScrollIndicator={false}
      >
        <View style={{ marginBottom: "8%" }}>
          <ImageCarousel
            items={data}
            selectIndex={selectImageCarouselIndex}
            setSelectIndex={setSelectImageCarouselIndex}
          />
        </View>
        <Wrapper>
          <ContentAligned style={{ marginTop: 0 }}>
            <View
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextCarousel
                items={textData}
                selectIndex={selectTextCarouselIndex}
                setSelectIndex={setSelectTextCarouselIndex}
              />

              <ButtonsWrapper>
                {/* <DefaultButton onPress={redirectRegister} text='QUERO FAZER PARTE'/> */}
                <DefaultButton
                  onPress={redirectLogin}
                  text="ACESSAR PROGRAMA"
                />
                {/* <DefaultButton onPress={directAccess} text='LOGIN DIRETO'/> */}
              </ButtonsWrapper>
            </View>

            {Platform.OS !== "web" ? (
              <View style={{ marginBottom: 30 }}>
                <ButtonsWrapper>
                  {/* <DefaultButton onPress={redirectRegister} text='QUERO FAZER PARTE'/> */}
                </ButtonsWrapper>
              </View>
            ) : (
              <View
                style={{
                  width: "100%",
                  marginTop: 100,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Image style={{ height: 380, width: 300 }} source={Celular} />
                <Image
                  style={{ height: 180, width: 180, marginLeft: 80 }}
                  source={qrCode}
                />
              </View>
            )}
          </ContentAligned>
          <View style={{ marginTop: 10 }}>
            <Profits data={profitData} />
          </View>
        </Wrapper>

        <SecondMainCotainer>
          <SecondWrapper>
            <SecondContentAligned>
              <View
                style={{
                  marginBottom: 40,
                  marginTop: 40,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <BigTextBold>BENEFÍCIOS POR CATEGORIA</BigTextBold>
              </View>
              {Platform.OS === "web" ? (
                <>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <TierProfits
                      border="Bronze"
                      desc="Lorem ipsum is placeholder text commonly used in the graphic, print."
                      tier="SOLAR Bronze"
                      data={tiersBronze}
                    ></TierProfits>
                    <TierProfits
                      border="Silver"
                      desc="Lorem ipsum is placeholder text commonly used in the graphic, print."
                      tier="SOLAR Prata`"
                      data={tiersBronze}
                    ></TierProfits>
                    <TierProfits
                      border="Gold"
                      desc="Lorem ipsum is placeholder text commonly used in the graphic, print."
                      tier="SOLAR OURO"
                      data={tiersBronze}
                    ></TierProfits>
                  </View>
                </>
              ) : (
                <ScrollView
                  horizontal={true}
                  showsHorizontalScrollIndicator={false}
                >
                  <TierProfits
                    border="Bronze"
                    desc=""
                    tier="SOLAR BRONZE"
                    data={tiersBronze}
                  ></TierProfits>
                  <TierProfits
                    border="Silver"
                    desc=""
                    tier="SOLAR PRATA"
                    data={tiersSilver}
                  ></TierProfits>
                  <TierProfits
                    border="Gold"
                    desc=""
                    tier="SOLAR OURO"
                    data={tiersGold}
                  ></TierProfits>
                </ScrollView>
              )}
            </SecondContentAligned>
          </SecondWrapper>
        </SecondMainCotainer>
      </ScrollView>

      <ModalAlert
        text={modalText}
        textButtonConfirm="OK"
        isVisible={modalVisible}
        onPressConfirm={() => setModalVisible(false)}
      />
    </MainCotainer>
  );
};

export default PublicHome;
