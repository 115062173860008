import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { stat } from 'fs';

interface propsPersistence {
    key: string,
    initialState: any
}

//função com persistencia de preset e salvamento em cache, bom para trocar de estilo no meio da aplicação ex dark and light theme
function usePersistedThemeState<T>({ key, initialState }: propsPersistence):any {
    const [ firstLoad, setFirstLoad]= useState(true)
    const [theme, setTheme] = useState()
    
    //inicia o processo de recuperação das configurações do app salva em cache
    const getPresets =async ()=>{
        let getStoragePresets = await AsyncStorage.getItem(key)

        if(getStoragePresets && getStoragePresets !== "undefined" && getStoragePresets !== undefined){
            return setTheme(JSON.parse(getStoragePresets))
        }
    }

    useEffect(() => {
        if(firstLoad){
            getPresets()
            setFirstLoad(false)
        }
        if(!theme){
            setTheme(initialState)
        }
        if(key && theme) AsyncStorage.setItem(key, JSON.stringify(theme))
        
    }, [key, theme])

    return [theme, setTheme];

}
export default usePersistedThemeState