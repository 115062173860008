import AuthRequest from "../config/AuthRequest";
import axios from "axios";
import URL from "../config/URL";

const getInvoice = async ({ id, token, startDate, finalDate }: any) => {
  try {
    if (startDate && finalDate) {
      startDate = startDate.split("/").reverse().join("-");
      finalDate = finalDate.split("/").reverse().join("-");
    }

    const config = {
      params: {
        startDate,
        finalDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    //   const statement =  await axios.get(`${AuthRequest.fidelityApi}/sensedia/getLoyaltyUser/${id}`)
    const invoice = await axios.get(
      `${AuthRequest.fidelityApi}/sensedia/getInvoice/${id}`,
      config
    );

    return invoice.data;
  } catch (e) {
    if (e?.response?.data.status == 404) {
      return [];
    }

    return "Request Error";
  }
};

export default getInvoice;
