






import styled  from 'styled-components/native';

// background-color: ${props => props.theme["_W"].colors.primary};
export const SecondContentAligned = styled.View`
display: flex;
justify-content: center;
background-color: transparent;
width: 100%; /*${props=> ((props.theme.ScreenWidth * 86)/100)}px; */

`