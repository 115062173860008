import styled  from 'styled-components/native';

// background-color: ${props => props.theme["_W"].colors.primary};
export const SmallTextBold = styled.Text`

font-size: 12px;

color: ${props => props.theme.colors.text};

font-family: ${props=> props.theme.fontFamily};
font-weight: bold;
`