import styled from "styled-components/native";
import { Platform } from "react-native";

export const Button = styled.Pressable`
    background-color: ${props => props.theme.colors.redemptionButton.background};
    width: ${Platform.OS === 'web' ?48:100}%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: ${props => props.theme.colors.defaultButton.borderColor};
    border-width: 1px;
    padding: 10px;
    margin-top:20px;
    margin-bottom:10px;
`;

export const TextButton = styled.Text`
    color: ${props => props.theme.colors.redemptionButton.textColor};
    font-size: 16px;
    font-weight: bold;
`;

export const InactiveButton = styled.View`
    background-color: ${props => props.theme.colors.redemptionInactiveButton.background};
    width: ${Platform.OS === 'web' ?48:100}%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-color: ${props => props.theme.colors.redemptionInactiveButton.borderColor};
    border-width: 1px;
    padding: 10px;
    margin-top:20px;
    margin-bottom:10px;
`;

export const TextInactiveButton = styled.Text`
    color: ${props => props.theme.colors.redemptionInactiveButton.textColor};
    font-size: 16px;
    font-weight: bold;
    flex:1;
    margin-left:20px;
    margin-right:20px;
    text-align: center;
`;
