import React, { useContext, useEffect, useState } from "react";
import { View, Linking, Platform, Alert, Text } from "react-native";
import AuthContext from "../../context";
import Donut from "../../components/charts/donut";
import { MainCotainer } from "../../styles/containers/main/mainContainer";
import { Wrapper } from "../../styles/containers/main/wrapper";
import { ContentAligned } from "../../styles/containers/main/contentAligned";
import { BigTextBold } from "../../styles/text/boldText/bigTextBold";
import InfoUser from "../../components/infoUser";
import { SecondMainCotainer } from "../../styles/containers/second/secondMainContainer";
import { SecondWrapper } from "../../styles/containers/second/secondWrapper";
import { SecondContentAligned } from "../../styles/containers/second/secondContentAligned";
import { SecondContentMargin } from "../../styles/containers/second/secondContentMargin";
import TierInfoUser from "../../components/tierInfoUser";
import { ScrollView } from "react-native-gesture-handler";
import PointsWillExpire from "../../components/pointsWillExpire";
import TierProfits from "../../components/tierProfits";
import ScreenMenu from "../../components/screenMenu";
import { useNavigation } from "@react-navigation/native";
import getLoyaltyUser from "../../services/getLoyaltyUser";
import { useQuery } from "react-query";
import { getPointsExpire } from "../../services/get";
import { MediumTextBold } from "../../styles/text/boldText/mediumTextBold";
import * as WebBrowser from "expo-web-browser";
import privacyPolicy from "../../services/privacyPolicy";
import jwt from "jwt-decode";
import sendMail from "../../services/sendEmail";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import ModalAlert from "../../components/modalAlert";
import GlobalMessages from "../../config/Messages";

interface decodeInterface {
  family_name: any;
  extension_Telefone: any;
  extension_cpf: any;
  given_name: any;
  isForgotPassword: boolean;
  celular: any;
  ddd: any;
  "signInNames.emailAddress": [];
}

const tiersBronze = [
  {
    title: "Descontos",
    promotion: ["Lojas", "Gastronomia", "Hospedagem"],
  },
  {
    title: "Troca de Pontos:",
    promotion: ["Ingressos para eventos", "Gastronomia", "Doações"],
  },
];
const tiersSilver = [
  {
    title: "Descontos",
    promotion: ["Lojas", "Gastronomia", "Hospedagem"],
  },
  {
    title: "Troca de Pontos:",
    promotion: [
      "Ingressos VIPs",
      "Experiências",
      "Doações",
      "Personal Shopper",
      "Isenção de Estacionamento",
    ],
  },
  {
    title: "Cortesia",
    promotion: ["Experiências no lounge SOLAR"],
  },
];
const tiersGold = [
  {
    title: "Descontos",
    promotion: ["Lojas", "Gastronomia", "Hospedagens Premium"],
  },
  {
    title: "Troca de Pontos:",
    promotion: [
      "Ingressos VIPs",
      "Experiências",
      "Doações",
      "Personal Shopper",
      "Pacotes de viagens premium",
    ],
  },
  {
    title: "Cortesia",
    promotion: [
      "Experiências no lounge SOLAR",
      "Ingressos VIPs",
      "Personal Shopper",
      "Gifts personalizados",
      "Isenção de estacionamento",
    ],
  },
];
const tiers = [
  {
    title: "Descontos",
    promotion: ["Lojas", "Gastronomia", "Hospedagem"],
  },
  {
    title: "Troca de Pontos:",
    promotion: ["Ingressos para eventos", "Gastronomia", "Doações"],
  },
];

const RestrictHome: React.FC<any> = (
  // props:any,
  // habilitar props quando quiser abrir o menu
  { navigation }
) => {
  const [privacyPolicyState, setPrivacyPolicyState] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const {
    setModality,
    setBalance,
    setUserId,
    setB2CLoginToken,
    cpf,
    signedOut,
    signed,
    B2CLoginToken,
    givenName,
    familyName,
    setDrawer,
    userId,
    balance,
    modality,
    fidelityToken,
    loyaltyUser,
  } = useContext(AuthContext);

  useEffect(() => {
    if (modality === "INATIVOS") {
      setModalVisible(true);
    }
  }, []);

  
  const clearUrlParamsWeb = async () => {
    if (Platform.OS === "web") {
      /**
       * Gatante que vai limpar os parametros da url quando vir para essa pagina
       */
      const queryString = window.location.search;
      const [, token] = queryString.split("id_token=");
      if (token) {
        return (window.location.href = "/");
      }
    }
  };

  const goToPrivacyCentral = () => {
    return Linking.openURL('https://privacy-central.securiti.ai/#/dsr/a571d020-4d05-4479-8700-f1dbad8acca1')
  }

  const removeAccount = async () => {
    Alert.alert(
      "Quero excluir minha conta",
      "Sua solicitação de exclusão de conta será atendida em até 15 dias. Deseja prosseguir com a exclusão da conta?",
      [
        {
          text: "Cancelar",
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel",
        },
        {
          text: "Excluir",
          onPress: goToPrivacyCentral
        },
      ]
    );
  };

  // const isDrawerOpen = useDrawerStatus()
  useEffect(
    () => {
      (async () => {
        const getPolicies = await privacyPolicy(fidelityToken);
        setPrivacyPolicyState(getPolicies[0]?.conteudo);
      })();

      clearUrlParamsWeb();

      Linking.getInitialURL()
        .then((url) => {})
        .catch((err) => {});
      // if(cpf){
      //   setDrawer(navigation)
      //   clearUrlParamsWeb()

      //   handlePopulateInformations()
      // }
    },
    [
      // cpf
    ]
  );

  // const signOutFnc = () =>{
  //   signedOut()
  // }

  // const handlePopulateInformations = async() =>{
  //     try{

  //       setAlianseToken(token?.access_token)
  //       setRefreshToken(token?.refresh_token)
  //       let user:any =  await getAlianseUser(cpf, token?.access_token)
  //       setUserId(user.data.pessoaFisica.id)

  //       const loyaltyUser = await getLoyaltyUser(user.data.pessoaFisica.id,token?.access_token)
  //       setBalance(loyaltyUser.saldo)
  //       setModality(loyaltyUser.modalidade)
  //       setDataIsDefined(true);

  //     }catch(e){
  //         console.log(e)
  //     }

  // }

  const { data, isError, isLoading, error } = useQuery(
    ["poins-expire", userId, fidelityToken],
    getPointsExpire
  );
  if (isError) {
    console.log(error);
  }
  if (!isLoading && !isError) {
  }
  const tierConfig = [
    { key: "INATIVOS", name: "Inativos", pts: 0 },
    { key: "BRONZE", name: "Bronze", pts: 0 },
    { key: "PRATA", name: "Prata", pts: 50000 },
    { key: "OURO", name: "Ouro", pts: 100000 },
  ];
  const datePointsExpireSplited = isLoading
    ? null
    : data?.data.slice(-1)[0]?.dataExpiracao.split("-");
  const datePointsExpire =
    isLoading || !Array.isArray(datePointsExpireSplited)
      ? null
      : `${datePointsExpireSplited[2]}/${datePointsExpireSplited[1]}/${datePointsExpireSplited[0]}`;

  const openPrivacyPolitic = async () => {
    await WebBrowser.openBrowserAsync(privacyPolicyState);
  };

  return (
    <>
      <ScrollView
        horizontal={false}
        contentContainerStyle={{ flexGrow: 1 }}
        showsVerticalScrollIndicator={false}
      >
        <SecondMainCotainer>
          <SecondWrapper>
            <SecondContentAligned>
              <SecondContentMargin>
                <InfoUser />
              </SecondContentMargin>

              {Platform.OS === "web" ? null : (
                <View style={{ left: 5 }}>
                  <View style={{ marginBottom: 20 }}>
                    <SecondContentMargin>
                      <BigTextBold>Meus Pontos</BigTextBold>
                    </SecondContentMargin>
                  </View>
                  <View style={{ width: "100%", alignItems: "center" }}>
                    <Donut tierDescription={true} />
                  </View>
                </View>
              )}
              <View
                style={{
                  justifyContent: "center",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <TierInfoUser
                  pointsRemaining={loyaltyUser.pontosProximaModalidade}
                  nextTier={loyaltyUser.proximaModalidade}
                />
              </View>
            </SecondContentAligned>
          </SecondWrapper>
        </SecondMainCotainer>

        <MainCotainer>
          <Wrapper>
            <ContentAligned>
              <View style={{ marginTop: -20 }}>
                <PointsWillExpire
                  points={
                    data?.data[0]?.pontos === undefined
                      ? "-"
                      : parseInt(data?.data[0]?.pontos)
                  }
                  date={isLoading ? "-" : datePointsExpire}
                />
              </View>

              <View style={{ marginTop: 30, marginBottom: 30 }}>
                <ScreenMenu navigation={navigation} />
              </View>
            </ContentAligned>
          </Wrapper>
        </MainCotainer>

        <SecondMainCotainer>
          <SecondWrapper>
            <SecondContentAligned>
              <View
                style={{
                  marginBottom: 40,
                  marginTop: 40,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <BigTextBold>BENEFÍCIOS POR CATEGORIA</BigTextBold>
              </View>
              {Platform.OS === "web" ? (
                <>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <TierProfits
                      border="Bronze"
                      desc="Lorem ipsum is placeholder text commonly used in the graphic, print."
                      tier="SOLAR Bronze"
                      data={tiers}
                    ></TierProfits>
                    <TierProfits
                      border="Silver"
                      desc="Lorem ipsum is placeholder text commonly used in the graphic, print."
                      tier="SOLAR Prata`"
                      data={tiers}
                    ></TierProfits>
                    <TierProfits
                      border="Gold"
                      desc="Lorem ipsum is placeholder text commonly used in the graphic, print."
                      tier="SOLAR OURO"
                      data={tiers}
                    ></TierProfits>
                  </View>
                </>
              ) : (
                <ScrollView
                  horizontal={true}
                  showsHorizontalScrollIndicator={false}
                >
                  <TierProfits
                    border="Bronze"
                    desc=""
                    tier="SOLAR BRONZE"
                    data={tiersBronze}
                  ></TierProfits>
                  <TierProfits
                    border="Silver"
                    desc=""
                    tier="SOLAR PRATA"
                    data={tiersSilver}
                  ></TierProfits>
                  <TierProfits
                    border="Gold"
                    desc=""
                    tier="SOLAR OURO"
                    data={tiersGold}
                  ></TierProfits>
                </ScrollView>
              )}
            </SecondContentAligned>
          </SecondWrapper>
        </SecondMainCotainer>

        <MediumTextBold
          style={{ textAlign: "center", marginTop: 40, marginBottom: 40 }}
          onPress={() => removeAccount()}
        >
          <MaterialCommunityIcons
            name="delete-forever"
            size={20}
            color="black"
          />
          <Text style={{ textDecorationLine: "underline" }}>
            Quero excluir a minha conta
          </Text>
        </MediumTextBold>
      </ScrollView>
      <ModalAlert
        text={GlobalMessages.userInactive}
        textButtonConfirm="Adicionar nota fiscal"
        textButtonCancel="Cancelar"
        isVisible={modalVisible}
        onPressConfirm={() => {
          setModalVisible(!modalVisible);
          navigation.navigate({ name: "BarCodeScanner" });
        }}
        onPressCancel={() => setModalVisible(!modalVisible)}
      />
    </>
  );
};

export default RestrictHome;
