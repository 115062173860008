import AuthRequest from '../config/AuthRequest';
import axios from 'axios';
import URL from '../config/URL';
import moment from 'moment';
/**
 * get previous month
 */

interface Period {
    startDate: string;
    finalDate: string;
}


const getStatement = async (id:any, token:any, lastPeriods?:any, period?: Period )=>{
    const current = new Date();
current.setMonth(current.getMonth()-1);
// const previousMonth = current.toLocaleString('default', { month: 'long' });
const previousMonth = moment(current).format('YYYY-MM-DD')


let params = lastPeriods ? {

    inicio: previousMonth,
    fim:moment().format('YYYY-MM-DD')
} :period ?{
    inicio: period.startDate.split("/").reverse().join("-"),
    fim: period.finalDate.split("/").reverse().join("-")
}:{}



    try{

    const statement =  await axios.get(`${AuthRequest.fidelityApi}/sensedia/getStatement/${id}`,  { 
        params,
        headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,

        }
    })

    return statement.data
}catch(e){
    console.log(e)

    return "Request Error"
}

}

export default getStatement