import { Platform } from 'react-native';
import styled  from 'styled-components/native';

export const Container = styled.View`
/* width: ${props => props.theme.ScreenWidth}px;
height: ${props => ((props.theme.ScreenHeight * 9)/100)}px;
background-color: ${props => props.theme.colors.header};
display: flex;
justify-content: center;
margin-top: ${Platform.OS === 'web' ?'10px' : '10px' }; */
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 90px;
background-color: ${props=> props.theme.colors.header};
`

export const LogoContainer= styled.View`
left: 10%;
width:${ Platform.OS === 'web' ? 24: 56 }%;
height: ${ Platform.OS === 'web' ? 100: 70 }%; 
/* margin-left: ${Platform.OS ==='web'? 40 : 10 }px; */
margin-bottom: ${Platform.OS ==='web'? 20 : 1 }px;
display: flex;
justify-content: center;
align-items: center;
${Platform.OS !== 'web' ? 'margin-top: 3%' :null}


`

export const Logo = styled.Image`
/* margin-left: 100px; */
/* ${ Platform.OS === 'web' ? 'width: 200px': null }
${ Platform.OS === 'web' ? 'height: 48px': null } */
width: 100%;
/* height: ${ Platform.OS === 'web' ? 100: 80 }%; */
height: 100%;
${Platform.OS === 'android' ? 'margin-top: 16px' : null}
${Platform.OS === 'ios' ? 'margin-top: 55px' : null}

/* width: ${Platform.OS==='web' ? '300px':null}; */
/* height: 50px; */
`
export const Menu = styled.View`
/* background-color: black; */
${Platform.OS === 'android' ? 'margin-top: 16px' : null}
${Platform.OS === 'ios' ? 'margin-top: 16px' : null}
width:${ Platform.OS === 'web' ? 76: 44 }%;
height: ${ Platform.OS === 'web' ? 100: 64 }%; 
display: flex;
justify-content: flex-end;
align-items: center;
flex-direction: row
`


export const BackgroundModal = styled.View`
    width: ${props => props.theme.ScreenWidth}px;
    height: ${props => props.theme.ScreenHeight}px;
    background-color: #000;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
`;