import React from "react";
import { LoadingStyle} from "./styles";


const Loading:React.FC<any> = ({primaryColor="#fff", secondaryColor="lightgray", duration="5000", style}) => {
    return(
        <LoadingStyle
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            duration={duration} 
            style={style}
        />
    )
}

export default Loading;