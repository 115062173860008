import AuthRequest from '../config/AuthRequest';
import axios from 'axios';


const getFidelity = async (userID:any, token:any) => {

    try{
        const getFidelityReq = await axios.get(`${AuthRequest.fidelityApi}/sensedia/getFidelity/${userID}`,
        {
            headers:{
                "Content-Type": "application/json",
                "application": AuthRequest.Application,
                "Authorization": `Bearer ${token}`,
            }
        })
        return getFidelityReq
    }catch(e:any){

        return {
            status: e?.response.status,
            message: e?.message
        }
    }

    
}

export default getFidelity;