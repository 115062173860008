import React, { useContext, useEffect, useState } from 'react';
import {Platform} from 'react-native';
import AuthContext from '../../context';
import getAlianseUser from '../../services/getAlianseUser';
import addClient from '../../services/addClient';
import updateClient from '../../services/updateClient';
import { LoginContainer } from './styles';
import jwt from 'jwt-decode'
import { Dimensions } from 'react-native';
import * as Linking from 'expo-linking';
import getFidelity from '../../services/getFidelity';
import classifyClient from '../../services/classifyClient';
import Loading from '../../components/loading';
import WebView from 'react-native-webview';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import ModalAlert from '../../components/modalAlert';
import GlobalMessages from '../../config/Messages';

const Signin: React.FC<any> = ({navigation, route}) => {



const windowWidth = Dimensions.get('window').width;
const [isLoading, setIsLoading] = useState(false);
const [modalVisible, setModalVisible] = useState(false)
const [modalText, setModalText] = useState('')

interface decodeInterface{
    family_name: any
    extension_Telefone: any
    extension_cpf: any
    given_name: any
    isForgotPassword: boolean
    celular:any
    ddd: any
    ['signInNames.emailAddress']: any
    extension_Nascimento: any
}

// ((props.theme.ScreenHeight * 6)/100)}px
const redirectWebLogin = async() =>{
    // return await Linking.openURL('wwww.google.com')]
    // To do: trocar por variavel de ambiente
    return await Linking.openURL('https://apiloginhml.b2clogin.com/apiloginhml.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_LOGINCADASTRO&response_mode=query&client_id=f98dc44d-bee3-4018-aab0-a7655ada263e&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fapp-fidelidade-dev.msalso.com&scope=openid&response_type=id_token&prompt=login').then(res=>console.log('slaaaaa',res))

}

useEffect(()=>{
    // loginB2C()
    // if(Platform.OS === "web"){
    //     redirectWebLogin()
    // }
    login(route.params.token)

},[])

const { 
    signed, 
    signIn, 
    setB2CLoginToken, 
    setCpf, 
    setGivenName, 
    setFamilyName,
    setTelephoneNumber,
    setUserId,
    fidelityToken
    // setAlianseToken
} = useContext(AuthContext)


// const _addClient = async (decode:any, access_token:any) => {

//     setIsLoading(!isLoading);

//     var data = {
//         "celular": decode?.extension_Telefone.substr(4, 9),
//         "ddd": decode?.extension_Telefone.substr(1, 2),
//         "email": decode["signInNames.emailAddress"],
//         "extension_Nascimento": decode?.extension_Nascimento === undefined ? null : decode?.extension_Nascimento,
//         "extension_cpf": decode?.extension_cpf,
//         "family_name": decode.family_name,
//         "given_name": decode.given_name
//     }

//     const addClientResponse:any = await addClient(data,fidelityToken,  access_token);

//     if(addClientResponse?.response.status === 201) {

//         const getClientResponse:any = await getAlianseUser(decode?.extension_cpf, fidelityToken,access_token);

//         if(getClientResponse?.status === 200) {
//             const classifyClientResponse:any = await classifyClient(getClientResponse?.data?.pessoaFisica.id,fidelityToken, access_token);

//             if(classifyClientResponse?.status === 204) {
//                 console.log('Cliente classificado');
//             } else {
//                 setModalText('Ocorreu um erro. Code 00002')
//                 setIsLoading(!isLoading)
//                 setModalVisible(!modalVisible)
//             }
//         } else {
//             setModalText('Ocorreu um erro. Code 00001')
//             setIsLoading(!isLoading)
//             setModalVisible(!modalVisible)
//         }

//         setModalText(GlobalMessages.userInactive);
//         setModalVisible(!modalVisible) 
        
//     } else {
//         var msgError = addClientResponse?.response.data.mensagem === undefined ? 'Ocorreu um erro. Code 00003' : addClientResponse?.response.data.mensagem
//         setModalText(msgError)
//         setIsLoading(!isLoading)
//         setModalVisible(!modalVisible) 
//     }
// }

const updateUserWhenForgotPassword = async ( decode:decodeInterface, alianseUser:any ) =>  {
    if(decode.isForgotPassword)
    {
        if(decode.extension_Telefone)
        {
            let telefone = decode.extension_Telefone.match(/[\d]/g).join("")
            decode.celular = telefone.substring(2, telefone.length)
            decode.ddd = telefone.substring(0,2)
        }
        const responseUserUpdate: any = await updateClient(alianseUser?.data?.pessoaFisica?.id, decode, fidelityToken)
        if(responseUserUpdate?.status === 200) {
            console.log('cadastro cliente atualizado');
        }
    }
}

const setSignInUserData = (userData: any) => {
  const { id, givenName, familyName, cpf, phoneNumber } = userData;

  setUserId(id);
  setGivenName(givenName);
  setFamilyName(familyName);
  setCpf(cpf);
  setTelephoneNumber(phoneNumber);
};

const login = async(b2cToken:string) =>{
    const decode:decodeInterface = await jwt(b2cToken)
    if(!decode) return
 
    setIsLoading(!isLoading)
    setB2CLoginToken(b2cToken);
    const decodedUser = {
      givenName: decode?.given_name,
      familyName: decode?.family_name,
      cpf: decode?.extension_cpf,
      phoneNumber: decode?.extension_Telefone,
      email: decode['signInNames.emailAddress'] || '',
      birthDate: decode.extension_Nascimento
    };
    /**
     * To Do:
     * fazer validações caso nao exista os campos do decode
     */
    
    const alianseUser:any = await getAlianseUser(decode?.extension_cpf, fidelityToken);
    
    if(alianseUser.status === 200) {

        await updateUserWhenForgotPassword(decode, alianseUser );

        const userFidelity:any = await getFidelity(alianseUser?.data?.pessoaFisica?.id, fidelityToken);
        //const userData = alianseUser?.data?.pessoaFisica;
        
        if (userFidelity?.status === 200) {
          const signInUserData = {
            id: alianseUser?.data?.pessoaFisica.id,
            ...decodedUser,
          };
          signIn();
          setSignInUserData(signInUserData);
          setIsLoading(!isLoading);
          //  if(userFidelity?.data?.modalidade === 'INATIVOS') {
          //     setIsLoading(false);
          //     //setModalText(GlobalMessages.userInactive)
          //     //setModalVisible(!modalVisible)
          //     //navigation.navigate('Signup', userData)
          //  } else {
          //     signIn()
          //     setB2CLoginToken(b2cToken)
          //     setCpf(decode?.extension_cpf)
          //     setUserId(alianseUser?.data?.pessoaFisica.id)
          //     setGivenName(decode?.given_name)
          //     setFamilyName(decode?.family_name)
          //     setTelephoneNumber(decode?.extension_Telefone)
          //     setIsLoading(!isLoading);
          //  }
        } else {
          setIsLoading(false);
          navigation.navigate("Signup", decodedUser);
          //setModalText(GlobalMessages.userInactive)
          //setModalVisible(!modalVisible)
        }
            
    } else {
        //_addClient(decode, token?.access_token);
        setIsLoading(false);
        navigation.navigate("Signup", decodedUser);
    }

}

return (
    <>
    {isLoading ? 
    <Spinner
    visible={true}
    textContent={'Carregando...'}
    textStyle={{color: '#fff'}}
    overlayColor="rgba(0,0,0,0.8)"
    /> : null}

    {modalVisible ?
    <ModalAlert 
        text={modalText}
        textButtonConfirm="OK" 
        isVisible={modalVisible}
        onPressConfirm={() => {
            setModalText('Cadastro enviado com sucesso. Em breve você receberá o e-mail de confirmação.')
            setModalVisible(!modalVisible);
            navigation.navigate('PublicHome')
        }}
    /> : null}
    </>
)
}

export default Signin;
