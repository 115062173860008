import styled from 'styled-components/native'
import { FadeLoading } from 'react-native-fade-loading';

export const Container = styled.ScrollView`
    background-color: ${props => props.theme.colors.secondBackground};
    width: ${props => props.theme.ScreenWidth}px;
    min-height: ${props => props.theme.ScreenHeight}px;
`
export const Circle = styled.View`
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: ${props => props.theme.colors.secondBackground};
    top: -20px;
    border-radius: 360px;
`
export const CircleBottom = styled.View`
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: ${props => props.theme.colors.secondBackground};
    bottom: -10px;
    border-radius: 360px;
    z-index: 99999;
`
export const Ticket = styled.View`
    width: ${props => 0.9 * props.theme.ScreenWidth}px;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
`
export const BenefitImage = styled.ImageBackground`
    width: ${props => 0.9 * props.theme.ScreenWidth}px;
    height: 250px;
    margin-left: -15px;
    margin-top: -40px
`;

export const Opacity = styled.View`
    background-color: #000;
    opacity: 0.5;
    height: 250px;
    width: ${props => 0.9 * props.theme.ScreenWidth}px;
    position: absolute;
    top: 0;
    left: 0;
`
export const Loading = styled(FadeLoading)`
    width: ${props => (0.9 * props.theme.ScreenWidth) - 30}px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
`;
