
import styled  from 'styled-components/native';

// background-color: ${props => props.theme["_W"].colors.primary};
export const MediumText = styled.Text`

font-size: 14px;

color: ${props => props.theme.colors.text};

font-family: ${props=> props.theme.fontFamily};
`