import React, { useContext } from 'react';
// import { Modal } from 'react-native';
import Modal from "react-native-modal";
import AuthContext from '../../context';
import { BackgroundModal, ModalView, CenteredView } from "./styles";
import { BigTextBold } from '../../styles/text/boldText/bigTextBold';
import DefaultButton from '../buttons/defaultButton/index';
import ConfirmButton from '../buttons/confirmButton';
import CancelButton from '../buttons/cancelButton/index';

export const ModalAlert:React.FC<any> = ({text, textButtonConfirm, textButtonCancel = null, onPressConfirm, onPressCancel, isVisible}:any) => {

    // const { setModalVisible, modalVisible, textModal } = useContext(AuthContext);

    if(isVisible) {
        return(
            <BackgroundModal>
                <Modal
                    isVisible={isVisible}
                >
                    <CenteredView>
                        <ModalView>
                            <BigTextBold>{text}</BigTextBold>
                            <ConfirmButton text={textButtonConfirm} onPress={onPressConfirm}  />
                            {   
                                textButtonCancel !== null ?
                                <CancelButton text={textButtonCancel} onPress={onPressCancel}  />
                                : null
                            }
                        </ModalView>
                    </CenteredView>
                </Modal>
            </BackgroundModal> 
        )
    } else {
        return null;
    }
}

export default ModalAlert;