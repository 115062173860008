import React, { useContext, useEffect } from 'react';
import { View, Text, Pressable, Alert} from 'react-native';
import { Container, LogoContainer, Logo, Menu, BackgroundModal } from './styles';
import LogoHeader from '../../../assets/LogoHeader2.png'
import { ContentAligned } from '../../styles/containers/main/contentAligned';
import AuthContext from '../../context';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import sendMail from '../../services/sendEmail';
import jwt from 'jwt-decode'
import { useNavigation } from '@react-navigation/native';
import Expo from 'expo'

interface decodeInterface{
  family_name: any
  extension_Telefone: any
  extension_cpf: any
  given_name: any
  isForgotPassword: boolean
  celular:any
  ddd: any,
  "signInNames.emailAddress":[]
}

const Header: React.FC = () => {
const { 
  drawer ,
  setDrawer, 
  signed, 
  modalVisible, 
  signedOut, 
  fidelityToken, 
  telephoneNumber, 
  givenName, 
  cpf, 
  B2CLoginToken
} = useContext(AuthContext)
const navigation = useNavigation();

useEffect(()=>{
},[drawer])

  

  const removeAccount = async () => {
    const decode:decodeInterface = await jwt(B2CLoginToken)

    Alert.alert('Excluir conta', 'Sua solicitação de exclusão de conta será atendida em até 15 dias. Deseja prosseguir com a exclusão da conta?', [
      {
        text: 'Cancelar',
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      { text: 'Excluir', onPress: async () => {
        const subject = 'Exclusão de Conta Cliente Solar'
        const template = 
        `<p>Cliente solicita a exclusão da sua conta Solar.</p>`+
        `<p>nome: ${givenName}</p>`+
        `<p>cpf: ${cpf}</p>`+
        `<p>e-mail: ${decode["signInNames.emailAddress"]}</p>`+
        `<p>telefone: ${decode?.extension_Telefone}</p>`

        const response = await sendMail(fidelityToken, subject, template)

        console.log('Response Email', response)
        signedOut();

        Alert.alert('Sucesso', 'Solicitação de exclusão de conta efetuada com sucesso.',[
          {
            text: 'OK',
            onPress: () => {  signedOut();  }
          }
        ])

      } },
    ]);
  }

  return (
  <>
  <Container>
    <ContentAligned style={{justifyContent: 'space-between', flexDirection: 'row'}}>

      <LogoContainer style={{justifyContent:'flex-end'}}>
        <Logo  style={{resizeMode:'contain'}} source={LogoHeader} />
      </LogoContainer>
      <Menu
      
      >
      {
        signed ? 
          (
            <View
              style={{flexDirection: 'column'}}
            >
            <Pressable 
              onPress={signedOut}
              style={{flexDirection: 'row', alignItems:'center'}}
            >
              <MaterialCommunityIcons name="logout" size={24} color="black" />
              <Text>Sair</Text>
            </Pressable>
            {/* <Pressable
            onPress={() => removeAccount()}
            style={{flexDirection: 'row', alignItems:'center'}}
            >
              <MaterialCommunityIcons name="account-remove" size={24} color="black" />
              <Text>Excluir conta</Text>
            </Pressable> */}
            
          </View>
          )
        : null
      }
        
        {
          /**
           * aqui é o containet de menu
           */
        }
        {/* {drawer && signed ? (
          <TouchableOpacity style={{marginLeft: 88, marginTop:16}} onPress={()=> drawer ? drawer["openDrawer"](): null}>
          <Feather  name='align-justify' size={Platform.OS === "web"? 40: 36} color={defaultTheme.colors.text} />


          </TouchableOpacity>
          // <Button title='sla' onPress={()=> drawer ? drawer["openDrawer"](): null}></Button>
        ): null} */}
        
      </Menu>
    </ContentAligned>
    
  </Container>
  {
      modalVisible ?
        <BackgroundModal></BackgroundModal>
      : null
    }
  </>
  )
}

export default Header;