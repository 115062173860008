import React from 'react';
import { View, Text} from 'react-native';
import { MediumTextBold } from '../../styles/text/boldText/mediumTextBold';
import { DefaultText } from '../../styles/text/defaultText';
import GlobalMessages from "../../config/Messages";

import { Container, GoldCircle, TiersContainer,SilverCircle, BronzeCircle,PointsCircle } from './styles';
import thousandsSeparator from '../../pages/utils/thousandsSeparator';

const TierInfoUser: React.FC<any> = ({pointsRemaining = 0 , nextTier='Prata' }:any) => {
  return (
      <Container>
          <View style={{width: '40%'}}>
            <DefaultText>Faltam: <MediumTextBold>{thousandsSeparator(pointsRemaining, '.')} {GlobalMessages.pts} </MediumTextBold> {"\n"}para você alcançar {"\n"}a Categoria <MediumTextBold>{nextTier}</MediumTextBold></DefaultText>
          </View>
          <View style={{width: '50%', display: 'flex', flexDirection: 'row'}}>
                <TiersContainer>
                    <View style={{display: 'flex', flexDirection:'row'}}>
                        <BronzeCircle />
                        <DefaultText style={{marginTop: -3, marginLeft: 10}}>Bronze</DefaultText>
                    </View>
                    <View style={{display: 'flex', flexDirection:'row'}}>
                        <GoldCircle />
                        <DefaultText style={{marginTop: -3, marginLeft: 10}}>Ouro</DefaultText>
                    </View>
                </TiersContainer>
                <TiersContainer>
                    <View style={{display: 'flex', flexDirection:'row'}}>
                        <SilverCircle />
                        <DefaultText style={{marginTop: -3, marginLeft: 10}}>Prata</DefaultText>
                    </View>
                    <View style={{display: 'flex', flexDirection:'row'}}>
                        <PointsCircle />
                        <DefaultText style={{marginTop: -3,marginLeft: 10}}>Pontuação</DefaultText>
                    </View>
                </TiersContainer>
          </View>
      </Container>
  )
}

export default TierInfoUser;