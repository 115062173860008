import { Dimensions } from 'react-native';
import styled  from 'styled-components/native';
const { width, height } = Dimensions.get('window');

export const BackgroundModal = styled.View`
    width: ${width}px;
    height: ${height}px;
    background-color: #000;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
`;


export const CenteredView = styled.View`
    align-items: center;
    justify-content: center;
    display: flex;
    flex: 1;
`;

export const ModalView = styled.View`
    margin: 20px;
    background-color: #fff;
    border-radius: 20px;
    padding: 35px;
    align-items: center;
    display: flex;
    width: 90%;
`;